export function santeSeniorRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        switch(pr.produit.produit_ws_nom){
            case 'SP_VIE':
            case 'CAPITAL_SENIOR':
            case 'SL_Sante_Retraites':
            case 'SERENISSIA':
            case 'MALIN':
            case 'SMART':
                produitsResult.push(santeSeniorSLProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function santeSeniorSLProduit(produit,garantie,exigences){

    // ------------------- SwissLife -------------------
    let Niveau_1={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Economique'
    }
    let Niveau_2={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Economique'
    }
    let Niveau_3={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Complète'
    }
    let Niveau_4={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Haut de gamme'
    }
    let Niveau_5={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Haut de gamme'
    }
    let Niveau_6={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:4,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Haut de gamme'
    }
    let Niveau_7={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:4,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Haut de gamme'
    }
    let Niveau_8={
        optique:{
            value:5,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:5,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Haut de gamme'
    }

    // ------------------- ECA ASsurances -------------------
    let BUDGET={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Economique'
    }
    let ESSENTIEL={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Economique'
    }
    let SECURITE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Complète'
    }
    let EQUILIBRE={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Complète'
    }
    let CONFORT={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Haut de gamme'
    }
    let BIEN_ETRE={
        optique:{
            value:5,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:5,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Haut de gamme'
    }

    // ------------------- SPV -------------------
    let Niveau_A={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Economique'
    }
    let Niveau_B={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Complète'
    }
    let Niveau_C={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Complète'
    }
    let Niveau_D={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Complète'
    }
    let Niveau_E={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        // medecineDouce:{
        //     value:-1,
        //     choose:searchoption('Medecine Douce')
        // },
        grNiveau:'Complète'
    }

    //-------------------ECA SERENISSIA
    let ES_100={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let ES_100_B={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let ES_125={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let ES_150={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let ES_175={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let ES_200={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:4,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let ES_250={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:4,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let ES_300={
        optique:{
            value:5,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:5,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }

    // ------------------- SPV MALIN -------------------
    let Formule_1={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_2={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let Formule_3={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }

    //------------------- SPV SMART
    let Formule_A_ESSENTIELLE={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_A_CONFORT={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_B_ESSENTIELLE={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_B_CONFORT={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_C_ESSENTIELLE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let Formule_C_CONFORT={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_C_FIDELITE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_D_ESSENTIELLE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let Formule_D_CONFORT={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_D_FIDELITE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_E_CONFORT={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_E_FIDELITE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_F_CONFORT={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:5,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_F_FIDELITE={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:5,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    // ------------------- Treatment -------------------

    switch(produit.tarif.formule){
        // ------------------- SwissLife -------------------
        case 'Niveau_1':
            calcultauxtarif(Niveau_1,garantie,produit)
        break;
        case 'Niveau_2':
            calcultauxtarif(Niveau_2,garantie,produit)
        break;
        case 'Niveau_3':
            calcultauxtarif(Niveau_3,garantie,produit)
        break;
        case 'Niveau_4':
            calcultauxtarif(Niveau_4,garantie,produit)
        break;
        case 'Niveau_5':
            calcultauxtarif(Niveau_5,garantie,produit)
        break;
        case 'Niveau_6':
            calcultauxtarif(Niveau_6,garantie,produit)
        break;
        case 'Niveau_7':
            calcultauxtarif(Niveau_7,garantie,produit)
        break;
        case 'Niveau_8':
            calcultauxtarif(Niveau_8,garantie,produit)
        break;
        // ------------------- ECA Assurances -------------------
        case 'BUDGET':
            calcultauxtarif(BUDGET,garantie,produit)
        break;
        case 'ESSENTIEL':
            calcultauxtarif(ESSENTIEL,garantie,produit)
        break;
        case 'SECURITE':
            calcultauxtarif(SECURITE,garantie,produit)
        break;
        case 'EQUILIBRE':
            calcultauxtarif(EQUILIBRE,garantie,produit)
        break;
        case 'CONFORT':
            calcultauxtarif(CONFORT,garantie,produit)
        break;
        case 'BIEN_ETRE':
            calcultauxtarif(BIEN_ETRE,garantie,produit)
        break;
        // ------------------- SPV -------------------
        case 'Niveau A':
            calcultauxtarif(Niveau_A,garantie,produit)
        break;
        case 'Niveau B':
            calcultauxtarif(Niveau_B,garantie,produit)
        break;
        case 'Niveau C':
            calcultauxtarif(Niveau_C,garantie,produit)
        break;
        case 'Niveau D':
            calcultauxtarif(Niveau_D,garantie,produit)
        break;
        case 'Niveau E':
            calcultauxtarif(Niveau_E,garantie,produit)
        break;
        // ---------------------ECA SERENISSIA---------------
         // ------------------- SwissLife -------------------
        case 'ES_100':
            calcultauxtarif(ES_100,garantie,produit)
        break;
        case 'ES_100_B':
            calcultauxtarif(ES_100_B,garantie,produit)
        break;
        case 'ES_125':
            calcultauxtarif(ES_125,garantie,produit)
        break;
        case 'ES_150':
            calcultauxtarif(ES_150,garantie,produit)
        break;
        case 'ES_175':
            calcultauxtarif(ES_175,garantie,produit)
        break;
        case 'ES_200':
            calcultauxtarif(ES_200,garantie,produit)
        break;
        case 'ES_250':
            calcultauxtarif(ES_250,garantie,produit)
        break;
        case 'ES_300':
            calcultauxtarif(ES_300,garantie,produit)
        break;
        // ------------------- Malin -------------------
        case 'Formule 1':
            calcultauxtarif(Formule_1,garantie,produit)
        break;
        case 'Formule 2':
            calcultauxtarif(Formule_2,garantie,produit)
        break;
        case 'Formule 3':
            calcultauxtarif(Formule_3,garantie,produit)
        break;
        // ---------------------SPV SMART---------------
        case 'AE':
            calcultauxtarif(Formule_A_ESSENTIELLE,garantie,produit)
            break;
        case 'AC':
            calcultauxtarif(Formule_A_CONFORT,garantie,produit)
            break;
        case 'BE':
            calcultauxtarif(Formule_B_ESSENTIELLE,garantie,produit)
            break;
        case 'BC':
            calcultauxtarif(Formule_B_CONFORT,garantie,produit)
            break;
        case 'CE':
            calcultauxtarif(Formule_C_ESSENTIELLE,garantie,produit)
            break;
        case 'CC':
            calcultauxtarif(Formule_C_CONFORT,garantie,produit)
            break;
        case 'CF':
            calcultauxtarif(Formule_C_FIDELITE,garantie,produit)
            break;
        case 'DE':
            calcultauxtarif(Formule_D_ESSENTIELLE,garantie,produit)
            break;
        case 'DC':
            calcultauxtarif(Formule_D_CONFORT,garantie,produit)
            break;
        case 'DF':
            calcultauxtarif(Formule_D_FIDELITE,garantie,produit)
            break;
        case 'EC':
            calcultauxtarif(Formule_E_CONFORT,garantie,produit)
            break;
        case 'EF':
            calcultauxtarif(Formule_E_FIDELITE,garantie,produit)
            break;
        case 'FC':
            calcultauxtarif(Formule_F_CONFORT,garantie,produit)
            break;
        case 'FF':
            calcultauxtarif(Formule_F_FIDELITE,garantie,produit)
            break;
        
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function calcultauxtarif(pgniveau,garantie,produit){
    let total=0
    let cpt=0
    Object.entries(pgniveau).forEach(([key, o]) => {
        if(key!=='grNiveau'){
            let somme=0;
            if(o.value==o.choose){
                somme=1
            }
            total+=somme;
            cpt++
        }
    });
    produit.tarif.tauxGn  = pgniveau.grNiveau==garantie ? 1 : 0
    produit.tarif.tauxCouv= total/cpt
}

function searchoption(option,exigences){
    let tmpOption= exigences.find( pg => pg.label === option);
    return tmpOption ? mapAndGetResult(tmpOption) : 1
}

function mapAndGetResult(exigence){
    let tmpEx= exigence.exigenceniveaux.find( egn => egn.id === exigence.exigenceNiveauID[0]);
    if(tmpEx && tmpEx.exigence_niveau_libel){
        switch(tmpEx.exigence_niveau_libel){
            case 'NON':
                return 0;
            case 'MIN':
                return 1;
            case 'MOYEN':
                return 2
            case 'FORT':
                return 3;
            case 'MAX':
                return 4;
            case 'MAXPLUS':
                return 5;
            default:
                return 1;
        }
    }else{
        return 1;
    }
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}
