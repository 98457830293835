<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 2 : Complément d'informations
        </h2>
        <h6>
          Vérifiez et complétez les informations. Une fois le devis créer chez notre partenaire vous serez amené à poursuivre sur son espace extranet
        </h6>
      </b-col>
    </b-row>
    <b-card-text>
      <h4 class="title-custom-wizard">
        <u>ASSURÉ PRINCIPAL (Signataire)</u>
      </h4>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Civilité*"
            label-for="civilite"
          >
            <b-form-select
              id="civilite"
              v-model="prospect.civilite"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Choisissez
              </b-form-select-option>
              <b-form-select-option value="MR">
                M.
              </b-form-select-option>
              <b-form-select-option value="MME">
                Mme
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!$v.prospect.civilite.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom*"
            label-for="nom"
          >
            <div
              v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' "
              class="form-control"
              readonly
              disabled
            >
              {{ prospect.nom }}
            </div>
            <b-form-input
              v-else
              id="nom"
              v-model="prospect.nom"
              autocomplete="nope"
              trim
              placeholder="Nom"
              class="text-uppercase"
            />
            <b-form-invalid-feedback :state="!$v.prospect.nom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Prénom*"
            label-for="prenom"
          >
            <div
              v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' "
              class="form-control"
              readonly
              disabled
            >
              {{ prospect.prenom }}
            </div>
            <b-form-input
              v-else
              id="prenom"
              v-model="prospect.prenom"
              autocomplete="nope"
              trim
              placeholder="Prénom"
              class="text-capitalize"
            />
            <b-form-invalid-feedback :state="!$v.prospect.prenom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Date de naissance*"
            label-for="date_naissance"
          >
            <div
              class="form-control"
              readonly
            >
              {{ prospect.date_naissance }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>

    <b-card-text>
      <b-row class="mt-2">
        <b-col md="12">
          <h4 class="text-uppercase">
            <b>Adresse actuelle</b>
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="N° et libellé de la voie*"
            label-for="libelle"
          >
            <b-form-input
              id="libelle"
              v-model="prospect.moyen_contact.libelle"
              autocomplete="nope"
              trim
              placeholder="N° et libellé de la voie"
            />
            <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.libelle.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Complément d'adresse"
            label-for="complement_adresse"
          >
            <b-form-input
              id="complement_adresse"
              v-model="prospect.moyen_contact.complement_adresse"
              autocomplete="nope"
              trim
              placeholder="Complément d'adresse"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Lieu-dit"
            label-for="lieu_dit"
          >
            <b-form-input
              id="lieu_dit"
              v-model="prospect.moyen_contact.lieu_dit_ou_bp"
              autocomplete="nope"
              trim
              placeholder="Lieu-dit"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Code postal*"
            label-for="code_postale"
          >
            <div
              class="form-control"
              readonly
              disabled
            >
              {{ prospect.moyen_contact.code_postal }}
            </div>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Ville*"
            label-for="ville"
          >
            <b-form-select
              id="ville"
              v-model="prospect.moyen_contact.ville_id"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Choisissez
              </b-form-select-option>
              <b-form-select-option
                v-for="(val, value) in villesLocal"
                :key="value"
                :value="val.id"
              >
                {{ val.ville }}
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.ville_id.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Numéro de téléphone "
            label-for="phone"
          >
            <div class="d-flex bd-highlight">
              <div class="w-25 bd-highlight">
                <v-select
                  v-model="$v.prospect.moyen_contact.indicatif_tel.$model"
                  :close-on-select="true"
                  :clearable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="indicatif"
                  :reduce="indicatif => indicatif.indicatif"
                  input-id="indicatif"
                  label="indicatif"
                  class="indicatif-chooser-souscription-santesenior w-100"
                >
                  <template #option="{ indicatif,code }">
                    <span> {{ `${code.toUpperCase()} ` + indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">
                    Aucune indicatif disponible.
                  </div>
                </v-select>
              </div>
              <div class="w-75 bd-highlight">
                <cleave
                  id="phone"
                  v-model="$v.prospect.moyen_contact.tel.$model"
                  :options="options.phone"
                  :raw="false"
                  class="form-control"
                  style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                  placeholder="ex: 06 00 00 00 00"
                />
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.tel.$error">
                  Le numéro de téléphone est obligatoire
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.indicatif_tel.$error">
                  L'indicatif téléphonique est obligatoire
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="false">
                  Attention, un numéro de téléphone portable valide est nécessaire pour la signature du contrat.
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Email*"
            label-for="email"
            class="mb-0"
          >
            <b-form-input
              id="email"
              v-model="prospect.moyen_contact.email"
              autocomplete="nope"
              trim
              placeholder="Email"
            />
            <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.email.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>

    <b-card-text>
      <b-row class="mt-2">
        <b-col md="12">
          <h4 class="text-uppercase">
            <b>Future adresse</b>
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="N° et libellé de la voie"
            label-for="libelle"
          >
            <b-form-input
              id="libelle"
              v-model="objetEmprunteur.libelle"
              autocomplete="nope"
              trim
              placeholder="N° et libellé de la voie"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Complément d'adresse"
            label-for="complement_adresse"
          >
            <b-form-input
              id="complement_adresse"
              v-model="objetEmprunteur.complement_adresse"
              autocomplete="nope"
              trim
              placeholder="Complément d'adresse"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Code postal"
            label-for="code_postale"
          >
            <b-form-input
              id="code_postale"
              v-model="objetEmprunteur.code_postal"
              autocomplete="nope"
              placeholder="Code postal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Ville"
            label-for="ville"
          >
            <b-form-input
              id="ville"
              v-model="objetEmprunteur.ville"
              autocomplete="nope"
              placeholder="Ville"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Pays de déménagement"
            label-for="pays-demenagement"
            :class="errors.length > 0 ? 'is-invalid' : ''"
          >
            <v-select
              v-model="objetEmprunteur.pays_demenagement"
              :close-on-select="true"
              :reduce="pays => pays.pays"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pays"
              class="flex-grow-1"
              :state="errors.length > 0 ? false : null"
              input-id="pays"
              label="pays"
              placeholder="Pays de déménagement"
            >
              <template #option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>

              <template #selected-option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>
              <div slot="no-options">
                Aucune pays disponible.
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Date de déménagement"
            label-for="date-demenagement"
          >
            <flat-pickr
              id="date-demenagement"
              v-model="objetEmprunteur.date_demenagement"
              :config="configDate"
              class="form-control"
              placeholder="Date de déménagement"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>

    <b-card-text>
      <b-row>
        <b-col lg="12" class="mt-4" v-if="produitPreconise.produit.assureur.nom_com_assureur.toLowerCase() !== 'mutlog'">
          <span class="float-right"> Préparez vos identifiants Harmonie Mutuelle </span>
        </b-col>
        <b-col lg="12" class="pt-1">
          <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
          <b-button v-if="produitPreconise" variant="primary" class="float-right" size="lg" @click="submit">
            {{ `Accédez à votre espace ${produitPreconise.produit.assureur.nom_com_assureur} et créer votre devis` }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Data from '@/shared/constants/risquebesoin'
import { is422 } from '@/shared/utils/response'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BButton,
  BCardText,
  BFormSelectOption
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    flatPickr,
    BFormSelect,
    BButton,
    BCardText,
    BFormSelectOption,
    Cleave,
  },
  props: ['pays', 'showLoading'],
  computed: {
    ...mapGetters(['Tiers']),
    ...mapGetters(['getItemsRisque']),
  },
  watch: {
    Tiers: {
      handler(val) {
        if (val.id != undefined) {
          this.mapProspectLocal(val)
        }
      },
      immediate: true,
      deep: true,
    },
    getItemsRisque: {
      handler(val) {
        this.rl = val.ItemsRisque[0]
        this.objets = val.ItemsRisque[0].objets
        this.devis = val.ItemsRisque[0].devis
        this.objetEmprunteur = this.objets.objet_emprunteurs[0]
        this.initPreconise()
      },
      immediate: true,
      deep: true,
    },
    'prospect.moyen_contact.code_postal': {
      immediate: true,
      handler() {
        this.cpKeyup()
      },
    },
    getSelectedRisque() {
      this.indexRisque = this.getSelectedRisque
    },
  },
  data() {
    return {
      produitPreconise: null,
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        },
      },
      indicatif: [],
      prospect: {
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        date_naissance: null,
        type: null,
        numeroOrganisme: null,
        num_secu: null,
        ayantdroit: null,
        situation_familiale: null,
        statut_gda: null,
        nom_naissance: null,
        code_postal_naissance: null,
        commune_naissance: null,
        pays_naissance: null,
        regime_obligatoire: null,
        rang_laser: 1,
        statut: null,
        original_statut: null,
        nationalite: null,
        secteur_activite: null,
        isPPE: null,
        moyen_contact: {
          libelle: null,
          lieu_dit_ou_bp: null,
          complement_adresse: null,
          indicatif_tel: null,
          tel: null,
          code_postal: null,
          ville_id: null,
          email: null,
        },
      },
      villesLocal: [],
      situationFamiliale: Data.SITUATIONFAMILIALE,
      nationalites: Data.NATIONALITES,
      statutJuridique: Data.ACTIVITEPROFESSIONNELLE,
      regimeSocial: Data.REGIMESOCIAL,
      secteurActivite: Data.SECTEUR_ACTIVITES,
      errors: [],
      rl: null,
      objets: null,
      objetEmprunteur: null,
      EnfantsGlobalLocal: [],
      enfantsData: [],
      disableEnfantNumSec: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          numericOnly: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        block: {
          blocks: [9],
          uppercase: true,
        },
        blockSecu: {
          blocks: [15],
          uppercase: true,
        },
        blockSiret: {
          blocks: [14],
          uppercase: true,
        },
        delimiter: {
          delimiter: ' ',
          blocks: [2, 2, 2, 2, 2],
          uppercase: true,
          numericOnly: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true,
        },
        cp: {
          blocks: [5],
        },
      },
      config: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        },
      },
    }
  },
  validations: {
    prospect: {
      moyen_contact: {
        tel: { required, minLength: minLength(14) },
        indicatif_tel: { required },
        email: { required, email },
        libelle: { required },
        code_postal: { required },
        ville_id: { required },
      },
      civilite: { required },
      nom: { required },
      prenom: { required },
      pays_naissance: { required },
    },
  },
  created() {
    this.getPaysList()
  },
  methods: {
    initPreconise() {
      if (this.rl !== undefined) {
        const pr = _.find(this.rl.produitsFiltred, p => p.tarif.choix_client)
        this.produitPreconise = pr
      }
    },
    getPaysList() {
      this.$http.get('tiers/getPays')
        .then(r => {
          const france = r.data.find(item => item.indicatif === '+33')
          r.data.sort((x, y) => (x == france ? -1 : y == france ? 1 : 0))
          this.indicatif = r.data
          if (!this.prospect.moyen_contact.indicatif_tel) {
            this.prospect.moyen_contact.indicatif_tel = this.indicatif[0].indicatif
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    mapProspectLocal(tiers) {
      this.prospect.id = tiers.id
      this.prospect.civilite = tiers.civilite
      this.prospect.nom = tiers.nom
      this.prospect.prenom = tiers.prenom
      this.prospect.date_naissance = tiers.date_naissance
      this.prospect.numeroOrganisme = tiers.numeroOrganisme
      this.prospect.type = tiers.type
      this.prospect.situation_familiale = tiers.situation_familiale
      this.prospect.statut_gda = tiers.statut_gda
      this.prospect.num_secu = tiers.num_secu
      this.prospect.ayantdroit = tiers.ayantdroit
      this.prospect.nom_naissance = tiers.nom_naissance
      this.prospect.code_postal_naissance = tiers.code_postal_naissance
      this.prospect.commune_naissance = tiers.commune_naissance
      this.prospect.pays_naissance = tiers.pays_naissance
      this.prospect.regime_obligatoire = tiers.regime_obligatoire
      this.prospect.rang_laser = tiers.rang_laser
      this.prospect.isPPE = tiers.isPPE
      this.prospect.statut = tiers.statut
      this.prospect.original_statut = tiers.statut
      this.prospect.nationalite = tiers.nationalite
      this.prospect.secteur_activite = tiers.secteur_activite
      this.prospect.moyen_contact.libelle = tiers.moyen_contact.libelle
      this.prospect.moyen_contact.lieu_dit_ou_bp = tiers.moyen_contact.lieu_dit_ou_bp
      this.prospect.moyen_contact.complement_adresse = tiers.moyen_contact.complement_adresse
      this.prospect.moyen_contact.tel = tiers.moyen_contact.tel
      this.prospect.moyen_contact.code_postal = tiers.moyen_contact.code_postal
      this.prospect.moyen_contact.ville_id = tiers.moyen_contact.ville_id
      this.prospect.moyen_contact.email = tiers.moyen_contact.email
      this.prospect.moyen_contact.indicatif_tel = tiers.moyen_contact.indicatif_tel
    },
    submit() {
      this.errors = []
      this.$v.$reset()
      this.$v.prospect.$touch()

      if (this.$v.prospect.$error) {
        return 0
      }

      this.$emit('updateLoading', true)

      if (this.prospect.original_statut != null && this.prospect.statut !== this.prospect.original_statut) {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Attention, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
            <br><br>
            Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
            <br><br>
            Souhaitez-vous confirmer cette modification ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            html: 'text-left',
          },
          buttonsStyling: false,
        })
          .then(confirmed => {
            if (confirmed.value) {
              this.updateDataSouscripteur()
              this.prospect.original_statut = this.prospect.statut
            } else {
              this.$emit('updateLoading', false)
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          })
          .catch(() => {
            this.$emit('updateLoading', false)
          }).finally(() => {
          })
      } else {
        this.updateDataSouscripteur()
      }
    },
    updateDataSouscripteur() {
      const data = {
        prospect: this.prospect,
        objets: this.objets,
        devis: this.rl.devis.id,
        produit: this.produitPreconise,
        objetEmprunteur: this.objetEmprunteur,
        risque: { label: this.rl.risque.label },
      }

      this.$http.post('portefeuilles/updateDataSouscripteur', data).then(async r => {
        await this.validTosouscrire(data)
        this.$store.commit('setProspect', {
          Tiers: this.prospect,
        })
        this.$store.commit('setObjetEmprunteur', {
          objet_emprunteurs: this.objetEmprunteur,
        })
      }).catch(err => {
        if (is422(err)) {
          console.log(err)
        }
      })
    },
    async validTosouscrire(data) {
      data.hasSign = false
      await this.$http.post('portefeuilles/creerDevis', data).then(r => {
        if (r.data.data.success) {
          this.updateDevisSouscriptionAvancement()
          this.messageToast(r.data.data.message, 'Succès', 'success')
          this.produitPreconise.tarif.numero_police = r.data.data.reference
          window.open(r.data.data.espace_link, '_blank')
          this.$router.push({ name: 'projets-list' })
        } else {
          this.messageToast(r.data.data.message, 'Erreur', 'danger')
        }
        this.$emit('updateLoading', false)
      }).catch(err => {
        if (is422(err)) {
          this.$emit('updateLoading', false)
          this.errors = err.response.data.errors
        }
      })
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 1,
          active: 'choixclient',
        },
      })
    },
    async cpKeyup() {
      const cp = this.prospect.moyen_contact.code_postal
      if (cp.length == 5) {
        try {
          this.villesLocal = (await this.$http.get(`helpers/${cp}/villes`)).data
          if (this.prospect.moyen_contact.ville_id == null) {
            this.prospect.moyen_contact.ville_id = this.villesLocal[0].id
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        this.villesLocal = []
        this.prospect.moyen_contact.ville_id = null
      }
    },
    hasErrors(val) {
      if (this.errors) {
        const found = Object.entries(this.errors).find(([key, value]) => key === val)
        return found ? found[1][0] : false
      }
    },
    async updateDevisSouscriptionAvancement() {
      const data = {
        id: this.rl.devis.id,
        devis_etape_avancement: 'devisenregistre',
      }
      await this.$http.post('devis/updateDevisSouscriptionState', data).then(r => true).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle
  .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .checked
  ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.title-custom-wizard{
    color: #4d25bc ;
}
</style>

<style>

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle:focus {
    border-radius: 0.357rem 0 0 0.357rem;
}
</style>
