<template>
  <div>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h2 class="mb-0 text-uppercase font-weight-bolder">
              étape {{ hasEntrepriseStep ? '6' : '5' }} : validation et signature
            </h2>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mb-2">
            <h4 class="mb-0">
              La proposition d’assurance ainsi que le bulletin d’adhésion de {{ titleClass(Tiers.civilite) }} {{ Tiers.prenom }} {{ Tiers.nom }} sont prêts pour signature.
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h4 class="mb-0">
              Vous pouvez les consulter ci-dessous pour validation. Après validation, un lien de signature sera directement envoyé à {{titleClass(Tiers.civilite)}} {{Tiers.prenom}} {{Tiers.nom}} par notre partenaire Universign.
            </h4>
          </b-col>
        </b-row>

        <b-row v-if="rl.devis.documentFic">
          <b-col cols="12" class="mb-2">
            <h4 class="mb-0 cursor-pointer mt-2" @click="showFic=!showFic">
              <b-img
                style="margin-top:-5px"
                height="18"
                :src="require('@/assets/images/icons/doc.png')"
              />
              Proposition d'assurance
              <feather-icon
                v-if="!showFic"
                icon="ChevronDownIcon"
                size="22"
              />
              <feather-icon
                v-else
                icon="ChevronUpIcon"
                size="22"
              />
            </h4>
          </b-col>
          <b-col lg="12" v-if="showFic">
            <div class="bloc-iframe" :style="{'overflow':'hidden'}">
              <iframe height="800px" width="100%" :src="getUrlbase64(rl.devis.documentFic)" :style="hideToolbar()"></iframe>
            </div>
          </b-col>
        </b-row>

         <b-row v-else>
            <b-col cols="12" class="mb-2">
              <h4 class="mb-0 cursor-pointer mt-2" @click="showFic=!showFic">
                <b-img
                  style="margin-top:-5px"
                  height="18"
                  :src="require('@/assets/images/icons/doc.png')"
                />
                Proposition d'assurance
                <feather-icon
                  v-if="!showFic"
                  icon="ChevronDownIcon"
                  size="22"
                />
                <feather-icon
                  v-else
                  icon="ChevronUpIcon"
                  size="22"
                />
              </h4>
            </b-col>
            <b-col lg="12" v-if="showFic">
              <div class="bloc-iframe" :style="{'overflow':'hidden'}">
                <iframe height="800px" width="100%" :src="getUrlbase64(rl.devis.documentProposition)" :style="hideToolbar()"></iframe>
              </div>
            </b-col>
          </b-row>

        <b-row v-if="rl.devis.document">
          <b-col cols="12" class="mb-2">
            <h4 class="mb-0 cursor-pointer" @click="showBa=!showBa">
              <b-img
                class="cursor-pointer"
                style="margin-top:-5px"
                height="18"
                :src="require('@/assets/images/icons/doc.png')"
              />
              Bulletin d’adhésion
              <feather-icon
                v-if="!showBa"
                icon="ChevronDownIcon"
                size="22"
              />
              <feather-icon
                v-else
                icon="ChevronUpIcon"
                size="22"
              />
            </h4>
          </b-col>
          <b-col lg="12" v-if="showBa">
            <div class="bloc-iframe" :style="{'overflow':'hidden'}">
              <iframe height="800px" width="100%" :src="getUrlbase64(rl.devis.document)" :style="hideToolbar()"></iframe>
            </div>
          </b-col>
        </b-row>

        <b-row >
          <b-col lg="12">
            <b-button
              variant="primary"
              class="float-right mt-4"
              size="lg"
              :disabled="btn_disabled"
              @click="signerContrat"
            >
              VALIDER ET ENVOYER POUR SIGNATURE
            </b-button>
          </b-col>
        </b-row>
  </div>

   <!-- <div>
      <b-row class="mb-4">
        <b-col lg="12" style="height:1600px">
          <iframe height="800px" width="100%" :src="getUrlbase64(rl.devis.documentFic)"></iframe>
          <iframe height="800px" width="100%" :src="getUrlbase64(rl.devis.document)"></iframe>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-button
            variant="primary"
            class="float-right"
            size="lg"
            @click="signerContrat"
          >
            Signer
          </b-button>
        </b-col>
      </b-row>
   </div> -->
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import url from '@/shared/api/parametre';
import "vue-pdf-app/dist/icons/main.css";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BImg
} from "bootstrap-vue";
import { required, email } from "@validations";
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BImg

  },
  props:['showLoading','hasEntrepriseStep'],
  computed: {
    ...mapGetters(["Tiers"]),
    ...mapGetters(["getItemsRisque"]),
  },
  watch: {
    getItemsRisque: {
        handler: function (val, oldVal) {
          this.rl = this.getItemsRisque.ItemsRisque[0];
        },
        immediate: true,
    },
  },
  data() {
    return {
      rl:null,
      config: {
        toolbar: {
          toolbarViewerLeft: { findbar: false }
        },
      },
      config2: {
        toolbar: {
          toolbarViewerLeft: { findbar: false }
        },
      },
      showBa:false,
      showFic:false,
      btn_disabled:false
    };
  },
  created() {
  },
  methods: {
      titleClass(word){
        return word ?  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '';
      },
     signerContrat(){
      let devis   = this.rl.devis;
      let risque  = this.rl.risque.label;
      let objets  = this.rl.objets;
      let produit = this.rl.produitsFiltred.find( pf => pf.tarif.choix_client);
      let data    ={
        devis:devis,
        produit:produit,
        risque:risque,
        prospect:{
          nom:this.Tiers.nom,
          prenom:this.Tiers.prenom,
          tel:this.Tiers.moyen_contact.tel,
          email:this.Tiers.moyen_contact.email,
        },
        objets:objets
      }
      this.$emit('updateLoading',true)
      this.btn_disabled=true
      this.$http.get(`devis/checkDevisTypeVente/${devis.id}`).then((r) => {
        if(r.data.state){
            this.$http.post(`portefeuilles/signerDocumentByAssureur`,data).then((r) => {
            this.$emit('updateLoading',false)
            this.$swal({
              icon: 'success',
              title: 'Envoi de document pour signature',
              showConfirmButton: false,
              timer: 4000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then((res)=>{
              this.$router.push({ name: 'projets-list' })
              //this.btn_disabled=false
            }).catch((err)=>{
                this.$emit('updateLoading',false)
                this.btn_disabled=false
                console.log(err)
            })
          }).catch((err)=>{
            this.$emit('updateLoading',false)
            this.btn_disabled=false
            console.log(err)
          })
        }else{
          this.$emit('updateLoading',false)
          this.btn_disabled=false
          this.$toast({
            component: ToastificationContent,
            timer: 10000,
            props: {
            title: 'Vous avez réalisé ce devis dans le cadre d’une vente en deux temps. La signature du contrat doit se faire dans les 24h suivant l’envoi des documents précontractuels (mail devis). Nous vous invitons à finaliser l’opération à l’issue de ce délai.',
            icon: 'XIcon',
            variant: 'danger',
            },
          })
        }
      }).catch((err)=>{
        this.$emit('updateLoading',false)
        console.log(err)
      })

    },
    // getUrlbase64(data){
    //   return "data:application/pdf;base64,"+data
    // },    
    geturlPdf(data){
      let path=data.path.replace('public', url + 'storage')
      path = path.replace('api/', '')
      return path+data.nom
    },
    getUrlbase64(base64){

          const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blo = new Blob([byteArray], {type: 'application/pdf'});

          var blob = new Blob([blo], {type: 'application/pdf'});
          var blobURL = URL.createObjectURL(blob);

          return blobURL

    },
    downloadFile(base64, name) {

          const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], {type: 'application/pdf'});

          // const linkSource = `data:application/pdf;base64,${base64}`;
          // const downloadLink = document.createElement("a");
          // const fileName = name;
          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.click();

          var blob = new Blob([file], {type: 'application/pdf'});
          var blobURL = URL.createObjectURL(blob);
          window.open(blobURL);

    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}


.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.bloc-iframe iframe {
  height: 800px!important
}
</style>

