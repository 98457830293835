<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 1 : confirmation du choix du client</h2>
        <h6>Sélectionnez la formule choisie par le client (commentaire obligatoire)</h6>
      </b-col>
    </b-row>

    <b-row class="pricing-card" v-for="(rl, index) in resumeLocal" :key="index + 'content'">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row>
          <b-col md="12" lg="12" xl="4" class="resume d-flex align-items-stretch"
            v-for="(pr, val) in filtreProduit(rl.produitsFiltred)" :key="val + 'produits'">
            <b-card align="center" :header="pr.produit.produit_nom" class="text-center">
              <div class="d-flex flex-column bd-highlight mb-3">
                <div class="pt-1 bd-highlight" style="height: 31px">
                  <b-card-text v-if="pr.tarif.preconise" icon="AwardIcon">
                    <b-badge variant="warning">
                      <feather-icon icon="AwardIcon" class="mr-25" />
                      <span>recommandée</span>
                    </b-badge>
                  </b-card-text>
                </div>
                <div class="pt-1 bd-highlight">
                  <h5>{{ pr.tarif.formule_commercial }}</h5>
                </div>
                <div class="py-0 bd-highlight my-1" style="height: 26px;">
                  <span class="d-block" style="font-size: 10px"> {{ pr.tarif.formule_descriptif }} </span>
                </div>
                <div class="bd-highlight">
                  <b-badge
                    :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.produit.produit_garanties[0], pr.tarif.codeFormule))"
                    class="mb-1">
                    {{ getGtNvByNiveau(pr.produit.produit_garanties[0], pr.tarif.codeFormule) }}
                  </b-badge>
                </div>
                <div class="pt-1 bd-highlight">
                  <div class="annual-plan">
                    <div class="plan-price">
                      <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                      <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.5rem"> {{
                        Number(pr.tarif.tarif).toFixed(2)
                      }} </span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{
                        rl.devis.fractionnement.toLowerCase()
                      }}</sub>
                    </div>
                  </div>
                </div>
                <div class="pt-1 bd-highlight" v-if="listOptionsFinal.length > 0">
                  <b-row style="font-size: 12px" class="text-left px-1">
                    <b-col sm="12" class="border-bottom-primary border-top-primary border-2 py-1">
                      <b-row class="text-primary">
                        <b-col sm="7" class="p-0">Garanties complémentaires et franchises</b-col>
                        <b-col sm="3" class="p-0">Tarif mensuel TTC</b-col>
                        <b-col sm="2" class="p-0">Sélection</b-col>
                      </b-row>
                    </b-col>
                    <b-col sm="12" class="pt-1"
                      v-for="(pg, i) in listOptionsFinal.find(item => item.codeFormule == pr.tarif.codeFormule).options"
                      :key="i + 'libellePGParent'" v-if="checkIsSelectedPG(pg)">
                      <b-row>
                        <b-col sm="12" class="p-0">{{ pg.libellePG }}</b-col>
                        <b-col sm="12" class="p-0" v-for="(detail, j) in $_.orderBy(pg.details, [getKeySortByPrice(index)], ['asc'])" :key="j + 'detailPGChild'"
                          v-if="detail.isShow || invisibleIfAllPricesEqual0(detail.tarif)">
                          <b-row class="ml-1">
                            <b-col sm="7" class="p-0">{{ detail.libelle_commercial }}</b-col>
                            <b-col sm="3" class="p-0">{{ Number(detail.tarif.mensuelle).toFixed(2) }}€</b-col>
                            <b-col sm="2" class="p-0">
                              <b-form-checkbox v-model="detail.isSelected" :disabled="detail.isSelected" switch
                                @change="changeSwitchPG(detail, index, pr.tarif.codeFormule, i, j)" size="sm" />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>

              <template #footer>
                <b-form-textarea
                  placeholder="exemple : Le rapport qualité/prix de cette formule est le plus adapté compte tenu du besoin que vous avez exprimé."
                  v-model="pr.tarif.commentaire_client" rows="3" maxlength="255"> </b-form-textarea>
                <!-- buttons -->
                <b-button block class="mt-2" @click="choisirProduit(pr.tarif.tarification_id)"
                  :variant="pr.tarif.choix_client ? 'primary' : 'outline-primary'"> Choix Client </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mb-1">
        <b-alert variant="warning" show class="mb-2 mx-2">
          <div class="alert-body">
            <span> Commentaire : Il s’agit des raisons pour lesquelles cette formule a été choisie. </span>
            <br />
            <span> La motivation doit être claire, compréhensible et ne pas renvoyer de façon elliptique aux Conditions
              Générales ou à une documentation non synthétique. La motivation doit consister à justifier la cohérence du
              produit avec les exigences de votre client. </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <app-collapse id="collapse-besoin" type="margin" class="mb-3">

      <app-collapse-item ref="tab-collapse-item-0" :is-visible="false"
        title="<h4 class='title-custom-wizard'><u>FMA - Détails garanties/franchises</u></h4>">
        <b-row>
          <b-col cols="12" class="mb-2" style="text-align: justify;">
            <p class="px-1">
              Garantie des équipements du conducteur
            <ul>
              <li><strong>Equip 300€</strong> couvre les équipements moto (blouson, pantalon, bottes) à concurrence de
                300 euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros.
              </li>
              <li><strong>Equip 750€</strong> couvre les équipements moto (blouson, pantalon, bottes) à concurrence de
                750 euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros.
              </li>
              <li><strong>Equip 1500€</strong> couvre les équipements moto (blouson, pantalon, bottes) sont couverts à
                concurrence de 1500 euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros
              </li>

            </ul>
            </p>

            <p class="px-1">
              Garantie dommages corporels du conducteur
            <ul>
              <li><strong>Protection du Pilote Essentielle</strong> indemnise le conducteur en cas d’atteinte permanente
                à l’intégralité
                physique et psychique partielle (capital de 250 000 euros multiplié par le taux d’AIPP défini par le
                médecin expert) et en cas de décès (20 000 euros au conjoint ou concubin, 7 500 euros par enfant à
                charge et remboursement des frais d’obsèques à hauteur de 4 000 euros, le total de ces indemnités de
                dépassera pas 250 000 euros). Le capital versé à l’assuré est doublé en cas de port du gilet airbag
                (jusqu'à 500 000 euros).</li>
              <li><strong>Protection du Pilote Etendue</strong> indemnise le conducteur en cas d’atteinte permanente à
                l’intégralité
                physique et psychique totale (capital de 500 000 euros), d’atteinte permanente à l’intégralité physique
                et psychique partielle (capital de 500 000 euros multiplié par le taux d’AIPP défini par le médecin
                expert) et en cas de décès (30 000 euros au conjoint ou concubin, 10 000 euros par enfant à charge et
                remboursement des frais d’obsèques à hauteur de 4 000 euros, le total de ces indemnités de dépassera pas
                500 000 euros). Le capital versé à l’assuré est doublé en cas de port du gilet airbag (jusqu'à 1 000 000
                euros).</li>
            </ul>
            </p>

            <p class="px-1">
              Garantie du contenu du Top Case
            <ul>
              <li><strong>Contenu du Top Case 250 </strong> garantie le vol des équipements du motard contenus dans le
                Top Case à hauteur de
                250€ (par sinistre et par an) suite à l’effraction du Top Case ou suite au vol de la moto.</li>
              <li><strong>Contenu du Top Case 500+</strong> garantie le vol des équipements du motard ainsi que le vol
                des clés et des
                papiers contenus dans le Top Case, à hauteur de 500€ (par sinistre et par an), suite à l’effraction du
                Top Case ou suite au vol de la moto.</li>
            </ul>
            </p>

            <p class="px-1">
              Garantie des accessoires du véhicule (hors série)
            <ul>
              <li><strong>Access 1 550 €</strong> couvre, en cas de sinistre, les accessoires hors série du véhicules à
                concurrence de 1 500 euros. Avec application d’une franchise égale à 10% du montant des dommages avec un
                minimum de 76 euros. </li>
              <li><strong>Access 3 000 €</strong> couvre, en cas de sinistre, les accessoires hors série du véhicules à
                concurrence de 3 000 euros. Avec application d’une franchise égale à 10% du montant des dommages avec un
                minimum de 76 euros.</li>
            </ul>
            </p>

<p class="px-1">
    Garantie valeur d'acquisition du véhicule
<ul>
<li><strong> Valeur d'acquisition 12 mois</strong> indemnise le véhicule sur sa valeur d’acquisition. </li>
</ul>
</p>

<p class="px-1">
                Franchise en cas de vol ou incendie (par sinistre)
            <ul>
                <li>Avec l’option <strong> Franchise de base (ou partielle)</strong> en cas de vol ou d’incendie, la franchise retenue et à la charge
                    du souscripteur, est réduite au montant indiqué (sous réserve des conditions d’acceptation pour un rachat de franchise partiel).</li>
                <li>Avec l’option <strong> Franchise à 0 €</strong> en cas de vol ou d’incendie, aucune franchise n'est retenue (sous réserve
                    des conditions d’acceptation).</li>
            </ul>
            </p>

            <p class="px-1">
                Franchise en cas de dommages à votre véhicule (par sinistre)
            <ul>
                <li>Avec l’option <strong> Franchise de base (ou partielle)</strong> Dommages, en cas de sinistre totalement ou partiellement
                    responsable, une franchise réduite au montant indiqué est retenue et à charge du souscripteur (sous réserve des conditions d’acceptation pour un rachat de franchise partiel).</li>
                <li>Avec l’option <strong> Franchise à 0 €</strong> Dommages, en cas de sinistre totalement ou partiellement responsable,
                    aucune franchise n'est retenue (sous réserve des conditions d’acceptation).</li>
            </ul>
            </p>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-row>
      <b-col lg="12">
        <b-button v-if="canNext" class="float-right" variant="primary" size="lg" @click="submitProduit(0)"> Suivant
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { updateProduitTarif } from './../../../../shared/utils/TarifCalcul'
import { BOverlay, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BFormCheckbox, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea, VBTooltip, VBPopover, BAlert, BBadge } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    BBadge
  },
  props: ['showLoading'],
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['getEtude']),
    canNext: function () {
      return _.find(this.resumeLocal[0]?.produitsFiltred, function (p) {
        return p.tarif.choix_client
      })
    },
    hasPmssPrivilege: function () {
      let dateEffetYear = moment(this.resumeLocal[0].objets.objet_moto[0].date_effet, 'DD/MM/YYYY').year()
      if (this.currentYear + 1 == dateEffetYear) {
        return true
      }
      return false
    }
  },
  watch: {
    // getItemsRisque: {
    //     handler: function (val, oldVal) {
    //       this.devis = this.getItemsRisque.ItemsRisque;
    //     },
    //     immediate: true,
    //     deep:true
    // },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover
  },
  data() {
    return {
      listOptionsFinal: [],
      resumeLocal: [],
      indexLocal: 0,
      indexRisque: 0,
      prospect: null,
      hasProduct: false,
      hasCommentedProduit: false,
      hasSelectedProduit: false,
      BooleanValue: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' }
      ],
      BooleanValue1: [
        { value: 1, text: 'Oui' },
        { value: 0, text: 'Non' }
      ],
      currentYear: moment(new Date(), 'DD/MM/YYYY').year()
    }
  },
  created() {
    this.getDataResume()
  },
  methods: {
    getKeySortByPrice(indexRisque) {
      switch (this.resumeLocal[indexRisque].devis.fractionnement.toLowerCase()) {
        case 'mois':
          return 'tarif.mensuelle'
        case 'annee':
          return 'tarif.annuelle'
        default:
          return 'tarif.mensuelle'
      }
      
    },
    getDataResume() {
      this.getEtudeData(this.$route.params.id)
        .then(res => {
          this.arrayProduit()
          this.hasProduct = false
          if (this.resumeLocal.length > 0) {
            this.resumeLocal.map(risque => {
              if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
                risque.produitsFiltred.map(rpf => {
                  if (rpf.tarif.presente) this.hasProduct = true

                  if (risque.devis.fractionnement == 'ANNEE') {
                    rpf.tarif.tarif = produit.tarif.tarif_base_annuel
                  } else {
                    rpf.tarif.tarif = produit.tarif.tarif_base
                  }
                })
              }
            })
            this.$emit('updateLoading', false)
          }
        })
        .catch(() => {
          this.$emit('updateLoading', false)
        })
        .finally(() => {
          this.filterListTarifOptions()
        })
    },
    invisibleIfAllPricesEqual0(tarif) {
      if (Number(tarif.mensuelle) > 0 || Number(tarif.trimestrielle) > 0 || Number(tarif.semestrielle) > 0 || Number(tarif.annuelle) > 0) {
        return false
      }
      return true
    },
    async changeSwitchPG(detail, indexRisque, codeFormule, i, j) {
      if (detail.isSelected) {
        //   return data ? data.filter(pr => pr.tarif.presente) : []

        let dataSent = {
          id: detail.tarificationProduitGtNv,
          produitGarantieNvId: detail.produitGarantieNiveauID,
          isProduitGarantie: true,
          prime_option: this.resumeLocal[indexRisque].devis.fractionnement.toLowerCase() == 'mois' ? detail.tarif.mensuelle : detail.tarif.annuelle,
          risque: this.resumeLocal[indexRisque].risque.label
        }
        await this.$http.post('portefeuilles/updateTarificationNiveauGtNv', dataSent).then(response => {
          if (response.data) {
            var indexProduit = this.resumeLocal[indexRisque].produitsFiltred.findIndex(item => item.tarif.codeFormule == codeFormule)
            this.resumeLocal[indexRisque].produitsFiltred[indexProduit].tarif.produitsgaranties[detail.indexPG].produitGarantieNiveauID = [detail.produitGarantieNiveauID]
            this.resumeLocal[indexRisque].produitsFiltred[indexProduit].produitsgaranties[detail.indexPG].produitGarantieNiveauID = [detail.produitGarantieNiveauID]

            var indexFormule = this.listOptionsFinal.findIndex(item => item.codeFormule == codeFormule)
            this.listOptionsFinal[indexFormule].options[i].details.forEach((element, rowIndex) => {
              if (element.isSelected && rowIndex != j) {
                element.isSelected = false
                return 0
              }
            })


            let produitTmp = this.resumeLocal[indexRisque].produitsFiltred[indexProduit]
            let risqueTmp = this.resumeLocal[indexRisque].risque
            let ProduitsGarantiesTmp = this.resumeLocal[indexRisque].produitsFiltred[indexProduit].produitsgaranties

            let { objets } = this.resumeLocal[indexRisque]

            let result = updateProduitTarif(ProduitsGarantiesTmp, produitTmp, this.resumeLocal[indexRisque].risque.label, objets, this.resumeLocal[indexRisque].devis.fractionnement)

            let pGNLibelle = ProduitsGarantiesTmp[detail.indexPG].produitgarantiesniveaux.find(item => item.id == ProduitsGarantiesTmp[detail.indexPG].produitGarantieNiveauID[0]).libelle
           
            this.resumeLocal[indexRisque].produitsFiltred[indexProduit].tarif.tarif = result.prime
            let dataSent = {
              'tarification_id': this.resumeLocal[indexRisque].produitsFiltred[indexProduit].tarif.tarification_id,
              'prime': result.prime
            }
            console.log(result.prime_option.find(item => item.pGNLibelle == pGNLibelle))
            this.$http.post(`portefeuilles/updatePrimeByTarificationId`, dataSent)
              .then(response => {

              })

          }
        })
      }
    },
    checkIsSelectedPG(pg) {
      var truthness = pg.details.some(element => {
        return element.isShow
      })

      return truthness
    },

    filterListTarifOptions() {
      this.listOptionsFinal = []
      this.resumeLocal.forEach(risque => {
        risque.produitsFiltred.forEach(pr => {
          var listOptionsFinalTmp = []
          if (pr.tarif.presente) {
            pr.tarif.produitsgaranties.forEach((pg, indexPG) => {
              var libellePG = pg.libelle_comm
              var orderPG = pg.order
              var tarificationProduitGtNv = pg.tarificationProduitGtNv
              var sendToClientProduitGarantieNiveauID = pg.sendToClientProduitGarantieNiveauID
              var produitGarantieNiveauID = pg.produitGarantieNiveauID

              pr.tarif.optionsCompatibles.forEach(oc => {
                var produitGarantieNiveau = pg.produitgarantiesniveaux.find(item => item.libelle == Object.keys(oc)[0])

                if (produitGarantieNiveau) {
                  var checkerListOptionsFinalTmpNormal = listOptionsFinalTmp.find(item => item.produitGarantieNiveauID == produitGarantieNiveau.id)
                  if (!checkerListOptionsFinalTmpNormal || checkerListOptionsFinalTmpNormal == undefined) {
                    var isSelected = produitGarantieNiveauID && produitGarantieNiveauID.length > 0 && produitGarantieNiveau.id == produitGarantieNiveauID[0] ? true : false
                    var isShow = false
                    // console.log( produitGarantieNiveau.id == produitGarantieNiveauID[0], produitGarantieNiveau.id == sendToClientProduitGarantieNiveauID)
                    if (produitGarantieNiveauID && produitGarantieNiveauID.length > 0 && produitGarantieNiveau.id == produitGarantieNiveauID[0]) {
                      isShow = true
                    } else if (produitGarantieNiveau.id == sendToClientProduitGarantieNiveauID) {
                      isShow = true
                    } else if ((oc[Object.keys(oc)[0]].tarif.mensuelle == 0 && oc[Object.keys(oc)[0]].tarif.trimestrielle == 0 && oc[Object.keys(oc)[0]].tarif.semestrielle == 0 && oc[Object.keys(oc)[0]].tarif.annuelle == 0)) {
                      isShow = true
                    }

                    if (isShow) {
                      listOptionsFinalTmp.push({
                        orderPG,
                        libellePG,
                        tarificationProduitGtNv,
                        indexPG,
                        libelle_commercial: produitGarantieNiveau.libelle_commercial,
                        order: produitGarantieNiveau.order,
                        produitGarantieID: produitGarantieNiveau.produit_garantie_id,
                        produitGarantieNiveauID: produitGarantieNiveau.id,
                        libelle: produitGarantieNiveau.libelle,
                        tarif: oc[Object.keys(oc)[0]].tarif,
                        isSelected,
                        isShow
                      })
                    }
                  }
                }
              })
              var produitGarantieNiveauNon = pg.produitgarantiesniveaux.find(item => item.libelle.includes('non'))

              if (produitGarantieNiveauNon && produitGarantieNiveauNon != undefined && tarificationProduitGtNv != undefined) {
                listOptionsFinalTmp.unshift({
                  orderPG,
                  libellePG,
                  tarificationProduitGtNv,
                  indexPG,
                  libelle_commercial: produitGarantieNiveauNon.libelle_commercial,
                  produitGarantieID: produitGarantieNiveauNon.produit_garantie_id,
                  produitGarantieNiveauID: produitGarantieNiveauNon.id,
                  libelle: produitGarantieNiveauNon.libelle,
                  tarif: { mensuelle: '0.00', trimestrielle: '0.00', semestrielle: '0.00', annuelle: '0.00' },
                  isSelected: produitGarantieNiveauID != undefined && produitGarantieNiveauID.length > 0 ? produitGarantieNiveauID[0] == produitGarantieNiveauNon.id : false,
                  isShow: true
                })
              }
            })
            let result = []

            if (listOptionsFinalTmp.length > 0) {
              result = _.chain(listOptionsFinalTmp)
                .groupBy('libellePG')
                .map((value, key) => ({
                  orderPG: value[0].orderPG,
                  libellePG: key,
                  produitGarantieID: value[0].produitGarantieID,
                  tarificationProduitGtNv: value[0].tarificationProduitGtNv,
                  details: value
                }))
                .value()
              result = _.orderBy(result, ['orderPG'], ['asc'])
            }
            this.listOptionsFinal.push({ codeFormule: pr.tarif.codeFormule, options: result })
          }
        })
      })

      this.listOptionsFinal.forEach((lop, indexLop) => {
        let indexesToRemove = []
        lop.options.forEach((option, indexOption) => {
          if (option.details.filter(item => item.libelle.substr(0, 3) == 'non').length == 1 && option.details.length <= 1) {
            indexesToRemove.push(indexOption)
          }
        })
        lop.options = lop.options.filter((option, index) => !indexesToRemove.includes(index))
      })
    },

    ifChooseSurComp() {
      if (this.resumeLocal[0].produitsFiltred && this.resumeLocal[0].produitsFiltred.length > 0) {
        let choosedSurComp = _.find(this.resumeLocal[0].produitsFiltred, (value, key) => value.hasSurComp && value.tarif.presente)
        return choosedSurComp ? true : false
      } else {
        return false
      }
    },
    ifHasSurComp(produit) {
      produit.hasSurComp = false
      if (produit.garanties && produit.garanties.length > 0) {
        produit.garanties.map(gn => {
          let result = _.find(gn.garantieniveaux, function (gnr) {
            return gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui'
          })
          if (result) {
            produit.hasSurComp = true
          }
        })
      }
      return produit
    },
    comparerExig(ex1, ex2) {
      return ex1 == ex2
    },
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      if (this.resumeLocal[risque].produitsFiltred[produit]) {
        let result = this.resumeLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
          }
        } else {
          return ''
        }
      }
    },
    getPtGtNvExigCorrespondant(risque, produit, exigence, formule) {
      if (this.resumeLocal[risque].produitsFiltred[produit]) {
        let result = this.resumeLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? (resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '') : ''
          }
        } else {
          return ''
        }
      }
    },
    getExigence(egn, egnArray) {
      let tmpOption = _.find(egnArray, (value, key) => value.id == egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    filtreProduit(data) {
      return data ? data.filter(pr => pr.tarif.presente) : []
    },
    async getEtudeData(etude) {
      await this.$http
        .get(`portefeuilles/getEtude/${etude}`)
        .then(r => {
          this.updateTarifModule(r.data.data)
          this.prospect = this.Tiers
          this.$store.commit('setChangedRisque', {})
        })
        .catch(e => {
          console.log(e)
        })
    },
    updateTarifModule(data) {
      let tmpProspect = data.Tiers
      tmpProspect.date_naissance = moment(tmpProspect.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.$store.state.tarifModule.etude = {
        etudeID: data.etudeID,
        etudeNom: data.etudeNom,
        ipid: data.ipid,
        cg: data.cg,
        tg: data.tg,
        Tiers: tmpProspect,
        checkedRisque: data.checkedRisque,
        itemsRisque: data.itemsRisque,
        Conjoint: data.Conjoint,
        Enfant: data.Enfant,
        Parent: data.Parent,
        Entreprise: data.Entreprise,
        changedRisque: false,
        step: data.itemsRisque.ItemsRisque[0].devis.devis_etape_avancement,
        etudeAvancement: null,
        conseilpath: null,
        selectedRisque: 0,
        activeStep: [
          { indexStep: 0, active: 'choix' },
          { indexStep: 1, active: 'beneficiaire' },
          { indexStep: 2, active: 'paiement' },
          { indexStep: 3, active: 'document' },
          { indexStep: 4, active: 'signature' }
        ]
      }
      this.resumeLocal = this.$store.state.tarifModule.etude.itemsRisque.ItemsRisque
    },
    async choisirProduit(tarification) {
      await this.updateProduitSelected(tarification, 0)
    },
    updateProduitSelected(tarification, index) {
      this.resumeLocal[index].produitsFiltred.map((pr, val) => {
        if (pr.tarif.tarification_id == tarification) {
          pr.tarif.choix_client = true
        } else pr.tarif.choix_client = false
      })
    },
    arrayProduit() {
      let produitFiltre = []
      if (this.resumeLocal.length > 0) {
        this.resumeLocal.map(risque => {
          produitFiltre = []
          risque.produitsFiltred = []
          risque.produits.map(produit => {
            if (produit.tarif) {
              produit.tarif.map(tf => {
                const { tarif, ...newProduit } = produit
                let garantiesLocal = tf.garanties
                let produitsgarantiesLocal = tf.produitsgaranties
                const { garanties, ...tarifLocal } = tf
                produitFiltre.push({
                  produit: newProduit,
                  garanties: garantiesLocal,
                  produitsgaranties: produitsgarantiesLocal,
                  tarif: tarifLocal
                })
              })
            }
          })
          if (produitFiltre.length > 0) {
            let data = {
              produits: produitFiltre,
              exigences: risque.exigences,
              garanties: risque.garanties,
              risque: risque.risque.label,
              objets: risque.objets
            }
            //let tmpProduits=selectRisqueAndProduct(data)
            risque.produitsFiltred = this.sortByTauxDec(data.produits)
          }
        })
      }
    },
    sortByTauxDec(data) {
      function UpDowncompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return 1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return -1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return 1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return -1
          }
        }
        return 0
      }
      data.sort(UpDowncompare)
      return data
    },
    formatText(str) {
      var map = {
        '-': ' ',
        '-': '_',
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ'
      }
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern)
      }
      return str.split(' ').join('').toUpperCase()
    },
    submitProduit(index) {
      let validProduit = null
      let typeVente = null
      let is_a_distance = null
      validProduit = this.resumeLocal[index].produitsFiltred.find(pr => pr.tarif.choix_client)
      typeVente = this.resumeLocal[index].devis.type_vente
      is_a_distance = this.resumeLocal[index].devis.is_a_distance

      if (!is_a_distance || (typeVente !== null && is_a_distance)) {
        if (validProduit) {
          if (validProduit.tarif.commentaire_client !== '') {
            this.$emit('updateLoading', true)
            this.hasCommentedProduit = false
            let produisChoisi = []
            this.resumeLocal[index].produitsFiltred.map(rlp => {
              produisChoisi.push({
                choix_client: rlp.tarif.choix_client,
                commentaire_client: rlp.tarif.commentaire_client,
                tarification_id: rlp.tarif.tarification_id,
                assureur_id: rlp.produit.assureur_id
              })
            })
            let data = {
              produisChoisi: produisChoisi,
              devis: this.resumeLocal[index].devis.id
            }
            this.$http
              .post(`portefeuilles/updateTarifChoixClient`, data)
              .then(r => {
                this.updateDevisLoiMadelin()
                this.updateObjetRembPrestationMoto()
                this.updateDevisSouscriptionAvancement(index)
                this.updateDevisTypeVente(this.resumeLocal[0].devis.type_vente)
                this.hasSelectedProduit = false
                this.$emit('updateLoading', false)
                this.$store.commit('setStepTarif', {
                  step: {
                    indexStep: 1,
                    active: 'beneficiaire'
                  }
                })
              })
              .catch(err => {
                this.$emit('updateLoading', false)
                console.log(err)
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Merci de saisir un commentaire',
                icon: 'EditIcon',
                variant: 'danger'
              }
            })
            this.hasCommentedProduit = true
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Merci de Choisir un produit',
              icon: 'EditIcon',
              variant: 'danger'
            }
          })
          this.hasSelectedProduit = true
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Merci de Choisir le type du vente',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
      }
    },
    async updateDevisSouscriptionAvancement(index) {
      let data = {
        id: this.resumeLocal[index].devis.id,
        devis_etape_avancement: 'beneficiaire'
      }
      await this.$http
        .post(`devis/updateDevisSouscriptionState`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    async updateDevisTypeVente(value) {
      let data = {
        id: this.resumeLocal[0].devis.id,
        type_vente: value,
        is_a_distance: this.resumeLocal[0].devis.is_a_distance
      }
      await this.$http
        .post(`devis/updateDevisTypeVente`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    async updateDevisLoiMadelin() {
      let dataMadelin = {
        id: this.resumeLocal[0].devis.id,
        loi_madelin: this.resumeLocal[0].devis.loi_madelin
      }
      this.$http
        .post(`devis/updateDevisLoiMadelin`, dataMadelin)
        .then(r => {
          console.log('loi madelin updated')
        })
        .catch(err => {
          console.log(err)
        })
    },
    async updateObjetRembPrestationMoto() {
      let objetMoto = {
        id: this.resumeLocal[0].objets.objet_moto[0].id,
        objet_type: 'moto'
      }
      this.$http
        .post(`portefeuilles/updateObjetRembPrestationMoto`, objetMoto)
        .then(r => {
          console.log('rembouresement prestation moto updated')
        })
        .catch(err => {
          console.log(err)
        })
    },
    getGtNvByNiveau(produit_garantie, niveau) {
      let tmpGNPOption = _.find(produit_garantie.garantiesniveaux, (value, key) => value.pivot.libelle == niveau)
      return tmpGNPOption ? tmpGNPOption.garantie_niveau_libel : ''
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Intermédiaire':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.resume>.card>.card-header {
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-icon-custom {
  color: #4d25bc;
  margin-top: -5px;
}
</style>

