<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 5 : validation et signature</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-2">
        <h4 class="mb-0">La fiche d’information et de conseil ainsi que le bulletin d’adhésion (DP) de {{ titleClass(Tiers.civilite) }} {{ Tiers.prenom }} {{ Tiers.nom }} sont prêts pour signature.</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h4 class="mb-0">Vous pouvez les consulter ci-dessous pour validation. Après validation, un lien de signature sera directement envoyé à {{ titleClass(Tiers.civilite) }} {{ Tiers.prenom }} {{ Tiers.nom }} par notre partenaire Universign.</h4>
      </b-col>
    </b-row>

    <div class="accordion" role="tablist">
      <div class="mb-2" v-for="(doc, key) in rl.devis.document" :key="key">
        <h4 v-b-toggle="['collapse-signature-'+key]" class="m-1">
          <b-img height="18" :src="require('@/assets/images/icons/doc.png')" class="pr-1"/>
          <span>{{ doc.nomFichier ==="Bulletin d'adhésion non signé" ? "Bulletin d'adhésion non signé (DP)" : doc.nomFichier }}</span>
          <feather-icon icon="ChevronDownIcon" size="22" />
        </h4>
        <b-collapse :id="'collapse-signature-'+key" class="mt-2">
          <b-card>
            <iframe :src="getUrlbase64(doc.base64)" height="800px" width="100%"/>
          </b-card>
        </b-collapse>
      </div>
    </div>
    <b-row>
      <b-col lg="12">
        <b-button variant="primary" class="float-right mt-4" size="lg" :disabled="btn_disabled" @click="signerContrat"> VALIDER ET ENVOYER POUR SIGNATURE </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import url from '@/shared/api/parametre'
import 'vue-pdf-app/dist/icons/main.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { VBTooltip,VBToggle, BCollapse, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea, BImg } from 'bootstrap-vue'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
//import { codeIcon } from './code'

export default {
  components: {
    BCollapse,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BImg
  },
  props: ['showLoading'],
  computed: {
    ...mapGetters(['Tiers']),
    ...mapGetters(['getItemsRisque'])
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  watch: {
    getItemsRisque: {
      handler: function (val, oldVal) {
        this.rl = this.getItemsRisque.ItemsRisque[0]
      },
      immediate: true
    }
  },
  data() {
    return {
      rl: null,
      config: {
        toolbar: {
          toolbarViewerLeft: { findbar: false }
        }
      },
      config2: {
        toolbar: {
          toolbarViewerLeft: { findbar: false }
        }
      },
      showBa: false,
      showFic: false,
      btn_disabled: false
    }
  },
  methods: {
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    signerContrat() {
      let devis = this.rl.devis
      let risque = this.rl.risque.label
      let objets = this.rl.objets
      let produit = this.rl.produitsFiltred.find(pf => pf.tarif.choix_client)
      let data = {
        devis: devis,
        produit: produit,
        risque: risque,
        prospect: {
          nom: this.Tiers.nom,
          prenom: this.Tiers.prenom,
          tel: this.Tiers.moyen_contact.tel,
          email: this.Tiers.moyen_contact.email
        },
        objets: objets
      }
      this.$emit('updateLoading', true)
      this.btn_disabled = true
      this.$http
        .get(`devis/checkDevisTypeVente/${devis.id}`)
        .then(r => {
          if (r.data.state) {
            this.$http
              .post(`portefeuilles/signerDocumentByAssureur`, data)
              .then((res) => {
                if(res.data.statut == 200) {
                  this.$http.post(`portefeuilles/TransMissDocsDevis`, {idDevis: devis.id}).then(resp => {
                  if(resp.data.statut == 200) {
                    this.$emit('updateLoading', false)
                    this.$swal({
                        icon: 'success',
                        title: 'Envoi de document pour signature',
                        showConfirmButton: false,
                        timer: 4000,
                        customClass: {
                          confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                      })
                      .then(() => {
                        this.$router.push({
                          name: 'projets-list'
                        })
                        //this.btn_disabled=false
                      })
                      .catch(err => {
                          this.$emit('updateLoading', false)
                          this.btn_disabled = false
                          console.log(err)
                        })
                  }
                })
                }
              })
              .catch(err => {
                this.$emit('updateLoading', false)
                this.btn_disabled = false
                console.log(err)
              })
          } else {
            this.$emit('updateLoading', false)
            this.btn_disabled = false
            this.messageToast(
              "Vous avez réalisé ce devis dans le cadre d’une vente en deux temps. La signature du contrat doit se faire dans les 24h suivant l’envoi des documents précontractuels (mail devis). Nous vous invitons à finaliser l’opération à l’issue de ce délai.",
              'Erreur',
              'danger',
              'XIcon',
              10000)
          }
        })
        .catch(err => {
          this.$emit('updateLoading', false)
          console.log(err)
        })
    },
    // getUrlbase64(data){
    //   return "data:application/pdf;base64,"+data
    // },
    geturlPdf(data) {
      let path = data.path.replace('public', url + 'storage')
      path = path.replace('api/', '')
      return path + data.nom
    },
    getUrlbase64(base64) {
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blo = new Blob([byteArray], { type: 'application/pdf' })

      var blob = new Blob([blo], { type: 'application/pdf' })
      var blobURL = URL.createObjectURL(blob)

      return blobURL
    },
    downloadFile(base64, name) {
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const file = new Blob([byteArray], { type: 'application/pdf' })

      // const linkSource = `data:application/pdf;base64,${base64}`;
      // const downloadLink = document.createElement("a");
      // const fileName = name;
      // downloadLink.href = linkSource;
      // downloadLink.download = fileName;
      // downloadLink.click();

      var blob = new Blob([file], { type: 'application/pdf' })
      var blobURL = URL.createObjectURL(blob)
      window.open(blobURL)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body {
  padding: 0 !important;
}
.resume > .card > .card-header {
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.bloc-iframe iframe {
  height: 800px !important;
}
</style>
