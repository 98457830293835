<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <div class="vue-form-wizard mb-3 md" id="addprojectform">
      <div class="wizard-header">
        <h4 class="wizard-title"></h4>
        <p class="category"></p>
      </div>
      <div class="wizard-navigation">
        <div class="wizard-progress-with-circle">
          <div class="wizard-progress-bar" style="background-color: rgb(115, 103, 240); color: rgb(115, 103, 240); width: 10%"></div>
        </div>
        <ul role="tablist" class="wizard-nav wizard-nav-pills">
          <li class="active" step-size="md">
            <a href="javascript:void(0)" class="disabled">
              <div role="tab" tabindex="0" id="step-RISQUES0" aria-controls="RISQUES0" class="wizard-icon-circle md square_shape">
                <div class="wizard-icon-container square_shape" :class="indexStep == 'choixclient' ? 'classActive' : 'classCheck'">
                  <i class="wizard-icon">1</i>
                </div>
              </div>
              <span class="stepTitle active" :class="indexStep == 'choixclient' ? 'classActiveText' : ''" @click="switchStep(0, 'choixclient')"> CHOIX CLIENT </span>
            </a>
          </li>
          <li class="" step-size="md">
            <!-- <a href="javascript:void(0)" class="disabled"> -->
            <a href="javascript:void(0)" class="disabled">
              <div role="tab" tabindex="" id="step-BENEFICIAIRES1" aria-controls="BENEFICIAIRES1" class="wizard-icon-circle md square_shape">
                <div class="wizard-icon-container square_shape" :class="indexStep == 'beneficiaire' ? 'classActive' : 'classCheck'">
                  <i class="wizard-icon">2</i>
                </div>
              </div>
              <span class="stepTitle active" :class="indexStep == 'beneficiaire' ? 'classActiveText' : ''" @click="switchStep(1, 'beneficiaire')"> BÉNÉFICIAIRES </span>
            </a>
          </li>
          <li class="" step-size="md">
            <!-- <a href="javascript:void(0)" class="disabled"> -->
            <a href="javascript:void(0)" class="disabled">
              <div role="tab" tabindex="" id="step-PAIEMENT4" aria-controls="PAIEMENT4" class="wizard-icon-circle md square_shape">
                <div class="wizard-icon-container square_shape" :class="indexStep == 'paiement' ? 'classActive' : 'classCheck'">
                  <i class="wizard-icon">3</i>
                </div>
              </div>
              <span class="stepTitle active" :class="indexStep == 'paiement' ? 'classActiveText' : ''" @click="switchStep(3, 'paiement')"> PAIEMENT </span>
            </a>
          </li>
          <li class="" step-size="md">
            <!-- <a href="javascript:void(0)" class="disabled"> -->
            <a href="javascript:void(0)" class="disabled">
              <div role="tab" tabindex="" id="step-DOCUMENT5" aria-controls="DOCUMENT5" class="wizard-icon-circle md square_shape">
                <div class="wizard-icon-container square_shape" :class="indexStep == 'document' ? 'classActive' : 'classCheck'">
                  <i class="wizard-icon">4</i>
                </div>
              </div>
              <span class="stepTitle active" :class="indexStep == 'document' ? 'classActiveText' : ''" @click="switchStep(4, 'document')"> DOCUMENTS </span>
            </a>
          </li>
          <li class="" step-size="md">
            <!-- <a href="javascript:void(0)" class="disabled"> -->
            <a href="javascript:void(0)" class="disabled">
              <div role="tab" tabindex="" id="step-SIGNATURE6" aria-controls="SIGNATURE6" class="wizard-icon-circle md square_shape">
                <div class="wizard-icon-container square_shape" :class="indexStep == 'signature' ? 'classActive' : 'classCheck'">
                  <i class="wizard-icon">5</i>
                </div>
              </div>
              <span class="stepTitle active" :class="indexStep == 'signature' ? 'classActiveText' : ''" @click="switchStep(5, 'signature')"> SIGNATURE </span>
            </a>
          </li>
        </ul>
        <div style="padding: 1.42rem 1.42rem 2.14rem 1.42rem">
          <div v-show="indexStep == 'choixclient'">
            <ChoixClient :showLoading="showLoading" @updateLoading="updateLoading" ref="choixclient"></ChoixClient>
          </div>
          <div v-show="indexStep == 'beneficiaire'">
            <Beneficiaires :showLoading="showLoading" @updateLoading="updateLoading" :hamon="hamon" ref="beneficiaire"></Beneficiaires>
          </div>
          <div v-show="indexStep == 'paiement'">
            <Paiement :showLoading="showLoading" @updateLoading="updateLoading" :paiement="paiement" :dataPayeur="dataPayeur" ref="paiement"></Paiement>
          </div>
          <div v-show="indexStep == 'document'">
            <Document :hamon="hamon" :paiement="paiement" @updatedentreprise="updatedEntreprise" :dataPayeur="dataPayeur" :updatedentreprise="updatedentreprise" ref="document"></Document>
          </div>
          <div v-if="indexStep == 'signature'">
            <Signature :showLoading="showLoading" @updateLoading="updateLoading" ref="signature"></Signature>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import ChoixClient from './ChoixClient.vue'
import Beneficiaires from './Beneficiaires.vue'
import Paiement from './Paiement.vue'
import Document from './Document.vue'
import RepriseConcurrence from './RepriseConcurrence.vue'
import Signature from './Signature.vue'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BRow, BCol, BOverlay, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BFormCheckboxGroup, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea } from 'bootstrap-vue'
import { required, email } from '@validations'
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCardCode,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    ChoixClient,
    Beneficiaires,
    Paiement,
    Document,
    RepriseConcurrence,
    Signature
  },
  data() {
    return {
      indexStep: 'choixclient',
      showLoading: false,
      activeStep: [{ indexStep: 0, active: 'choixclient' }],
      tarifStep: 0,
      rl: null,
      hamon: {
        id: null,
        raisonSociale: null,
        contratNum: null,
        adresse: null,
        codePostal: null,
        ville_id: null,
        villesData: []
      },
      paiement: {
        id: null,
        iban: null,
        bic: null,
        modePaiement: null,
        periodicite: null,
        paiementTitulaireCompte: false,
        paiementTitulaireNom: null,
        paiementTitulairePrenom: null,
        remboursementPrestationsSante: false,
        remboursementTitulaireCompte: true,
        remboursementTitulaireIban: null,
        remboursementTitulaireBic: null,
        remboursementTitulairePaiementTitulaire: true,
        remboursementTitulairePaiementNom: null,
        remboursementTitulairePaiementPrenom: null
      },
      dataPayeur : {
        id: null,
        type: null,
        s_is_payeur: 1,
        typePayeur: null,
        civilite: null,
        nom: null,
        prenom: null,
        libelle: null,
        lieu_dit_ou_bp:null,
        complement_adresse: null,
        code_postal: null,
        ville_id: null,
        pays: null
      },
      updatedentreprise: false
    }
  },
  computed: {
    ...mapGetters(['Tiers']),
    ...mapGetters(['getItemsRisque'])
  },
  watch: {
    '$store.state.tarifModule.etude': {
      handler: function (val, oldVal) {
        this.indexStep = val.step
        this.activeStep = val.activeStep
      },
      deep: true,
      immediate: true
    },
    getItemsRisque: {
      handler: function (val, oldVal) {
        this.rl = val.ItemsRisque[0]
        if (this.Tiers.id) this.getPaiementByDevis()
        this.getHamon()
      },
      immediate: true
    }
  },
  created() {
    this.showLoading = true
  },
  methods: {
    getHamon() {
      let devisID = this.rl.devis.id
      this.$http
        .get(`ecaglobal/getMandatRiaByDevis/${devisID}`)
        .then(r => {
          this.initHamonData(r.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    initHamonData(data) {
      if (data.id) {
        ;(this.hamon.id = data.id), (this.hamon.raisonSociale = data.assureur_resiliation.raison_sociale), (this.hamon.contratNum = data.contratNum), (this.hamon.adresse = data.assureur_resiliation.adresse), (this.hamon.codePostal = data.assureur_resiliation.code_postal)
        this.hamon.ville_id = data.assureur_resiliation.ville_id
        this.cpKeyup()
      }
    },
    async cpKeyup() {
      let cpLocal = this.hamon.codePostal
      if (cpLocal.length == 5) {
        try {
          this.hamon.villesData = (await this.$http.get(`helpers/${cpLocal}/villes`)).data
        } catch (e) {
          this.hamon.villesData = []
        }
      } else {
        this.hamon.villesData = []
      }
    },
    switchStep(index, step) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: index,
          active: step
        }
      })
      this.indexStep = step
    },
    switchStepNext(index, step) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: index,
          active: step
        }
      })
      this.indexStep = step
    },
    switchStepTarif(step, index) {
      this.tarifStep = index
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: index,
          active: step
        }
      })
      this.indexStep = step
    },
    async getPaiementByDevis(){
      await this.$http.get(`ecaglobal/getPaiementByDevis/${this.rl.devis.id}`)
          .then((r) => {
              if(r.data.length>0){
                this.mapPaiementData(r.data)
              }
          })
          .catch((e)=>{
          console.log(e);
          })
    },
    mapPaiementData(data){
      // let meta=null
      // meta = data.find(el => el.type=='VIR');
      // if(meta){
      //     this.paiement.remboursementTitulaireCompte=true
      //     this.paiement.remboursementTitulaireIban=meta.iban
      //     this.paiement.remboursementTitulaireBic=meta.bic
      //     if(meta.titulaireable){
      //         this.paiement.remboursementTitulairePaiementTitulaire=true
      //         this.paiement.remboursementTitulairePaiementNom=meta.titulaireable.nom
      //         this.paiement.remboursementTitulairePaiementPrenom=meta.titulaireable.prenom
      //     }
      // }
      let meta=null
      if(this.rl.devis.mode_paiement=='PRELEVEMENT'){
        meta = data.find(el => el.type=='PREV');
      } else if(this.rl.devis.mode_paiement=='CHEQUE') {
        meta = data.find(el => el.type=='VIR');
      }
      if(meta){
          this.paiement.iban=meta.iban
          this.paiement.bic=meta.bic

          if(meta.titulaireable && meta.titulaireable.nom){
              this.paiement.paiementTitulaireCompte=true
              this.paiement.paiementTitulaireNom=meta.titulaireable.nom
              this.paiement.paiementTitulairePrenom=meta.titulaireable.prenom
              this.mapPayeurData(meta.titulaireable)
          } else {
            this.paiement.paiementTitulaireCompte=true
            this.paiement.paiementTitulaireNom=meta.souscripteable.nom
            this.paiement.paiementTitulairePrenom=meta.souscripteable.prenom
          }
      }
    },
    mapPayeurData(titulaire) {
      this.dataPayeur.id = titulaire.id
      this.dataPayeur.type = titulaire.type
      this.dataPayeur.s_is_payeur = 0
      this.dataPayeur.typePayeur = titulaire.id == this.rl.objets.objet_moto[0].conducteur.lienable_id ? 'CONDUCTEUR' : 'AUTRE'
      this.dataPayeur.civilite = titulaire.civilite
      this.dataPayeur.nom = titulaire.nom
      this.dataPayeur.prenom = titulaire.prenom
      this.dataPayeur.pays = titulaire.pays_naissance
      this.dataPayeur.libelle = titulaire.moyen_contact.libelle
      this.dataPayeur.lieu_dit_ou_bp = titulaire.moyen_contact.lieu_dit_ou_bp
      this.dataPayeur.complement_adresse = titulaire.moyen_contact.complement_adresse
      this.dataPayeur.code_postal = titulaire.moyen_contact.code_postal
      this.dataPayeur.ville_id = titulaire.moyen_contact.ville_id
    },
    updateLoading(value) {
      this.showLoading = value
    },
    updatedEntreprise() {
      this.updatedentreprise = !this.updatedentreprise
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body {
  padding: 0 !important;
}
.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}
.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.classCheck {
  border-color: rgb(115, 103, 240);
  background-color: rgba(115, 103, 240, 0.08);
}
.classCheck > i {
  color: #4d25bc !important;
}

.classActive {
  border-color: #4d25bc;
  background-color: #4d25bc;
}
.classActive > i {
  color: #fff !important;
}
.classActiveText {
  color: #4d25bc !important;
}
</style>
