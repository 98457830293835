export function santeRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        switch(pr.produit.produit_ws_nom){
            case 'OPTION_BUDGET':
                produitsResult.push(optionBudgetProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function optionBudgetProduit(produit,garantie,exigences){

    let total=0
    let cpt=0
    let tmptauxGarantie=0

    let OPTION_BUDGET_100_B={
        optique:{
            value:1,
            choose:searchoption('Optique'),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire')
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation')
        },
        soinsMmedicaux:{
            value:1,
            choose:searchoption('Soins medicaux')
        },
        aidesAuditives:{
            value:-1,
            choose:searchoption('Aides auditives')
        },
        grNiveau:'Standard'
    }
    let OPTION_BUDGET_150_B={
        optique:{
            value:1,
            choose:searchoption('Optique')
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire')
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation')
        },
        soinsMmedicaux:{
            value:2,
            choose:searchoption('Soins medicaux')
        },
        aidesAuditives:{
            value:-1,
            choose:searchoption('Aides auditives')
        },
        grNiveau:'Standard'
    }
    let OPTION_BUDGET_175_B={
        optique:{
            value:2,
            choose:searchoption('Optique')
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire')
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation')
        },
        soinsMmedicaux:{
            value:2,
            choose:searchoption('Soins medicaux')
        },
        aidesAuditives:{
            value:-1,
            choose:searchoption('Aides auditives')
        },
        grNiveau:'Confort'
    }
    let OPTION_BUDGET_250_B={
        optique:{
            value:3,
            choose:searchoption('Optique')
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire')
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation')
        },
        soinsMmedicaux:{
            value:3,
            choose:searchoption('Soins medicaux')
        },
        aidesAuditives:{
            value:-1,
            choose:searchoption('Aides auditives')
        },
        grNiveau:'Complete'
    }
    let OPTION_BUDGET_300_B={
        optique:{
            value:4,
            choose:searchoption('Optique')
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire')
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation')
        },
        soinsMmedicaux:{
            value:4,
            choose:searchoption('Soins medicaux')
        },
        aidesAuditives:{
            value:-1,
            choose:searchoption('Aides auditives')
        },
        grNiveau:'Complete'
    }

    function mapAndGetResult(exigence){
        let tmpEx= exigence.exigenceniveaux.find( egn => egn.id === exigence.exigenceNiveauID[0]);
        if(tmpEx && tmpEx.exigence_niveau_libel){
            switch(tmpEx.exigence_niveau_libel){
                case 'NON':
                    return -1;
                case 'Min':
                    return 1;
                case 'Moyen':
                    return 2
                case 'Fort':
                    return 3;
                case 'Max':
                    return 4;
                default:
                    return 1;
            }
        }else{
            return 1
        }
    }
    function searchoption(option){
        let tmpOption= exigences.find( pg => pg.label === option);
        return tmpOption ? mapAndGetResult(tmpOption) : 1
    }
    function calcultauxtarif(pgniveau){
        Object.entries(pgniveau).forEach(([key, o]) => {
            if(key!=='grNiveau'){
                total+=o.value/o.choose;
                cpt++
            }
        });
        tmptauxGarantie=pgniveau.grNiveau==garantie ? 1 : 0
        let tmpTotal=(total/cpt+tmptauxGarantie)/2
        if(tmpTotal>1){
            produit.tarif.taux=(1-(tmpTotal-1))
            produit.tarif.tauxtype=1
        }
        else{
            produit.tarif.taux=tmpTotal
            produit.tarif.tauxtype=-1
        }
    }
    
    switch(produit.tarif.formule){
        case 'OPTION_BUDGET_100_B':
            calcultauxtarif(OPTION_BUDGET_100_B)
        break;
        case 'OPTION_BUDGET_150_B':
            calcultauxtarif(OPTION_BUDGET_150_B)
        break;
        case 'OPTION_BUDGET_175_B':
            calcultauxtarif(OPTION_BUDGET_175_B)
        break;
        case 'OPTION_BUDGET_250_B':
            calcultauxtarif(OPTION_BUDGET_250_B)
        break;
        case 'OPTION_BUDGET_300_B':
            calcultauxtarif(OPTION_BUDGET_300_B)
        break;
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}