export function santeAnimauxRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        switch(pr.produit.produit_ws_nom){
            case 'SANTE_ANIM_ECA':
                produitsResult.push(santeAnimEcaProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function santeAnimEcaProduit(produit,garantie,exigences){

    let total=0
    let cpt=0
    let tmptauxGarantie=0

    let REDUITE={
        Fraischirurgicaux:{
            value:3,
            choose:searchoption('FRAIS CHIRURGICAUX')
        },
        Fraishospitalisation:{
            value:-1,
            choose:searchoption('FRAIS HOSPITALISATION')
        },
        Radiotherapie:{
            value:-1,
            choose:searchoption('Radiotherapie')
        },
        Sterilisation:{
            value:3,
            choose:searchoption('Sterilisation')
        },
        Pharmacie:{
            value:2,
            choose:searchoption('Pharmacie')
        },
        Franchise:{
            value:1,
            choose:searchoption('Franchise')
        },
        DelaisCarenceAccident:{
            value:2,
            choose:searchoption('Delais carence accident')
        },
        DelaisCarenceMaladie:{
            value:3,
            choose:searchoption('Delais carence maladie')
        },
        grNiveau:'Economique'
    }
    let CONFORT={
        Fraischirurgicaux:{
            value:3,
            choose:searchoption('FRAIS CHIRURGICAUX')
        },
        Fraishospitalisation:{
            value:3,
            choose:searchoption('FRAIS HOSPITALISATION')
        },
        Radiotherapie:{
            value:-1,
            choose:searchoption('Radiotherapie')
        },
        Sterilisation:{
            value:3,
            choose:searchoption('Sterilisation')
        },
        Pharmacie:{
            value:2,
            choose:searchoption('Pharmacie')
        },
        Franchise:{
            value:2,
            choose:searchoption('Franchise')
        },
        DelaisCarenceAccident:{
            value:2,
            choose:searchoption('Delais carence accident')
        },
        DelaisCarenceMaladie:{
            value:3,
            choose:searchoption('Delais carence maladie')
        },
        grNiveau:'Intermediaire'
    }
    let COMPLETE={
        Fraischirurgicaux:{
            value:3,
            choose:searchoption('FRAIS CHIRURGICAUX')
        },
        Fraishospitalisation:{
            value:3,
            choose:searchoption('FRAIS HOSPITALISATION')
        },
        Radiotherapie:{
            value:2,
            choose:searchoption('Radiotherapie')
        },
        Sterilisation:{
            value:3,
            choose:searchoption('Sterilisation')
        },
        Pharmacie:{
            value:2,
            choose:searchoption('Pharmacie')
        },
        Franchise:{
            value:3,
            choose:searchoption('Franchise')
        },
        DelaisCarenceAccident:{
            value:3,
            choose:searchoption('Delais carence accident')
        },
        DelaisCarenceMaladie:{
            value:3,
            choose:searchoption('Delais carence maladie')
        },
        grNiveau:'Intermediaire'
    }
    let PREMIUM={
        Fraischirurgicaux:{
            value:3,
            choose:searchoption('FRAIS CHIRURGICAUX')
        },
        Fraishospitalisation:{
            value:3,
            choose:searchoption('FRAIS HOSPITALISATION')
        },
        Radiotherapie:{
            value:3,
            choose:searchoption('Radiotherapie')
        },
        Sterilisation:{
            value:3,
            choose:searchoption('Sterilisation')
        },
        Pharmacie:{
            value:3,
            choose:searchoption('Pharmacie')
        },
        Franchise:{
            value:3,
            choose:searchoption('Franchise')
        },
        DelaisCarenceAccident:{
            value:3,
            choose:searchoption('Delais carence accident')
        },
        DelaisCarenceMaladie:{
            value:3,
            choose:searchoption('Delais carence maladie')
        },
        grNiveau:'Optimum'
    }
    let PREMIUM_PLUS={
        Fraischirurgicaux:{
            value:3,
            choose:searchoption('FRAIS CHIRURGICAUX')
        },
        Fraishospitalisation:{
            value:3,
            choose:searchoption('FRAIS HOSPITALISATION')
        },
        Radiotherapie:{
            value:3,
            choose:searchoption('Radiotherapie')
        },
        Sterilisation:{
            value:3,
            choose:searchoption('Sterilisation')
        },
        Pharmacie:{
            value:3,
            choose:searchoption('Pharmacie')
        },
        Franchise:{
            value:3,
            choose:searchoption('Franchise')
        },
        DelaisCarenceAccident:{
            value:3,
            choose:searchoption('Delais carence accident')
        },
        DelaisCarenceMaladie:{
            value:3,
            choose:searchoption('Delais carence maladie')
        },
        grNiveau:'Optimum'
    }

    function mapAndGetResult(exigence){
        let tmpEx= exigence.exigenceniveaux.find( egn => egn.id === exigence.exigenceNiveauID[0]);
        if(tmpEx && tmpEx.exigence_niveau_libel){
            switch(tmpEx.exigence_niveau_libel){
                case 'NON':
                    return -1;
                case 'Min':
                    return 1;
                case 'Moyen':
                    return 2
                case 'Fort':
                    return 3;
                case 'Max':
                    return 4;
                default:
                    return 1;
            }
        }else{
            return 1
        }
    }
    function searchoption(option){
        let tmpOption= exigences.find( pg => pg.label === option);
        return tmpOption ? mapAndGetResult(tmpOption) : 1
    }
    function calcultauxtarif(pgniveau){
        Object.entries(pgniveau).forEach(([key, o]) => {
            if(key!=='grNiveau'){
                total+=o.value/o.choose;
                cpt++
            }
        });
        tmptauxGarantie=pgniveau.grNiveau==garantie ? 1 : 0
        let tmpTotal=(total/cpt+tmptauxGarantie)/2
        if(tmpTotal>1){
            produit.tarif.taux=(1-(tmpTotal-1))
            produit.tarif.tauxtype=1
        }
        else{
            produit.tarif.taux=tmpTotal
            produit.tarif.tauxtype=-1
        }
    }
    
    switch(produit.tarif.formule){
        case 'REDUITE':
            calcultauxtarif(REDUITE)
        break;
        case 'CONFORT':
            calcultauxtarif(CONFORT)
        break;
        case 'COMPLETE':
            calcultauxtarif(COMPLETE)
        break;
        case 'PREMIUM':
            calcultauxtarif(PREMIUM)
        break;
        case 'PREMIUM_PLUS':
            calcultauxtarif(PREMIUM_PLUS)
        break;
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}