export function protectionJuridiqueRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        switch(pr.produit.produit_ws_nom){
            case 'PROTECTION_JURIDIQUE':
                produitsResult.push(protectionJuridiqueProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function protectionJuridiqueProduit(produit,garantie,exigences){
    // ------------------- ECA ASsurances -------------------
    let ESSENTIELLE={
        PatrimoineMobilier:{
            value:0,
            choose:searchoption("Patrimoine mobilier",exigences),
        },
        ConstructionGros:{
            value:0,
            choose:searchoption("Construction et gros travaux",exigences),
        },
        LitigesFamiliaux:{
            value:0,
            choose:searchoption("Divorce – Filiation-Adoption - Succession",exigences),
        },
        UsurpationIdentite:{
            value:0,
            choose:searchoption("E-réputation – Usurpation d'identité",exigences),
        },
        grNiveau:'Economique'
    }
    let CONFORT={
        PatrimoineMobilier:{
            value:2,
            choose:searchoption("Patrimoine mobilier",exigences),
        },
        ConstructionGros:{
            value:2,
            choose:searchoption("Construction et gros travaux",exigences),
        },
        LitigesFamiliaux:{
            value:2,
            choose:searchoption("Divorce – Filiation-Adoption - Succession",exigences),
        },
        UsurpationIdentite:{
            value:2,
            choose:searchoption("E-réputation – Usurpation d'identité",exigences),
        },
        grNiveau:'Haut de gamme'
    }
    console.log(ESSENTIELLE);
    console.log(CONFORT);
    //-------------------ECA SERENISSIA
    // ------------------- Treatment -------------------
    switch(produit.tarif.formule){
        // ------------------- ECA Assurances -------------------
        case 'ESSENTIELLE':
            calcultauxtarif(ESSENTIELLE,garantie,produit)
        break;
        case 'CONFORT':
            calcultauxtarif(CONFORT,garantie,produit)
        break;
        // ---------------------ECA SERENISSIA---------------
        
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function calcultauxtarif(pgniveau,garantie,produit){
    let total=0
    let cpt=0
    Object.entries(pgniveau).forEach(([key, o]) => {
        if(key!=='grNiveau'){
            let somme=0;
            if(o.value==o.choose){
                somme=1
            }
            total+=somme;
            cpt++
        }
    });
    produit.tarif.tauxGn  = pgniveau.grNiveau==garantie ? 1 : 0
    produit.tarif.tauxCouv= total/cpt
}

function searchoption(option,exigences){
    let tmpOption= exigences.find( pg => pg.label === option);
    return tmpOption ? mapAndGetResult(tmpOption) : 1
}

function mapAndGetResult(exigence){
    let tmpEx= exigence.exigenceniveaux.find( egn => egn.id === exigence.exigenceNiveauID[0]);
    if(tmpEx && tmpEx.exigence_niveau_libel){
        switch(tmpEx.exigence_niveau_libel){
            case 'Non':
                return 1;
            case 'Oui':
                return 2;
            default:
                return 0;
        } 
    }else{
        return 0;
    }
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}
