<template>
   <div>
      <b-row>
        <b-col cols="12" class="mb-2">
          <h2 class="mb-0 text-uppercase font-weight-bolder">
              étape 3 : désignation des modes de paiement 
          </h2>
          <h6>
              Choisissez le mode de paiement des cotisations et des prestations 
          </h6>
        </b-col>
      </b-row>  

      <h4 class="title-custom-wizard mb-1"><u>Informations de paiements</u></h4>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Mode de paiement"
            label-for="mode_paiement"
          >
            <b-form-select
              id="mode_paiement"
              v-model="devis.mode_paiement"
              :class="devis.mode_paiement == null ? 'opacited_input' : ''"
              readonly
              disabled
            >
              <b-form-select-option :value="null" disabled>Mode de paiement</b-form-select-option>
              <b-form-select-option value="CHEQUE">Chèque </b-form-select-option>
              <b-form-select-option value="PRELEVEMENT">Prélèvement</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!hasErrors('devis.mode_paiement')">
              {{hasErrors('devis.mode_paiement')}}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Fractionnement"
            label-for="fractionnement"
          >
            <b-form-select
              :disabled="confBySelected() == 20 || confBySelected() == 27"
              v-model="devis.fractionnement"
              :class="devis.fractionnement == null ? 'opacited_input' : ''"
            >
              <b-form-select-option :value="null" disabled>Périodicité</b-form-select-option>
              <b-form-select-option value="MOIS">Mois</b-form-select-option>
              <b-form-select-option value="TRIMESTRE" v-if="devis.mode_paiement=='PRELEVEMENT'">Trimestre</b-form-select-option>
              <b-form-select-option value="SEMESTRE" v-if="devis.mode_paiement=='PRELEVEMENT'">Semestre</b-form-select-option>
              <b-form-select-option value="ANNEE">Année</b-form-select-option>

            </b-form-select>
            <b-form-invalid-feedback :state="!hasErrors('devis.fractionnement')">
              {{hasErrors('devis.fractionnement')}}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Titulaire du compte"
            label-for="paiementTitulaireNom"
          >
            <b-form-input
              class=""
              id="paiementTitulaireNom"
              autocomplete="nope"
              v-model="paiementLocal.paiementTitulaireNom"
              trim
              placeholder="Titulaire du compte"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="!hasErrors('paiement.paiementTitulaireNom')">
             {{hasErrors('paiement.paiementTitulaireNom')}}
          </b-form-invalid-feedback>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Jour du prélèvement"
            label-for="jour_prelev"
          >
            <b-form-select
              :disabled="confBySelected() == 29"
              v-model="devis.jour_de_prelevement"
              :class="devis.jour_de_prelevement == null ? 'opacited_input' : ''"
            >
              <b-form-select-option :value="null" disabled>Jour du prélèvement</b-form-select-option>
              <b-form-select-option value="5">5</b-form-select-option>
              <b-form-select-option value="10">10</b-form-select-option>
              <b-form-select-option value="15">15</b-form-select-option>
              <b-form-select-option value="20">20</b-form-select-option>
              <b-form-select-option value="DERNIERJOUR">Le dernier jour du mois</b-form-select-option>

            </b-form-select>
            <b-form-invalid-feedback :state="!hasErrors('devis.jour_de_prelevement')">
              {{hasErrors('devis.jour_de_prelevement')}}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      
      <!-- <h4 class="title-custom-wizard" v-if="devis.mode_paiement!=='CHEQUE' && devis.mode_paiement!==null"><u>Informations Paiements</u></h4> -->
      <b-row>

        <b-col md="6">
          <b-form-group
            label="IBAN"
            label-for="iban"
          >
            <b-form-input
              id="iban"
              v-model="paiementLocal.iban"
              autocomplete="nope"
              trim
              placeholder="Iban"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="!hasErrors('paiement.iban')">
            {{hasErrors('paiement.iban')}}
          </b-form-invalid-feedback>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="BIC"
            label-for="bic"
          >
            <b-form-input
              id="bic"
              v-model="paiementLocal.bic"
              autocomplete="nope"
              trim
              placeholder="Bic"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="!hasErrors('paiement.bic')">
             {{hasErrors('paiement.bic')}}
          </b-form-invalid-feedback>
        </b-col>
        <!-- <b-col md="12 mb-2">
          <label style="margin-bottom:20px">Le titulaire du compte est-il différent de l'entreprise  ? </label>
          <b-form-radio-group
            label="titulaie_compte"
            id="titulaie_compte"
            v-model="paiementLocal.paiementTitulaireCompte"
            :options="BooleanValue"
          ></b-form-radio-group>
        </b-col> -->

        <!-- <b-col md="6" v-if="paiementLocal.paiementTitulaireCompte">
          <b-form-group
            label="Prénom"
            label-for="paiementTitulairePrenom"
          >
            <b-form-input
              class="text-capitalize"
              id="paiementTitulairePrenom"
              v-model="paiementLocal.paiementTitulairePrenom"
              trim
              placeholder="Prénom"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="!hasErrors('paiement.paiementTitulairePrenom')">
             {{hasErrors('paiement.paiementTitulairePrenom')}}
          </b-form-invalid-feedback>
        </b-col> -->
      </b-row>

      <h4 class="title-custom-wizard mb-1" ><u>Informations de remboursements</u></h4>
      <b-row>
        <b-col md="12 mb-2">
          <label style="margin-bottom:20px">Le compte de remboursement des prestations est-il différent du compte de paiement des cotisations ?</label>
          <b-form-radio-group
            label="remb_diff"
            id="remb_diff"
            v-model="paiementLocal.remboursementTitulaireCompte"
            :options="BooleanValue"
          ></b-form-radio-group>
        </b-col>
        <b-col md="12" v-if="paiementLocal.remboursementTitulaireCompte">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Titulaire du compte"
                label-for="remboursementTitulairePaiementNom"
              >
                <b-form-input
                  id="remboursementTitulairePaiementNom"
                  v-model="paiementLocal.remboursementTitulairePaiementNom"
                  autocomplete="nope"
                  trim
                  placeholder="Titulaire du compte"
                />
                <b-form-invalid-feedback :state="!hasErrors('paiement.remboursementTitulairePaiementNom')">
                  {{hasErrors('paiement.remboursementTitulairePaiementNom')}}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="IBAN de remboursement"
                label-for="remboursementTitulaireIban"
              >
                <b-form-input
                  id="remboursementTitulaireIban"
                  v-model="paiementLocal.remboursementTitulaireIban"
                  autocomplete="nope"
                  trim
                  placeholder="IBAN de remboursement"
                />
                <b-form-invalid-feedback :state="!hasErrors('paiement.remboursementTitulaireIban')">
                  {{hasErrors('paiement.remboursementTitulaireIban')}}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="BIC de remboursement"
                label-for="remboursementTitulaireBic"
              >
                <b-form-input
                  id="remboursementTitulaireBic"
                  v-model="paiementLocal.remboursementTitulaireBic"
                  autocomplete="nope"
                  trim
                  placeholder="BIC de remboursement"
                />
                <b-form-invalid-feedback :state="!hasErrors('paiement.remboursementTitulaireBic')">
                  {{hasErrors('paiement.remboursementTitulaireBic')}}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="confBySelected() == 29">
        <h4 class="title-custom-wizard mb-1"><u>Informations assureur</u></h4>
        <b-row>
          <b-col md="6">
            <b-form-group label="Frais de dossier" label-for="frais_dossier">
              <b-form-select v-model="objetProtectionJuridique.frais_dossier">
                <b-form-select-option :value="null">offerts</b-form-select-option>
                <b-form-select-option value="10">10 €</b-form-select-option>
                <b-form-select-option value="20">20 €</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col lg="12" class="mt-4">
          <b-button
            variant="primary"
            class="float-left"
            size="lg"
            @click="backStep"
          >
            Précédent
          </b-button>
          <b-button
            variant="primary"
            class="float-right"
            size="lg"
            @click="submit"
          >
            Suivant
          </b-button>
        </b-col>
      </b-row>
      
   </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {is422} from '@/shared/utils/response'
import {required} from "vuelidate/lib/validators";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Cleave from 'vue-cleave-component'
import BCardCode from '@core/components/b-card-code'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  // BCardCode,
  BFormSelectOption,
  BFormCheckbox,
  BAlert

} from "bootstrap-vue";
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BCardCode,
    BFormSelectOption,
    Cleave,
    BFormCheckbox,
    BAlert
  },
  props:['paiement','showLoading'],
  computed: {
    ...mapGetters(["getItemsRisque"]),
    ...mapGetters(["Tiers"]),
    // key_model_methode_calcul: function() {
    //   return this.devis.nbr_honoraire == '' || this.devis.nbr_honoraire == null ? true : false 
    // },
    // key_model_honoraire: function() {
    //   return this.devis.methode_calcul == '' || this.devis.methode_calcul == null ? true : false 
    // },
  },
  watch:{
    getItemsRisque: {
        immediate:true,
        handler(val,nld){
            this.rl=val.ItemsRisque[0]
            this.devis=val.ItemsRisque[0].devis
            this.objetProtectionJuridique=val.ItemsRisque[0].objets.objet_protection_juridique
            this.devis.mode_paiement='PRELEVEMENT'
            //this.getPaiementByProspect()
        }
    },
    paiement: {
        immediate:true,
        handler(val,nld){
            this.paiementLocal=val
        }
    },
    'devis.mode_paiement': {
        deep:true,
        handler(val,nld){
          if(val=='PRELEVEMENT')
          this.paiementLocal.remboursementTitulairePaiementTitulaire=true
        }
    },
  },
  data() {
    return {
      devis:{
        mode_paiement:null,
        fractionnement:null
      },
      objetProtectionJuridique: {
        frais_dossier: null,
      },
      paiementLocal:{
          id:null,
          iban:null,
          bic:null,
          modePaiement:null,
          periodicite:null,
          paiementTitulaireCompte:false,
          paiementTitulaireNom:null,
          paiementTitulairePrenom:null,
          remboursementPrestationsSante:false,
          remboursementTitulaireCompte:true,
          remboursementTitulaireIban:null,
          remboursementTitulaireBic:null,
          remboursementTitulairePaiementTitulaire:true,
          remboursementTitulairePaiementNom:null,
          remboursementTitulairePaiementPrenom:null,
      },
      config: {
        dateFormat: 'd/m/Y',      
      },
      BooleanValue: [
        { value: true, text: "Oui" },
        { value: false, text: "Non" },
      ],
      errors:[],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
    };
  },
  validations: {
    // entreprise: { 
    //   denomination_commercial:  { required },
    //   raison_sociale: { required },
    //   siret: { required },
    //   forme_juridique: { required },
    // },
  },
  created() {
  },
  methods: {
    confBySelected() {
      let result=null
      if(this.rl !== undefined) {
        let pr= _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
        if(pr) {
          switch(pr.produit.id) {
              case 29:
                this.devis.jour_de_prelevement = "10"
                result = 29
                break;
              default:
                result = null
            }
        }
      }
      return result
    },
    backStep(){
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:3,
            active:'souscripteur'
          },
      });
    },
    submit(){
        this.$emit('updateLoading',true)
        this.errors=[]
        let data={
            paiement:this.paiementLocal,
            devis:this.devis,
            risque:this.rl.risque,
            prospect:this.Tiers,
            objet_protection_juridique: this.objetProtectionJuridique
        }
        this.$http.post(`portefeuilles/saveorupdatePaiementStrategy`,data).then((r) => {        
            this.updateDevisSouscriptionAvancement()
            this.$store.commit("setStepTarif", {
              step: {
                indexStep:4,
                active:'document'
              },
            });
            this.$emit('updateLoading',false)
          }).catch((err)=>{
            console.log(err)
            if(is422(err))
            {
                this.$emit('updateLoading',false)
                this.errors=err.response.data.errors
            }
        })
      
    },
    async updateDevisSouscriptionAvancement(){
      let data={
          id:this.rl.devis.id,
          devis_etape_avancement:'document'
      }
      await this.$http.post(`devis/updateDevisSouscriptionState`,data).then((r) => {
          return  true
      }).catch((err)=>{
          console.log(err)
      })
    },
    hasErrors(val){
      if(this.errors){
          const found = Object.entries(this.errors).find(([key, value]) => key === val);
          return found ? found[1][0] : false
      }
    },
    async updateDevisRemuneration(){
        let data={
          id:this.rl.devis.id,
          honoraires_remuneration:this.rl.devis.honoraires_remuneration,
          commission_remuneration:this.rl.devis.commission_remuneration,
          autre_remuneration:this.rl.devis.autre_remuneration,
          nbr_honoraire:this.rl.devis.nbr_honoraire,
          methode_calcul:this.rl.devis.methode_calcul
        }
        await this.$http.post(`devis/updateDevisRemuneration`,data).then((r) => {
            return  true
        }).catch((err)=>{
            console.log(err)
        })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle
  .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .checked
  ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.title-custom-wizard{
    color: #4d25bc ;
}
.opacited_input{
    opacity: 0.6;
}
</style>

