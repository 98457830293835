<template>
  <div>
    <b-overlay
      :show="showLoading"
      no-wrap
    />
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 1 : confirmation du choix du client
        </h2>
        <h6>
          Sélectionnez la formule choisie par le client (commentaire obligatoire)
        </h6>
      </b-col>
    </b-row>

    <!-- <b-row class="mb-2">
      <b-col md="12">
        <label>Le client souhaite-t-il bénéficier de la loi Madelin ?</label><br>
        <b-form-radio-group
          label=""
          name="madelinValue"
          v-model="resumeLocal[0].devis.loi_madelin"
          :options="BooleanValue1"
        ></b-form-radio-group>
      </b-col>
    </b-row> -->

    <!-- <b-row class="mb-2">
      <b-col md="12">
        <label>L’assuré souhaite-t-il bénéficier des échanges automatiques avec son régime obligatoire si les accords nécessaires existent ?</label><br>
        <b-form-radio-group
          v-model="resumeLocal[0].objets.objet_deces.remb_prestation_sante"
          :disabled="toggleTransmission"
          label=""
          name="mrembPrestationValue"
          :options="BooleanValue1"
        />
      </b-col>
    </b-row> -->

    <b-row
      v-for="(rl, index) in this.resumeLocal"
      :key="index + 'content'"
      class="pricing-card"
    >
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="12"
        class="mx-auto"
      >
        <b-row style="justify-content: center;">
          <b-col
            v-for="(pr, val) in filtreProduit(rl.produitsFiltred)"
            :key="val + 'produits'"
            md="12"
            lg="6"
            xl="4"
            class="resume"
          >
            <b-card
              align="center"
              :header="pr.produit.produit_nom+` - `+pr.produit.assureur.nom_com_assureur"
              class="text-center"
            >

              <b-card-text
                v-if="pr.tarif.preconise"
                icon="AwardIcon"
              >
                <b-badge variant="warning">
                  <feather-icon
                    icon="AwardIcon"
                    class="mr-25"
                  />
                  <span>recommandée</span>
                </b-badge>
              </b-card-text>
              <div
                v-else
                style="height:35px"
              />
              <h3>{{'Formule '+ pr.tarif.formule_commercial }}</h3>
              <span
                class="d-block"
                style="font-size:12px;height:15px"
              >
                <span v-if="ifHasSurComp(pr) ? pr.hasSurComp : false">{{ formatSurComplementaire(pr.niveauLabel, pr.produit.id) }} </span>
                <span v-else />
              </span>
              <!-- <div style="height:15px">
                <span class="d-block" style="font-size:12px" v-if="ifHasSurComp(pr) ? pr.hasSurComp : false" >Option Surcomplémentaire </span><br>
              </div> -->
              <br>
              <!-- <b-badge
                :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.garanties[0],pr.tarif.formule))"
                class="mb-1"
              >
                {{ getGtNvByNiveau(pr.garanties[0],pr.tarif.formule) }}
              </b-badge> -->

              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    style="font-size: 1.5rem;"
                  >{{  Number(pr.tarif.tarif).toFixed(2) }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                 
                </div>

              </div>
                <b-row>
                <b-col md="12" lg="12" style="text-align:left" class="mb-2 ml-3 d-flex">
                    Capital décès de l'assuré : {{formatLibelleCommercial(pr.tarif.capital_assure)+' €'}}
                    <b-col class="ml-5 p-0" lg="1" v-if="checkAdequation(pr.tarif.formule, index) == 0">
                          <feather-icon
                              icon="PlusIcon"
                              size="16"
                              color="#26bf39"
                          />
                          
                        </b-col>
                          
                        <b-col  v-else-if="checkAdequation(pr.tarif.formule, index) == -1" class="ml-5 p-0" lg="1">
                          <feather-icon
                              icon="MinusIcon"
                              size="16"
                              color="#bf2626"
                          />
                        </b-col>
                        <b-col  v-else-if="checkAdequation(pr.tarif.formule, index) == 1" class="ml-5 p-0" lg="1" >
                          <feather-icon
                              icon="CheckIcon"
                              size="16"
                              color="#26bf39"
                          />
                        </b-col> 
                </b-col>
                <b-col md="12" lg="12" v-if="pr.tarif.capital_conjoint" style="text-align:left"  class="mb-3 ml-3">
                    Capital décès du conjoint : {{formatLibelleCommercial(pr.tarif.capital_conjoint)+' €'}}
                </b-col>
              </b-row>
           

              <b-form-textarea
                v-model="pr.tarif.commentaire_client"
                placeholder="exemple : Le rapport qualité/prix de cette formule est le plus adapté compte tenu du besoin que vous avez exprimé."
                rows="3"
                maxlength="255"
              />

              <b-button
                block
                class="mt-2"
                :variant="pr.tarif.choix_client ? 'primary' : 'outline-primary'"
                @click="choisirProduit(pr.tarif.tarification_id)"
              >
                Choix Client
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-alert
          variant="warning"
          show
          class="mb-2 mx-2"
        >
          <div class="alert-body">
            <span>
              Commentaire : Il s’agit des raisons pour lesquelles cette formule a été choisie.
            </span>
            <br>
            <span>
              La motivation doit être claire, compréhensible et ne pas renvoyer de façon elliptique aux Conditions Générales ou à une documentation non synthétique.
              La motivation doit consister à justifier la cohérence du produit avec les exigences de votre client.
            </span>
          </div>
        </b-alert>

      </b-col>
    </b-row>
    <b-row
      v-if="hasPrvilegeToRepriseConcurrence"
      class="mb-2"
    >
      <b-col md="12">
        <label>S’agit-il d’une reprise concurrence ?</label><br>
        <b-form-radio-group
          v-model="resumeLocal[0].devis.reprise_concurrence"
          label=""
          class="d-inline"
          name="repriseconcurrence"
          :options="BooleanValue1"
        />
        <span
          v-b-popover.html.hover="'En cas d’hospitalisation, les délais d’attente (niveau 5 appliqué pendant 3 mois) sur les garanties suivantes peuvent être abrogés : chirurgie-obstétrique, anesthésie, frais de séjours, chambre particulière (du bloc hospitalisation et du renfort Retraités+), frais d’accompagnement.<br>Les conditions d’abrogation sont les suivantes :  <br> - les garanties précédentes étaient équivalentes <br> - il n’y a pas d’interruption de garanties de plus de 2 mois entre les 2 assureurs <br> - les justificatifs ont été fournis'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :title="'Reprise concurrence'"
          class="ml-1 cursor-pointer"
          variant="outline-primary"
        >
          <feather-icon
            icon="InfoIcon"
            size="20"
          />
        </span>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <!-- <h6>* Adhérents aux dispositifs de pratique tarifaire maitrisée</h6> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <!-- <app-collapse id="collapse-besoin" type="margin">
            <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>SwissLife - Détail des options<u></h4>">
              <ul>
                <li>
                  <strong>Le module Retraité +</strong> permet de bénéficier de forfaits supplémentaires en médecines douces, pharmacie et dentaire (non remboursés par la Sécurité sociale ou tout autre régime obligatoire) ou aide aux frais d’obsèques. Il propose également un forfait complémentaire pour la chambre particulière en cas d’hospitalisation et le versement d’un capital en cas de perte totale et irréversible d’autonomie.
                </li>
          
                <li>
                  <strong>L’option Essentiel Pharma</strong> permet d’optimiser le montant de votre cotisation en limitant la prise en charge aux médicaments remboursés à 65 % par la Sécurité sociale.
                </li>
              </ul>
            </app-collapse-item>

            <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>ECA - Détail des options<u></h4>">
              <ul>
                <li>
                  <strong>L’option Renfort + </strong> permet de bénéficier de garanties en médecines douces et médecines non remboursées par l’Assurance Maladie obligatoire. Elle comprend des forfaits supplémentaires pour la prise en charge de la chambre particulière en secteur conventionné, pour les prothèses dentaires, l’implantologie et la parodontologie non remboursées par l’Assurance Maladie Obligatoire. Elle propose également un capital forfaitaire en cas de décès accidentel de l’Adhérent ou de l’un des assurés au contrat ainsi qu’un capital perte d’autonomie. 
                </li>
        
                <li>
                  <strong>L’option Pharma +</strong> vise à prendre en charge les médicaments remboursés à 65%, 30% et 15%  par l’Assurance Maladie Obligatoire.                
                </li>
              </ul>
            </app-collapse-item>
          </app-collapse> -->

      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-button
          v-if="canNext"
          class="float-right"
          variant="primary"
          size="lg"
          @click="submitProduit(0)"
        >
          Suivant
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  VBTooltip,
  VBPopover,
  BAlert,
  BBadge,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import TreeVue from '@/views/extensions/tree/Tree.vue'
import { selectRisqueAndProduct, updateProduitTarif } from '../../../../shared/utils/TarifCalcul'

// import { codeIcon } from './code'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BAlert,
    BBadge,
  },
  props: ['showLoading'],
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['getEtude']),
    canNext() {
      return _.find(this.resumeLocal[0].produitsFiltred, p => p.tarif.choix_client)
    },
    hasPrvilegeToRepriseConcurrence() {
      let result = false
      const pr = _.find(this.resumeLocal[0].produitsFiltred, p => p.tarif.choix_client)
      if (pr) {
        if (pr.tarif.formule === 'Niveau_6' || pr.tarif.formule === 'Niveau_7' || pr.tarif.formule === 'Niveau_8') {
          result = true
        } else if (pr.tarif.formule === 'Niveau_5') {
          const searchGarantie = _.find(pr.garanties, pg => pg.label === 'Renfort Retraité +')
          if (searchGarantie) {
            const searchGarantieNiveau = _.find(searchGarantie.garantieniveaux, pgn => pgn.id === searchGarantie.garantieNiveauID[0] && pgn.garantie_niveau_libel === 'Oui')
            if (searchGarantieNiveau) {
              result = true
            }
          }
        }
      }
      return result
    },
    toggleTransmission() {
      let result = false
      const pr = _.find(this.resumeLocal[0].produitsFiltred, p => p.tarif.choix_client)
      if (pr && (pr.produit.id == 19 || pr.produit.id == 23)) {
        result = true
      }
      return result
    },
  },
  watch: {
    getItemsRisque: {
      handler(val, oldVal) {
        this.checkerExplicationOptions = { isOption1: false, isOption2: false }
      },
      immediate: true,
      deep: true,
    },
    getSelectedRisque(val, old) {
      this.indexRisque = this.getSelectedRisque
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  data() {
    return {
      disablePrestation: false,
      checkerExplicationOptions: { isOption1: false, isOption2: false },
      resumeLocal: [],
      indexLocal: 0,
      indexRisque: 0,
      prospect: null,
      hasProduct: false,
      hasCommentedProduit: false,
      hasSelectedProduit: false,
      BooleanValue: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' },
      ],
      BooleanValue1: [
        { value: 1, text: 'Oui' },
        { value: 0, text: 'Non' },
      ],
    }
  },
  created() {
    this.getEtudeData(this.$route.params.id).then(res => {
      this.arrayProduit()
      this.hasProduct = false
      console.log('resumeLocal After ArrayProduit')
      console.log(this.resumeLocal)
      if (this.resumeLocal.length > 0) {
        this.resumeLocal.map(risque => {
          if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
            risque.produitsFiltred.map(rpf => {
              if (rpf.tarif.presente) this.hasProduct = true
            })
          }
        })
        this.$emit('updateLoading', false)
      }
    }).catch(() => {
      this.$emit('updateLoading', false)
    })
  },
  mounted() {

  },
  methods: {
    checkAdequation(formuleComm, risqueIndex) {

      const garantiesTmp = this.resumeLocal[risqueIndex].garanties[0]
      const garantieNiveau = garantiesTmp.garantieniveaux.find(item => garantiesTmp.garantieNiveauID[0] == item.id)
      const priceRanges = [
          { id: 0, label: "Moins de 20 000 €", min: 0, max: 19999 },
          { id: 1, label: "Entre 20 000 € et 100 000 €", min: 20000, max: 100000 },
          { id: 2, label: "Entre 100 001 € et 130 000 €", min: 100001, max: 130000 },
          { id: 3, label: "Plus de 130 000 €", min: 130001, max: 150000 }
      ];
      const selectedPriceRange = priceRanges.find(range => range.label === garantieNiveau.garantie_niveau_libel)

      if (formuleComm < selectedPriceRange.min) {
          return -1;
      } else if (formuleComm > selectedPriceRange.max) {
          return 0;
      } else if (formuleComm >= selectedPriceRange.min && formuleComm <= selectedPriceRange.max) {
          return 1;
      } 
    },
  formatLibelleCommercial(libelleCommercial) {
    switch (libelleCommercial) {
        case '10000':
            return "10 000";
        case '20000':
            return "20 000";
        case '50000':
            return "50 000";
        case '70000':
            return "70 000";
        case '100000':
            return "100 000";
        case '110000':
            return "110 000";
        case '120000':
            return "120 000";
        case '130000':
            return "130 000";
        case '140000':
            return "140 000";
        case '150000':
            return "150 000";
        default:
            return '';
    }
    },
    explicationExigenceLibel(exProduitsDetails, produitSelected) {
      const expDetail = exProduitsDetails.find(exp => exp.produit_id == produitSelected)
      return expDetail != undefined ? expDetail.info : ''
    },

    formatSurComplementaire(niveauLabel, produitId) {
      let option = ''
      niveauLabel.forEach(el => {
        // Ajouter les IDs dans Array pour créer une condition pour afficher les explications des étoiles du garanties
        // id 46 => renfort retraité +
        // id 47 => essentiel pharma
        if (el.id == 46) {
          option += `avec ${el.produit_garantie.find(item => item.produit_id === produitId)?.libelle_comm}`

          if (!this.checkerExplicationOptions.isOption1) {
            this.checkerExplicationOptions.isOption1 = true
          }
        } else if (el.id == 47) {
          if (option == '') {
            option = `avec ${el.produit_garantie.find(item => item.produit_id === produitId)?.libelle_comm}`
          } else {
            option += ` et ${el.produit_garantie.find(item => item.produit_id === produitId)?.libelle_comm}`
          }

          if (!this.checkerExplicationOptions.isOption2) {
            this.checkerExplicationOptions.isOption2 = true
          }
        }
      })

      return option
    },
    ifChooseSurComp() {
      if (this.resumeLocal[0].produitsFiltred && this.resumeLocal[0].produitsFiltred.length > 0) {
        const choosedSurComp = _.find(this.resumeLocal[0].produitsFiltred, (value, key) => value.hasSurComp && value.tarif.presente)
        return !!choosedSurComp
      }
      return false
    },
    ifHasSurComp(produit) {
      produit.hasSurComp = false
      produit.niveauLabel = []
      if (produit.garanties && produit.garanties.length > 0) {
        produit.garanties.map(gn => {
          const result = _.find(gn.garantieniveaux, gnr => gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui')
          if (result) {
            produit.niveauLabel.push(gn)
            produit.hasSurComp = true
          }
        })
      }
      return produit
    },
    comparerExig(ex1, ex2) {
      const i = this.getExByScoringNumber(ex1)
      const j = this.getExByScoringNumber(ex2)
      if (i < j) return 1
      if (i > j) return -1
      return 0
    },
    getExByScoringNumber(ex1) {
      switch (ex1) {
        case 'NON':
          return 0
        case 'MIN':
          return 1
        case 'MOYEN':
          return 2
        case 'FORT':
          return 3
        case 'MAX':
          return 4
        case 'MAXPLUS':
          return 5
        default:
          return 1
      }
    },
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      const tmpProduitsFiltred = this.filtreProduit(this.resumeLocal[risque].produitsFiltred)
      if (tmpProduitsFiltred[produit]) {
        const result = tmpProduitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            const resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
          }
        } else {
          return ''
        }
      }
    },
    getPtGtNvExigCorrespondant(risque, produit, exigence, formule) {
      const tmpProduitsFiltred = this.filtreProduit(this.resumeLocal[risque].produitsFiltred)
      if (tmpProduitsFiltred[produit]) {
        const result = tmpProduitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            const resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '' : ''
          }
        } else {
          return ''
        }
      }
    },
    getExigence(egn, egnArray) {
      const tmpOption = _.find(egnArray, (value, key) => value.id == egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    filtreProduit(data) {
      return data ? data.filter(pr => pr.tarif.presente) : []
    },
    async getEtudeData(etude) {
      await this.$http
        .get(`portefeuilles/getEtude/${etude}`)
        .then(r => {
          this.updateTarifModule(r.data.data)
          this.prospect = this.Tiers
          this.$store.commit('setChangedRisque', {})
        })
        .catch(e => {
          console.log(e)
        })
    },
    updateTarifModule(data) {
      const tmpProspect = data.Tiers
      tmpProspect.date_naissance = moment(tmpProspect.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.$store.state.tarifModule.etude = {
        etudeID: data.etudeID,
        etudeNom: data.etudeNom,
        ipid: data.ipid,
        cg: data.cg,
        tg: data.tg,
        Tiers: tmpProspect,
        checkedRisque: data.checkedRisque,
        itemsRisque: data.itemsRisque,
        Conjoint: data.Conjoint,
        Enfant: data.Enfant,
        Entreprise: data.Entreprise,
        changedRisque: false,
        step: data.itemsRisque.ItemsRisque[0].devis.devis_etape_avancement,
        etudeAvancement: null,
        conseilpath: null,
        selectedRisque: 0,
        activeStep: [
          { indexStep: 0, active: 'choix' },
          { indexStep: 1, active: 'souscripteur' },
          { indexStep: 2, active: 'benificaire' },
          { indexStep: 3, active: 'paiement' },
          { indexStep: 4, active: 'document' },
          { indexStep: 5, active: 'signature' },
        ],
      }
      this.resumeLocal = this.$store.state.tarifModule.etude.itemsRisque.ItemsRisque
    },
    async choisirProduit(tarification) {
      await this.updateProduitSelected(tarification, 0)
    },
    updateProduitSelected(tarification, index) {
      this.resumeLocal[index].produitsFiltred.map((pr, val) => {
        if (pr.tarif.tarification_id == tarification) {
          pr.tarif.choix_client = true
          if (pr.produit.id == 19 || pr.produit.id == 23) {
            this.resumeLocal[0].objets.objet_deces.remb_prestation_sante = 1
          }
        } else {
          pr.tarif.choix_client = false
        }
      })
    },
    arrayProduit() {
      let produitFiltre = []
      console.log('resumeLocal')
      console.log(this.resumeLocal)
      if (this.resumeLocal.length > 0) {
        this.resumeLocal.map(risque => {
          produitFiltre = []
          risque.produitsFiltred = []
          risque.produits.map(produit => {
            if (produit.tarif) {
              produit.tarif.map(tf => {
                const { tarif, ...newProduit } = produit
                const garantiesLocal = tf.garanties
                const { garanties, ...tarifLocal } = tf
                produitFiltre.push({
                  produit: newProduit,
                  garanties: garantiesLocal,
                  tarif: tarifLocal,
                })
              })
            }
          })
          if (produitFiltre.length > 0) {
            const data = {
              produits: produitFiltre,
              exigences: risque.exigences,
              garanties: risque.garanties,
              risque: risque.risque.label,
              objets: risque.objets,
            }
            // let tmpProduits=selectRisqueAndProduct(data)
            risque.produitsFiltred = this.sortByTauxDec(data.produits)
          }
        })
      }
    },
    sortByTauxDec(data) {
      function UpDowncompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return 1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return -1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return 1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return -1
          }
        }
        return 0
      }
      data.sort(UpDowncompare)
      return data
    },
    formatText(str) {
      const map = {
        '-': ' ',
        '-': '_',
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ',
      }
      for (const pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern)
      }
      return str.split(' ').join('').toUpperCase()
    },
    submitProduit(index) {
      let validProduit = null
      validProduit = this.resumeLocal[index].produitsFiltred.find(pr => pr.tarif.choix_client)
      if (validProduit) {
        if (validProduit.tarif.commentaire_client !== '') {
          if (this.resumeLocal[index].devis.reprise_concurrence == null && this.hasPrvilegeToRepriseConcurrence) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Merci de préciser s\'il s\'agit d\'une reprise concurrence',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$emit('updateLoading', true)
            this.hasCommentedProduit = false
            const produisChoisi = []
            this.resumeLocal[index].produitsFiltred.map(rlp => {
              produisChoisi.push({
                choix_client: rlp.tarif.choix_client,
                commentaire_client: rlp.tarif.commentaire_client,
                tarification_id: rlp.tarif.tarification_id,
                assureur_id: rlp.produit.assureur_id,
              })
            })
            const data = {
              produisChoisi,
              devis: this.resumeLocal[index].devis.id,
            }
            this.$http.post('portefeuilles/updateTarifChoixClient', data).then(r => {
              // this.updateDevisLoiMadelin()
              this.updateObjetRembPrestationSante()
              this.updateDevisRepriseConcurrence()
              this.updateDevisSouscriptionAvancement(index)
              this.hasSelectedProduit = false
              this.$emit('updateLoading', false)
              this.$store.commit('setStepTarif', {
                step: {
                  indexStep: 1,
                  active: 'souscripteur',
                },
              })
            }).catch(err => {
              this.$emit('updateLoading', false)
              console.log(err)
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Merci de saisir un commentaire',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          this.hasCommentedProduit = true
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Merci de Choisir un produit',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.hasSelectedProduit = true
      }
    },
    async updateDevisSouscriptionAvancement(index) {
      const data = {
        id: this.resumeLocal[index].devis.id,
        devis_etape_avancement: 'souscripteur',
      }
      await this.$http.post('devis/updateDevisSouscriptionState', data).then(r => true).catch(err => {
        console.log(err)
      })
    },
    // async updateDevisLoiMadelin(){
    //       let dataMadelin={
    //           id:this.resumeLocal[0].devis.id,
    //           loi_madelin:this.resumeLocal[0].devis.loi_madelin
    //       }
    //       this.$http.post(`devis/updateDevisLoiMadelin`,dataMadelin).then((r) => {
    //          console.log('loi madelin updated')
    //       }).catch((err)=>{
    //           console.log(err)
    //       })
    // },
    async updateDevisRepriseConcurrence() {
      const dataRepriseConcurrence = {
        id: this.resumeLocal[0].devis.id,
        reprise_concurrence: this.hasPrvilegeToRepriseConcurrence ? this.resumeLocal[0].devis.reprise_concurrence : null,
      }
      this.$http.post('devis/updateDevisRepriseConcurrence', dataRepriseConcurrence).then(r => {
        console.log('reprise concurrence updated')
      }).catch(err => {
        console.log(err)
      })
    },
    async updateObjetRembPrestationSante() {
      const objetSanteDeces = {
        id: this.resumeLocal[0].objets.objet_deces.id,
        remb_prestation_sante: this.resumeLocal[0].objets.objet_deces.remb_prestation_sante,
        objet_type: 'deces',
      }
      this.$http.post('portefeuilles/updateObjetRembPrestationSante', objetSanteDeces).then(r => {
        console.log('rembouresement prestation sante updated')
      }).catch(err => {
        console.log(err)
      })
    },
    getGtNvByNiveau(garantie, niveau) {
      let tmpGNPOption = null
      garantie.produit_garantie.forEach(element => {
        element.garantiesniveaux.forEach(element1 => {
          if (element1.pivot.libelle == niveau) {
            tmpGNPOption = element1.garantie_niveau_libel
            return tmpGNPOption
          }
        })
      })
      return tmpGNPOption
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Complète':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-icon-custom{
  color: #4d25bc;
  margin-top: -5px;
}

.popover {
  max-width: 600px;
  width: auto;
}
</style>
