<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <div class="vue-form-wizard mb-3 md" id="addprojectform">
      <div class="wizard-header">
          <h4 class="wizard-title"></h4>
          <p class="category"></p>
      </div>
      <div class="wizard-navigation">
        <div class="wizard-progress-with-circle">
            <div class="wizard-progress-bar" style="background-color: rgb(115, 103, 240); color: rgb(115, 103, 240); width: 10%;">
            </div>
        </div>
        <ul role="tablist" class="wizard-nav wizard-nav-pills">
            <li class="active" step-size="md">
                <a href="javascript:void(0)" class="disabled">
                <div role="tab" tabindex="0" id="step-RISQUES0" aria-controls="RISQUES0"   class="wizard-icon-circle md  square_shape">
                    <div class="wizard-icon-container square_shape" :class="indexStep == 'choixclient'  ? 'classActive': 'classCheck'">
                      <i class="wizard-icon">1</i>
                    </div>
                </div>
                <span class="stepTitle active" :class="indexStep == 'choixclient'  ? 'classActiveText': ''"   @click="switchStep(0,'choixclient')">
                    CHOIX CLIENT
                </span>
                </a>
            </li>
            <li class="" step-size="md">
                <!-- <a href="javascript:void(0)" class="disabled"> -->
                <a href="javascript:void(0)" class="disabled">
                    <div role="tab" tabindex="" id="step-SOUSCRIPTEUR1" aria-controls="SOUSCRIPTEUR1" class="wizard-icon-circle md square_shape">
                      <div class="wizard-icon-container square_shape" :class="indexStep == 'souscripteur'  ? 'classActive': 'classCheck'" >
                        <i class="wizard-icon">2</i>
                      </div>
                    </div>
                        <span class="stepTitle active" :class="indexStep == 'souscripteur'  ? 'classActiveText': ''" @click="switchStep(1,'souscripteur')">
                          COMPLÉMENT
                        </span>
                </a>
            </li>
        </ul>
        <div  style="padding:1.42rem 1.42rem 2.14rem 1.42rem"> 
          <div v-show="indexStep=='choixclient'">
            <ChoixClient :showLoading="showLoading" @updateLoading="updateLoading"  ref="choixclient" ></ChoixClient>
          </div>
          <div v-show="indexStep=='souscripteur'">
            <Souscripteur :showLoading="showLoading" @updateLoading="updateLoading" :pays="pays" ref="souscripteur" ></Souscripteur>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import ChoixClient from "./ChoixClient.vue";
import Souscripteur from "./Souscripteur.vue";
import moment from "moment"
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckboxGroup,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea
} from "bootstrap-vue";
import { required, email } from "@validations";
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCardCode,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    ChoixClient,
    Souscripteur
  },
  data() {
    return {
      indexStep:'choixclient',
      showLoading:false,
      activeStep:[{indexStep:0,active:'choixclient'}],
      tarifStep:0,
      rl:null,
      paiement:{
          id:null,
          iban:null,
          bic:null,
          modePaiement:null,
          periodicite:null,
          paiementTitulaireCompte:false,
          paiementTitulaireNom:null,
          paiementTitulairePrenom:null,
          remboursementPrestationsSante:false,
          remboursementTitulaireCompte:false,
          remboursementTitulaireIban:null,
          remboursementTitulaireBic:null,
          remboursementTitulairePaiementTitulaire:true,
          remboursementTitulairePaiementNom:null,
          remboursementTitulairePaiementPrenom:null,
      },
      pays:[]
    };
  },
  computed: {
    ...mapGetters(["Tiers"]),
    ...mapGetters(["getItemsRisque"]),
  },
  watch: {
    '$store.state.tarifModule.etude': {
        handler: function (val, oldVal) {
            this.indexStep=val.step
            this.activeStep=val.activeStep
        },
        deep: true,
        immediate:true
    },
    getItemsRisque: {
        handler: function (val, oldVal) {
          this.rl=val.ItemsRisque[0]
          if(this.Tiers.id)
          this.getPaiementByProspect()
          this.getListePays()
        },
        immediate:true
    },
  },
  created(){
    this.showLoading=true
  },
  methods: {
    getListePays(){
      this.$http.get(`tiers/getPays`)
        .then((r) => {
          this.pays=r.data
        })
        .catch((e)=>{
          console.log(e);
        })
    },
    switchStep(index,step){
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.$store.commit("setStepTarif", {
            step: {
              indexStep:index,
              active:step
            },
      });
      this.indexStep=step
    },
    switchStepNext(index,step){
     window.scrollTo({ top: 0, behavior: 'smooth' });
     this.$store.commit("setStepTarif", {
          step: {
            indexStep:index,
            active:step
          },
     });
     this.indexStep=step
    },
    switchStepTarif(step,index){
     this.tarifStep=index
     this.$store.commit("setStepTarif", {
          step: {
            indexStep:index,
            active:step
          },
     });
     this.indexStep=step
    },
    async getPaiementByProspect(){
      await this.$http.get(`ecaglobal/getPaiementByDevis/${this.rl.devis.id}`)
          .then((r) => {
              if(r.data.length>0){ 
                  this.mapPaiementData(r.data)
              }else{
                  this.paiement.paiementTitulaireNom=this.Tiers.prenom +' '+this.Tiers.nom
              }
          })
          .catch((e)=>{
          console.log(e);
          })
    },
    mapPaiementData(data){
      let meta=null
      meta = data.find(el => el.type=='VIR');
      if(meta){
          this.paiement.remboursementTitulaireCompte=true
          this.paiement.remboursementTitulaireIban=meta.iban
          this.paiement.remboursementTitulaireBic=meta.bic
          if(meta.titulaireable){
              this.paiement.remboursementTitulairePaiementTitulaire=true
              this.paiement.remboursementTitulairePaiementNom=meta.titulaireable.nom
              this.paiement.remboursementTitulairePaiementPrenom=meta.titulaireable.prenom
          }
      }
      if(this.rl.devis.mode_paiement=='PRELEVEMENT'){
          let metaPrev = data.find(el => el.type=='PREV');
          if(metaPrev){
              this.paiement.iban=metaPrev.iban
              this.paiement.bic=metaPrev.bic
              if(metaPrev.titulaireable.nom){
                  this.paiement.paiementTitulaireCompte=true
                  this.paiement.paiementTitulaireNom=metaPrev.titulaireable.nom
                  this.paiement.paiementTitulairePrenom=metaPrev.titulaireable.prenom
              }
          }
      }
    },
    updateLoading(value){
      this.showLoading=value
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.propositions .card-header
{
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight:bold;
  font-size: 1.2rem;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.classCheck{
  border-color: rgb(115, 103, 240);
  background-color: rgba(115,103,240,.08);
}
.classCheck >i{
  color:#4d25bc !important; 
}

.classActive{
  border-color: #4d25bc;
  background-color: #4d25bc;
}
.classActive >i{
  color: #fff !important; 
}
.classActiveText{
  color:  #4d25bc !important;
}
</style>
