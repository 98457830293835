<template>
   <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">
            étape 2 : validation des bénéficiaires
        </h2>
        <h6>
           Vérifiez et complétez les informations relatives aux bénéficaires
        </h6>
      </b-col>
    </b-row>
    <b-card-text>
      <h4 class="title-custom-wizard"><u>ASSURÉ PRINCIPAL (Signataire)</u></h4>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Civilité*"
            label-for="civilite"
          >
            <b-form-select
              v-model="prospect.civilite"
              id="civilite"
            >
              <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
              <b-form-select-option value="MR">M.</b-form-select-option>
              <b-form-select-option value="MME">Mme</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!$v.prospect.civilite.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom*"
            label-for="nom"
          >
            <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.nom }}</div>
            <b-form-input v-else
              id="nom"
              v-model="prospect.nom"
              autocomplete="nope"
              trim
              placeholder="Nom"
              class="text-uppercase"
            />
            <b-form-invalid-feedback :state="!$v.prospect.nom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom de naissance*"
            label-for="nom_naissance"
          >
            <b-form-input
              id="nom_naissance"
              v-model="prospect.nom_naissance"
              autocomplete="nope"
              trim
              placeholder="Nom de naissance"
              class="text-uppercase"
            />
            <b-form-invalid-feedback :state="!$v.prospect.nom_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Prénom*"
            label-for="prenom"
          >
            <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.prenom }}</div>
            <b-form-input  v-else
              id="prenom"
              v-model="prospect.prenom"
              autocomplete="nope"
              trim
              placeholder="Prénom"
              class="text-capitalize"
            />
          <b-form-invalid-feedback :state="!$v.prospect.prenom.$error">
            Veuillez renseigner le(s) champ(s) obligatoire(s)
          </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Date de naissance*"
            label-for="date_naissance"
          >
            <div class="form-control" readonly>{{ prospect.date_naissance }}</div>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Code postal de naissance*"
            label-for="code_postal_naissance"
          >
            <b-form-input
              id="code_postal_naissance"
              v-model="prospect.code_postal_naissance"
              autocomplete="nope"
              trim
              placeholder="Code postal de naissance"
              class="text-capitalize"
            />
            <b-form-invalid-feedback :state="!$v.prospect.code_postal_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Commune de naissance*"
            label-for="commune_naissance"
          >
            <b-form-input
              id="commune_naissance"
              v-model="prospect.commune_naissance"
              autocomplete="nope"
              trim
              placeholder="Commune de naissance"
              class="text-capitalize"
            />
            <b-form-invalid-feedback :state="!$v.prospect.commune_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Pays de naissance*" label-for="pays">
            <v-select v-model="prospect.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays"
                      class="flex-grow-1"
                      input-id="pays" label="pays" placeholder="Pays de naissance"
            >
              <template #option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>

              <template #selected-option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>
              <div slot="no-options">Aucune pays disponible.</div>
            </v-select>
            <b-form-invalid-feedback :state="!$v.prospect.pays_naissance.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Nationalité*" label-for="nationalite">
            <v-select v-model="prospect.nationalite" :close-on-select="true" :reduce="nationalite => nationalite.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="nationalites"
                      class="flex-grow-1"
                      input-id="value" label="value" placeholder="Nationalité"
            >
              <template #option="{ value }">
                <span class="ml-50"> {{ value }}</span>
              </template>

              <template #selected-option="{ value }">
                <span class="ml-50"> {{ value }}</span>
              </template>
              <div slot="no-options">Aucune nationalité disponible.</div>
            </v-select>
            <b-form-invalid-feedback :state="!$v.prospect.nationalite.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
            <b-form-group
              label="Situation familiale*"
              label-for="situation_familiale"
            >
              <b-form-select
              v-model="prospect.situation_familiale"
              id="situation_familiale"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option v-for="(val, value) in situationFamiliale" :key="value" :value="val.value">{{val.name}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.situation_familiale.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Catégorie Socioprofessionnelle*" label-for="activite_professionnelle">
              <b-form-select v-model="prospect.statut" id="activite_professionnelle" :state="errors.length > 0 ? false : null">
                <b-form-select-option v-for="(val, value) in statutJuridique" :key="value" :value="val.value">{{val.text}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.statut.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Secteur d’activité*" label-for="secteur_activite">
              <b-form-select v-model="prospect.secteur_activite" id="secteur_activite" :state="errors.length > 0 ? false : null">
                <b-form-select-option value="null" disabled>Choisissez</b-form-select-option>
                <template v-for="secteur in sortedSecteurs">
                  <b-form-select-option :value="secteur.value" :key="secteur.value">{{ secteur.name }}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.secteur_activite.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Nombre d'enfants à charge*" label-for="enfant">
            <b-form-input
              id="enfant"
              type="number"
              v-model="prospect.enfantCharge"
              placeholder="Nombre d'enfants"
            />
            <b-form-invalid-feedback :state="!$v.prospect.enfantCharge.$error">
              <span v-if="!$v.prospect.enfantCharge.required">Veuillez renseigner le(s) champ(s) obligatoire(s)</span>
              <span v-if="!$v.prospect.enfantCharge.between" class="error">Veuillez renseigner une valeur entre 0 et 10</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>

    <b-card-text>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="N° et libellé de la voie*"
              label-for="libelle"
            >
              <b-form-input
                id="libelle"
                v-model="prospect.moyen_contact.libelle"
                autocomplete="nope"
                trim
                placeholder="N° et libellé de la voie"
              />
            <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.libelle.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Complément d'adresse"
              label-for="complement_adresse"
            >
              <b-form-input
                id="complement_adresse"
                v-model="prospect.moyen_contact.complement_adresse"
                autocomplete="nope"
                trim
                placeholder="Complément d'adresse"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Lieu-dit"
              label-for="lieu_dit"
            >
              <b-form-input
                id="lieu_dit"
                v-model="prospect.moyen_contact.lieu_dit_ou_bp"
                autocomplete="nope"
                trim
                placeholder="Lieu-dit"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Code postal*"
              label-for="code_postale"
            >
              <div class="form-control" v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE'" readonly disabled>{{ prospect.moyen_contact.code_postal }}</div>
              <cleave
              v-else
                id="code_postale"
                v-model="prospect.moyen_contact.code_postal"
                autocomplete="nope"
                type="number"
                class="form-control"
                :raw="false"
                :options="options.cp"
                placeholder="Code Postal"
              />
              <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.code_postal.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Ville*"
              label-for="ville"
            >
              <b-form-select
              v-model="prospect.moyen_contact.ville_id"
              id="ville"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option v-for="(val, value) in villesLocal" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.ville_id.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Numéro de téléphone " label-for="phone">
            <div class="d-flex bd-highlight">
              <div class="w-25 bd-highlight">
                <v-select v-model="$v.prospect.moyen_contact.indicatif_tel.$model" :close-on-select="true" :clearable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"
                  :reduce="indicatif => indicatif.indicatif" input-id="indicatif" label="indicatif" class="indicatif-chooser-souscription-santesenior w-100">
                  <template #option="{ indicatif,code }">
                    <span> {{`${code.toUpperCase()} ` + indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">Aucune indicatif disponible.</div>
                </v-select>
              </div>
              <div class="w-75 bd-highlight">
                  <cleave id="phone" v-model="$v.prospect.moyen_contact.tel.$model" :options="options.phone" :raw="false" class="form-control" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" placeholder="ex: 06 00 00 00 00" />
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.tel.$error"> Le numéro de téléphone est obligatoire </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.indicatif_tel.$error"> L'indicatif téléphonique est obligatoire </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="false"> Attention, un numéro de téléphone portable valide est nécessaire pour la signature du contrat. </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>
          </b-col>
        </b-row>
    </b-card-text>

    <b-card-text>
        <b-row>
          <b-col lg="12" class="mt-4">
            <b-button
              variant="primary"
              class="float-left"
              size="lg"
              @click="backStep"
            >
              Précédent
            </b-button>
            <b-button
              variant="primary"
              class="float-right"
              size="lg"
              @click="submit"
            >
              Suivant
            </b-button>
          </b-col>
        </b-row>
    </b-card-text>

   </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {required,minLength,maxLength,numeric,email, between, requiredIf} from "vuelidate/lib/validators";
import Data from "@/shared/constants/risquebesoin";
import {is422} from '@/shared/utils/response';
import moment from 'moment';
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  // BCardCode,
  BFormSelectOption

} from "bootstrap-vue";
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormSelectOption,
    Cleave
  },
  props:["pays","showLoading"],
  computed: {
    ...mapGetters(["Tiers"]),
    ...mapGetters(["getItemsRisque"]),
    sortedSecteurs() {
      return this.secteurActivite.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  watch: {
    Tiers: {
        handler: function (val, oldVal) {
          if(val.id != undefined) {
            this.mapProspectLocal(val)
          }
        },
        immediate: true,
        deep:true
    },
    getItemsRisque: {
        handler: function (val, oldVal) {
          this.rl=val.ItemsRisque[0]
          this.objets=val.ItemsRisque[0].objets
          this.devis=val.ItemsRisque[0].devis
        },
        immediate:true
    },
    "prospect.moyen_contact.code_postal": {
      immediate:true,
      handler(old,nld){
          this.cpKeyup()
      }
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque;
    },
    "prospect.enfantCharge": {
      immediate:true,
      handler(val){
        this.objets.objet_protection_juridique.enfantCharge = val
      }
    },
  },
  data() {
    return {
      devis:{
        loi_madelin:null,
      },
      objets:{
        objet_protection_juridique:{
          enfantCharge: null
        }
      },
      indicatif:[],
      BooleanValue: [
        {
          value: 0,
          text: 'Oui'
        },
        {
          value: 1,
          text: 'Non'
        }
      ],
      ayantdroitList: [
        {
          value: 'sans',
          text: '-- Choisissez --'
        },
        {
          value: 'signataire',
          text: 'Signataire'
        }
      ],
      prospect:{
        id:null,
        civilite:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        type:null,
        numeroOrganisme:null,
        num_secu:null,
        ayantdroit:null,
        situation_familiale:null,
        statut_gda:null,
        nom_naissance:null,
        code_postal_naissance:null,
        commune_naissance:null,
        pays_naissance:null,
        regime_obligatoire:null,
        rang_laser: 1,
        statut: null,
        original_statut: null,
        nationalite: null,
        secteur_activite: null,
        enfantCharge: null,
        isPPE:null,
        moyen_contact:{
          libelle:null,
          lieu_dit_ou_bp:null,
          complement_adresse:null,
          indicatif_tel: null,
          tel:null,
          code_postal:null,
          ville_id:null,
          email:null
        }
      },
      BooleanValue: [
        { value: 1, text: "Oui" },
        { value: 0, text: "Non" },
      ],
      BooleanValueBenif: [
        { value: true, text: "Oui" },
        { value: false, text: "Non" },
      ],
      villesLocal:[],
      situationFamiliale : Data["SITUATIONFAMILIALE"],
      nationalites: Data["NATIONALITES"],
      statutJuridique : Data["ACTIVITEPROFESSIONNELLE"],
      regimeSocial : Data["REGIMESOCIAL"],
      secteurActivite : Data["SECTEUR_ACTIVITES"],
      errors:[],
      rl:null,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          numericOnly: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        delimiter: {
          delimiter: ' ',
          blocks: [2,2,2,2,2],
          uppercase: true,
          numericOnly:true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true,
        },
        cp: {
          blocks: [5],
        },
      },
      config: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },     
      }
    };
  },
  validations: {
    prospect: {
      moyen_contact: {
        tel: { required, minLength: minLength(14) },
        indicatif_tel: { required },
        email: { required, email },
        libelle: { required },
        code_postal: { required },
        ville_id: { required },
      },
      civilite: { required },
      nom: { required },
      prenom: { required },
      situation_familiale: { required },
      secteur_activite: { required },
      enfantCharge:{ required,between: between(0,10) },
      statut: { required },
      ayantdroit: { required },
      nom_naissance: { required },
      nationalite: { required },
      code_postal_naissance: { required },
      commune_naissance: { required },
      pays_naissance: { required },
    }
  },
  created(){
    this.getPaysList()
  },
  methods: {
    getPaysList() {
      this.$http.get(`tiers/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
        if (!this.prospect.moyen_contact.indicatif_tel) {
          this.prospect.moyen_contact.indicatif_tel = this.indicatif[0].indicatif
        }
      })
      .catch((e)=>{
        console.log(e);
      })
    },
    mapProspectLocal(tiers){
      this.prospect.id=tiers.id
      this.prospect.civilite=tiers.civilite
      this.prospect.nom=tiers.nom
      this.prospect.prenom=tiers.prenom
      this.prospect.date_naissance=tiers.date_naissance
      this.prospect.numeroOrganisme=tiers.numeroOrganisme
      this.prospect.type=tiers.type
      this.prospect.situation_familiale=tiers.situation_familiale
      this.prospect.statut_gda=tiers.statut_gda 
      this.prospect.num_secu=tiers.num_secu
      this.prospect.ayantdroit=tiers.ayantdroit 
      this.prospect.nom_naissance=tiers.nom_naissance
      this.prospect.code_postal_naissance=tiers.code_postal_naissance
      this.prospect.commune_naissance=tiers.commune_naissance
      this.prospect.pays_naissance=tiers.pays_naissance
      this.prospect.regime_obligatoire=tiers.regime_obligatoire
      this.prospect.rang_laser=tiers.rang_laser
      this.prospect.isPPE=tiers.isPPE
      this.prospect.statut=tiers.statut
      this.prospect.original_statut=tiers.statut
      this.prospect.nationalite=tiers.nationalite
      this.prospect.secteur_activite=tiers.secteur_activite
      this.prospect.enfantCharge=tiers.enfantCharge
      this.prospect.moyen_contact.libelle=tiers.moyen_contact.libelle
      this.prospect.moyen_contact.lieu_dit_ou_bp=tiers.moyen_contact.lieu_dit_ou_bp
      this.prospect.moyen_contact.complement_adresse=tiers.moyen_contact.complement_adresse
      this.prospect.moyen_contact.tel=tiers.moyen_contact.tel
      this.prospect.moyen_contact.code_postal=tiers.moyen_contact.code_postal
      this.prospect.moyen_contact.ville_id=tiers.moyen_contact.ville_id
      this.prospect.moyen_contact.email=tiers.moyen_contact.email
      this.prospect.moyen_contact.indicatif_tel = tiers.moyen_contact.indicatif_tel
    },
    updateElementInRow(row, columnIndex, newValue) {
      var rowArray = row.split('');
      rowArray[columnIndex] = newValue;
      return rowArray.join('');
    },
    submit() {
      this.errors=[]
      this.$v.$reset();
      this.$v.prospect.$touch();
    
      if (this.$v.prospect.$error) {
          return 0;
      }

 
      this.$emit('updateLoading',true)

      if(this.prospect.original_statut != null && this.prospect.statut !== this.prospect.original_statut) {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Attention, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
          <br><br>
          Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
          <br><br>
          Souhaitez-vous confirmer cette modification ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            html: 'text-left'
          },
          buttonsStyling: false
        })
          .then(confirmed => {
            if (confirmed.value) {
              this.updateDataSouscripteur()
              this.prospect.original_statut = this.prospect.statut
            } else {
              this.$emit('updateLoading',false)
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }
          })
          .catch(() => {
            this.$emit('updateLoading',false)
          }).finally(() => {
          })
      } else {
        this.updateDataSouscripteur()
      }
    },
    updateDataSouscripteur() {
      let data={
        prospect:this.prospect,
        objets:this.objets,
        devis:this.rl.devis.id,
        risque: { label: this.rl.risque.label },
        objetProtectionJuridique: this.objets.objet_protection_juridique,
      }
      this.$http.post(`portefeuilles/updateDataSouscripteurStrategy`,data).then((r) => {
          let dataMadelin={
              id:this.devis.id,
              loi_madelin:this.devis.loi_madelin
          }
           this.$http.post(`devis/updateDevisLoiMadelin`,dataMadelin).then((r) => {
              this.updateDevisSouscriptionAvancement()
              let prosp = JSON.parse(JSON.stringify(this.prospect))
              this.$store.commit('setProspect', {
                Tiers: prosp
              })
              this.$store.commit("setStepTarif", {
                  step: {
                    indexStep:2,
                    active:'paiement'
                  },
              });
              this.$emit('updateLoading',false)
          }).catch((err)=>{
              this.$emit('updateLoading',false)
              console.log(err)
          })
      }).catch((err)=>{
          if(is422(err)){
            this.$emit('updateLoading',false)
            this.errors = err.response.data.errors
          }
      })
    },
    backStep(){
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:1,
            active:'choixclient'
          },
      });
    },
    async cpKeyup(){
      let cp=this.prospect.moyen_contact.code_postal
      if(cp.length==5){
          try{
              this.villesLocal = (await this.$http.get(`helpers/${cp}/villes`)).data
              if(this.prospect.moyen_contact.ville_id==null){
                  this.prospect.moyen_contact.ville_id=this.villesLocal[0].id
              }
          }catch(e){
              console.log(e)
          }
      }else{
              this.villesLocal=[]
              this.prospect.moyen_contact.ville_id=null
      }
    },
    async updateDevisSouscriptionAvancement(){
      let data={
          id:this.rl.devis.id,
          devis_etape_avancement:'paiement'
      }
      await this.$http.post(`devis/updateDevisSouscriptionState`,data).then((r) => {
          return  true
      }).catch((err)=>{
          console.log(err)
      })
    },
    hasErrors(val){
      if(this.errors){
        const found = Object.entries(this.errors).find(([key, value]) => key === val);
        return found ? found[1][0] : false
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle
  .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .checked
  ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.title-custom-wizard{
    color: #4d25bc ;
}
</style>


<style>

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle:focus {
    border-radius: 0.357rem 0 0 0.357rem;
}
</style>