export function deuxRouesRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        switch(pr.produit.produit_ws_nom){
            case 'PRODUIT_DEUX_ROUES':
                produitsResult.push(deuxRouesFMAProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function deuxRouesFMAProduit(produit,garantie,exigences){


    let FORMULE_1={
        grNiveau:'Economique'
    }
    let FORMULE_2={
        grNiveau:'Intermédiaire'
    }
    let FORMULE_3={
        grNiveau:'Intermédiaire'
    }
    let FORMULE_4={
        grNiveau:'Haut de gamme'
    }
    switch(produit.tarif.codeFormule){
        case 'F1':
            calcultauxtarif(FORMULE_1,garantie,produit)
        break;
        case 'F2':
            calcultauxtarif(FORMULE_2,garantie,produit)
        break;
        case 'F3':
            calcultauxtarif(FORMULE_3,garantie,produit)
        break;
        case 'F4':
            calcultauxtarif(FORMULE_4,garantie,produit)
        break;
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function calcultauxtarif(pgniveau,garantie,produit){
    let total=0
    let cpt=0
    Object.entries(pgniveau).forEach(([key, o]) => {
        if(key!=='grNiveau'){
            let somme=0;
            if(o.value==o.choose){
                somme=1
            }
            total+=somme;
            cpt++
        }
    });
    produit.tarif.tauxGn  = pgniveau.grNiveau==garantie ? 1 : 0
    produit.tarif.tauxCouv= total/cpt
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}