import { santeRisque } from './Risque/Sante'
import { santeAnimauxRisque } from './Risque/SanteAnimaux'
import { santeTnsRisque } from './Risque/SanteTns'
import { santeSeniorRisque } from './Risque/SanteSenior'
import { santeActifRisque } from './Risque/SanteActif'
import { deuxRouesRisque } from './Risque/DeuxRoues'
import { mrhRisque } from './Risque/Mrh'
import { protectionJuridiqueRisque } from './Risque/ProtectionJuridique'

export function selectRisqueAndProduct(data) {

  let garanties = data.garanties
  let gnSelectedtmp = garanties[0].garantieniveaux.find(pgn => pgn.id === garanties[0].garantieNiveauID[0])
  let garantie = gnSelectedtmp ? gnSelectedtmp.garantie_niveau_libel : ''
  let exigences = data.exigences
  let produits = data.produits
  let risque = data.risque
  let objets = data.objets

  produits = calculetarifProduitGarantie(produits, [], risque, objets)
  switch (risque) {
    case 'Santé individuelle':
      return santeRisque(produits, garantie, exigences)
    case 'MRH':
      return mrhRisque(produits, garantie, exigences)
    case 'Santé animaux':
      return santeAnimauxRisque(produits, garantie, exigences)
    case 'Santé TNS':
      return santeTnsRisque(produits, garantie, exigences)
    case 'Santé Séniors':
      return santeSeniorRisque(produits, garantie, exigences)
    case '2-Roues':
      return deuxRouesRisque(produits, garantie, exigences)
    case 'Protection juridique':
      return protectionJuridiqueRisque(produits, garantie, exigences)
    case 'Santé Actifs':
      return santeActifRisque(produits, garantie, exigences)
    default:
      return produits
  }
}

function calculetarifProduitGarantie(produits, [], risque, objets) {
  if (risque == 'Santé TNS') {

    let nbrAdulte = 1
    let nbrEnfant = 0
    nbrAdulte = objets.objet_sante_tns.conjoint ? 2 : 1
    nbrEnfant = parseInt(objets.objet_sante_tns.enfantCharge)
    if (nbrEnfant > 2) {
      nbrEnfant = 2
    }
    _.forEach(produits, function (pr) {
      _.forEach(pr.garanties, function (g, key) {
        if (g.garantieNiveauID.length > 0 && key > 0) {
          let result = _.find(g.garantieniveaux, function (gn) {
            return gn.id == g.garantieNiveauID[0] && gn.garantie_niveau_libel == 'Oui'
          })
          if (result) {
            let tmpOption = _.find(pr.tarif.optionsCompatibles, (v, k) => k == g.produit_garantie.libelle)
            if (tmpOption) {
              let total = (parseFloat(tmpOption.Adulte.tarif.mensuelle) * nbrAdulte) + parseFloat(pr.tarif.tarif.tarif) + (parseFloat(tmpOption.Enfant.tarif.mensuelle) * nbrEnfant)
              pr.tarif.tarif.tarif = total.toFixed(2)
            }
          }
        }
      })
    })
  } else {
    _.forEach(produits, function (pr) {
      _.forEach(pr.garanties, function (g, key) {
        if (g.garantieNiveauID.length > 0 && key > 0) {
          let result = _.find(g.garantieniveaux, function (gn) {
            return gn.id == g.garantieNiveauID[0] && gn.garantie_niveau_libel == 'Oui'
          })
          if (result) {
            let tmpOption = _.find(pr.tarif.optionsCompatibles, (v, k) => k == g.produit_garantie.libelle)
            if (tmpOption) {
              let total = parseFloat(tmpOption.tarif.mensuelle) + parseFloat(pr.tarif.tarif)
              pr.tarif.tarif = total.toFixed(2)
            }
          }
        }
      })
    })
  }
  return produits
}

export function updateProduitTarif(garanties, produit, risque, objets, fractionnement = 'MOIS') {
  let total = 0
  let totalpmss=0
  let tmpproduit = null
  if (risque == 'Santé TNS') {
    let nbrAdulte = objets.objet_sante_tns.conjoint ? 2 : 1
    let nbrEnfant = objets.objet_sante_tns.enfantCharge ? parseInt(objets.objet_sante_tns.enfantCharge) : 0
    if (nbrEnfant > 2) {
      nbrEnfant = 2
    }
    _.forEach(garanties, function (g, key) {
      let result
      if (g.garantieNiveauID.length > 0 && key > 0) {
        result = _.find(g.garantieniveaux, function (gn) {
          return gn.id == g.garantieNiveauID[0] && gn.garantie_niveau_libel == 'Oui'
        })
        if (result) {
          let tmpOption = _.find(produit.tarif.optionsCompatibles, (v, k) => k == g.produit_garantie[0].libelle)
          
          if (tmpOption) {
            total += (parseFloat(tmpOption.Adulte.tarif.mensuelle) * nbrAdulte) + (parseFloat(tmpOption.Enfant.tarif.mensuelle) * nbrEnfant)
            totalpmss += (parseFloat(tmpOption.Adulte.tarif_pmss) * nbrAdulte) + (parseFloat(tmpOption.Enfant.tarif_pmss) * nbrEnfant)
          }
        }
      }
    })
    tmpproduit = produit.tarif.tarif
    tmpproduit.tarif = (total + parseFloat(produit.tarif.tarif_base.tarif)).toFixed(2)
    tmpproduit.tarif_pmss = (totalpmss + parseFloat(produit.tarif.tarif_base.tarif_pmss)).toFixed(3)
    return tmpproduit
  }
  else if (risque == 'Santé Séniors') {
    let isSelectedAllOptions = 0

    _.forEach(garanties, function (g, key) {
      let result
      if (g.garantieNiveauID.length > 0 && key > 0) {
        result = _.find(g.garantieniveaux, function (gn) {
          return gn.id == g.garantieNiveauID[0] && gn.garantie_niveau_libel == 'Oui'
        })
        if (result) {
          let tmpOption = _.find(produit.tarif.optionsCompatibles, (v, k) => k == g.produit_garantie[0].libelle)
          if (tmpOption) {
            isSelectedAllOptions += 1

            total = 0
            if (isSelectedAllOptions == 1) {
              total += parseFloat(tmpOption.tarif.mensuelle)
            } else if (isSelectedAllOptions == 2) {
              console.log(produit.tarif.optionsCompatibles)
              if(produit.produit.produit_ws_nom === "SERENISSIA"){
                total += parseFloat(produit.tarif.optionsCompatibles['RENFORT_PHARMA_PLUS'].tarif.mensuelle)
              }else{
                total += parseFloat(produit.tarif.optionsCompatibles['Renfort_et_essentiel_pharma'].tarif.mensuelle)
              }
            }
          }
        }
      }

    })
    let result = total + parseFloat(produit.tarif.tarif_base)
    return result.toFixed(2)
  } 
  else if (risque == '2-Roues') {
    
    var prime_option = []
    garanties.forEach((g, i) => {
      if(i > 0 && g.produitGarantieNiveauID && g.produitGarantieNiveauID.length > 0) {
        let pGNLibelle = g.produitgarantiesniveaux.find(item => item.id == g.produitGarantieNiveauID[0]).libelle
        let tmpOption = produit.tarif.optionsCompatibles.find(item => Object.keys(item)[0] == pGNLibelle)
        if (tmpOption && tmpOption != undefined) {
          if (fractionnement == 'ANNEE') {
            total += parseFloat(tmpOption[pGNLibelle].tarif.annuelle)
            prime_option.push({ pGNLibelle, prime: tmpOption[pGNLibelle].tarif.annuelle })
          } else {
            total += parseFloat(tmpOption[pGNLibelle].tarif.mensuelle)
            prime_option.push({ pGNLibelle, prime: tmpOption[pGNLibelle].tarif.mensuelle })
          }
        }
      }
    })

    let result = 0
    if (fractionnement == 'ANNEE') {
      result = total + parseFloat(produit.tarif.tarif_base_annuel)
    } else {
      result = total + parseFloat(produit.tarif.tarif_base)
    }
    return { prime: result.toFixed(2), prime_option: prime_option }
  } else {
    _.forEach(garanties, function (g, key) {
      let result
      if (g.garantieNiveauID.length > 0 && key > 0) {
        result = _.find(g.garantieniveaux, function (gn) {
          return gn.id == g.garantieNiveauID[0] && gn.garantie_niveau_libel == 'Oui'
        })
        if (result) {
          let tmpOption = _.find(produit.tarif.optionsCompatibles, (v, k) => k == g.produit_garantie.libelle)
          if (tmpOption) {
            total += parseFloat(tmpOption.tarif.mensuelle)
          }
        }
      }
    })
    let result = total + parseFloat(produit.tarif.tarif_base)
    return result.toFixed(2)
  }
}

