<template>
   <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">
            étape 2 : validation des bénéficiaires
        </h2>
        <h6>
           Vérifiez et complétez les informations relatives aux bénéficaires
        </h6>
      </b-col>
    </b-row>
    <b-card-text>
      <h4 class="title-custom-wizard"><u>ASSURÉ PRINCIPAL (Signataire)</u></h4>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Civilité*"
            label-for="civilite"
          >
            <b-form-select
              v-model="prospect.civilite"
              id="civilite"
            >
              <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
              <b-form-select-option value="MR">M.</b-form-select-option>
              <b-form-select-option value="MME">Mme</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!$v.prospect.civilite.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom*"
            label-for="nom"
          >
            <!-- <b-form-input 
              id="nom"
              v-model="prospect.nom"
              
              autocomplete="nope"
              trim
              placeholder="Nom"
              class="text-uppercase"
            /> -->

            <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.nom }}</div>
            <b-form-input v-else
              id="nom"
              v-model="prospect.nom"
              autocomplete="nope"
              trim
              placeholder="Nom"
              class="text-uppercase"
            />
            <b-form-invalid-feedback :state="!$v.prospect.nom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom de naissance*"
            label-for="nom_naissance"
          >
            <b-form-input
              id="nom_naissance"
              v-model="prospect.nom_naissance"
              autocomplete="nope"
              trim
              placeholder="Nom de naissance"
              class="text-uppercase"
            />
            <b-form-invalid-feedback :state="!$v.prospect.nom_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Prénom*"
            label-for="prenom"
          >
            <!-- <b-form-input  
              id="prenom"
              v-model=""
              disabled
              autocomplete="nope"
              trim
              placeholder="Prénom"
              class="text-capitalize"
            /> -->
            <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.prenom }}</div>
            <b-form-input  v-else
              id="prenom"
              v-model="prospect.prenom"
              autocomplete="nope"
              trim
              placeholder="Prénom"
              class="text-capitalize"
            />
          <b-form-invalid-feedback :state="!$v.prospect.prenom.$error">
            Veuillez renseigner le(s) champ(s) obligatoire(s)
          </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Date de naissance*"
            label-for="date_naissance"
          >
            <!-- <b-form-input  v-if="prospect.statut_gda==='VERT'"
              id="date_naissance"
              readonly
              disabled
              v-model="prospect.date_naissance"
              autocomplete="nope"
              trim
              placeholder="Date de naissance"
              class="text-capitalize"
            /> -->
            <div class="form-control" readonly>{{ prospect.date_naissance }}</div>
            <!-- <b-form-input  v-else
              id="date_naissance"
              readonly
              v-model="prospect.date_naissance"
              autocomplete="nope"
              trim
              placeholder="Date de naissance"
              class="text-capitalize"
            /> -->
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Code postal de naissance*"
            label-for="code_postal_naissance"
          >
            <b-form-input
              id="code_postal_naissance"
              v-model="prospect.code_postal_naissance"
              autocomplete="nope"
              trim
              placeholder="Code postal de naissance"
              class="text-capitalize"
            />
            <b-form-invalid-feedback :state="!$v.prospect.code_postal_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Commune de naissance*"
            label-for="commune_naissance"
          >
            <b-form-input
              id="commune_naissance"
              v-model="prospect.commune_naissance"
              autocomplete="nope"
              trim
              placeholder="Commune de naissance"
              class="text-capitalize"
            />
            <b-form-invalid-feedback :state="!$v.prospect.commune_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Pays de naissance*" label-for="pays">
            <v-select v-model="prospect.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays"
                      class="flex-grow-1"
                      input-id="pays" label="pays" placeholder="Pays de naissance"
            >
              <template #option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>

              <template #selected-option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>
              <div slot="no-options">Aucune pays disponible.</div>
            </v-select>
            <b-form-invalid-feedback :state="!$v.prospect.pays_naissance.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Nationalité*" label-for="nationalite">
            <v-select v-model="prospect.nationalite" :close-on-select="true" :reduce="nationalite => nationalite.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="nationalites"
                      class="flex-grow-1"
                      input-id="value" label="value" placeholder="Nationalité"
            >
              <template #option="{ value }">
                <span class="ml-50"> {{ value }}</span>
              </template>

              <template #selected-option="{ value }">
                <span class="ml-50"> {{ value }}</span>
              </template>
              <div slot="no-options">Aucune nationalité disponible.</div>
            </v-select>
            <b-form-invalid-feedback :state="!$v.prospect.nationalite.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
            <b-form-group
              label="Situation familiale*"
              label-for="situation_familiale"
            >
              <b-form-select
              v-model="prospect.situation_familiale"
              id="situation_familiale"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option v-for="(val, value) in situationFamiliale" :key="value" :value="val.value">{{val.name}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.situation_familiale.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Catégorie Socioprofessionnelle*" label-for="activite_professionnelle">
              <b-form-select v-model="prospect.statut" id="activite_professionnelle" :state="errors.length > 0 ? false : null">
                <b-form-select-option v-for="(val, value) in statutJuridique" :key="value" :value="val.value">{{val.text}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.statut.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Secteur d’activité*" label-for="secteur_activite">
              <b-form-select v-model="prospect.secteur_activite" id="secteur_activite" :state="errors.length > 0 ? false : null">
                <b-form-select-option value="null" disabled>Choisissez</b-form-select-option>
                <template v-for="secteur in sortedSecteurs">
                  <b-form-select-option :value="secteur.value" :key="secteur.value">{{ secteur.name }}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.secteur_activite.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Régime de sécurité sociale*"
            label-for="regime_sociale"
          >
            <!-- <b-form-input
              id="regime_sociale"
              v-model="prospect_regime_obligatoire_mapped"
              autocomplete="nope"
              trim
              placeholder="Régime de sécurité sociale"
              class=""
              readonly
            /> -->
            <div class="form-control" readonly disbaled>{{ prospect_regime_obligatoire_mapped }}</div>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Numéro de sécurité sociale*"
            label-for="num_secu_assur"
          >
            <cleave
              id="num_secu_assur"
              v-model="prospect.num_secu"
              :options="options.blockSecu"
              class="form-control"
              :raw="false"
              placeholder="Numéro de sécurite sociale"
            />
            <b-form-invalid-feedback :state="!$v.prospect.num_secu.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="!hasErrors('prospect.num_secu')">
              Le numéro de sécurite sociale est erroné. 
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Code organisme*"
            label-for="numeroOrganisme"
          >
            <cleave
              id="numeroOrganisme"
              v-model="prospect.numeroOrganisme"
              placeholder="Code organisme"
              class="form-control"
              type="number"
              :raw="false"
              :options="options.block"
            />

           

            <b-form-invalid-feedback :state="!$v.prospect.numeroOrganisme.$error && $v.prospect.numeroOrganisme.minLength">
                <span v-if="prospect.numeroOrganisme == null || prospect.numeroOrganisme == ''">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </span>
                   <span v-else>
                      Le champ doit être de 9 caractères              
                    </span>

              </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!hasErrors('prospect.numeroOrganisme')">
                  Le code organisme est erroné. 
                </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- rang laser assuré principal -->
        <b-col md="3">
          <b-form-group
            label="Rang de naissance (laser)"
            label-for="rang_laser_assure"
          >
            <b-form-input
              id="rang_laser_assure"
              v-model="prospect.rang_laser"
              autocomplete="nope"
              placeholder="Rang de naissance (laser)"
              type="number"
              min="0"
            />
          </b-form-group>
        </b-col>
        <!-- rang laser assuré principal -->
        
      </b-row>
    </b-card-text>

    <b-card-text>
        <!-- <h4 class="title-custom-wizard"><u>COORDONNÉES</u></h4> -->
        <b-row>
          <!-- <b-col md="6">
            <b-form-group
              label="Adresse"
              label-for="adresse"
            >
              <b-form-input
                id="adresse"
                v-model="prospect.moyen_contact.adresse"
                trim
                placeholder="Adresse postale"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.adresse.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-col> -->
          <b-col md="6">
            <b-form-group
              label="N° et libellé de la voie*"
              label-for="libelle"
            >
              <b-form-input
                id="libelle"
                v-model="prospect.moyen_contact.libelle"
                autocomplete="nope"
                trim
                placeholder="N° et libellé de la voie"
              />
            <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.libelle.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Complément d'adresse"
              label-for="complement_adresse"
            >
              <b-form-input
                id="complement_adresse"
                v-model="prospect.moyen_contact.complement_adresse"
                autocomplete="nope"
                trim
                placeholder="Complément d'adresse"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Lieu-dit"
              label-for="lieu_dit"
            >
              <b-form-input
                id="lieu_dit"
                v-model="prospect.moyen_contact.lieu_dit_ou_bp"
                autocomplete="nope"
                trim
                placeholder="Lieu-dit"
              />
              <!-- <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.lieu_dit_ou_bp.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Code postal*"
              label-for="code_postale"
            >
              <!-- <cleave
                id="code_postale"
                v-model=""
                autocomplete="nope"
                type="number"
                class="form-control"
                :raw="false"
                :options="options.cp"
                placeholder="Code postal"
                
              /> -->
              <div class="form-control" readonly disabled>{{ prospect.moyen_contact.code_postal }}</div>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Ville*"
              label-for="ville"
            >
              <b-form-select
              v-model="prospect.moyen_contact.ville_id"
              id="ville"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option v-for="(val, value) in villesLocal" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.ville_id.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <!-- <b-form-group
              label="Téléphone portable*"
              label-for="telephone"
            >
              <cleave
                id="telephone"
                v-model="prospect.moyen_contact.tel"
                class="form-control"
                :raw="false"
                :options="options.delimiter"
                placeholder="Téléphone portable"
              />
              <b-form-invalid-feedback :state="false">
                Attention, un numéro de téléphone portable valide est nécessaire pour la signature du contrat.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.tel.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group> -->
            <b-form-group label="Numéro de téléphone " label-for="phone">
            <div class="d-flex bd-highlight">
              <div class="w-25 bd-highlight">
                <v-select v-model="$v.prospect.moyen_contact.indicatif_tel.$model" :close-on-select="true" :clearable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"
                  :reduce="indicatif => indicatif.indicatif" input-id="indicatif" label="indicatif" class="indicatif-chooser-souscription-santesenior w-100">
                  <template #option="{ indicatif,code }">
                    <span> {{`${code.toUpperCase()} ` + indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">Aucune indicatif disponible.</div>
                </v-select>
              </div>
              <div class="w-75 bd-highlight">
                  <cleave id="phone" v-model="$v.prospect.moyen_contact.tel.$model" :options="options.phone" :raw="false" class="form-control" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" placeholder="ex: 06 00 00 00 00" />
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.tel.$error"> Le numéro de téléphone est obligatoire </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.indicatif_tel.$error"> L'indicatif téléphonique est obligatoire </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="false"> Attention, un numéro de téléphone portable valide est nécessaire pour la signature du contrat. </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>
          </b-col>
        </b-row>
    </b-card-text>

      <b-card-text v-if="objets.objet_sante_seniors.conjoint">
        <h4 class="title-custom-wizard"><u>CONJOINT</u></h4>
        <b-row>
          <b-col md="3">
            
            <b-form-group
              label="Civilité*"
              label-for="civiliteConjoint"
            >
              <b-form-select
                v-model="ConjointLocal.civilite"
                id="civiliteConjoint"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option value="MR">M.</b-form-select-option>
                <b-form-select-option value="MME">Mme</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.ConjointLocal.civilite.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!hasErrors('conjoint.civilite')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Nom*"
              label-for="nom_conjoint"
            >
            <div class="form-control" readonly disabled v-if="ConjointLocal.statut_gda=='ORANGE' || ConjointLocal.statut_gda=='ROUGE' || ConjointLocal.statut_gda=='VERT'">{{ ConjointLocal.nom }}</div>
              <b-form-input v-else
                class="text-uppercase"
                id="nom_conjoint"
                v-model="ConjointLocal.nom"
                trim
                placeholder="Nom"
              />
              <b-form-invalid-feedback :state="!$v.ConjointLocal.nom.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!hasErrors('conjoint.nom')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s).
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Prénom*"
              label-for="prenom_conjoint"
            >
            <div class="form-control" readonly disabled v-if="ConjointLocal.statut_gda=='ORANGE' || ConjointLocal.statut_gda=='ROUGE' || ConjointLocal.statut_gda=='VERT'">{{ ConjointLocal.prenom }}</div>
              <b-form-input v-else
                class="text-capitalize"
                id="prenom_conjoint"
                v-model="ConjointLocal.prenom"
                trim
                placeholder="Prénom"
              />
               <b-form-invalid-feedback :state="!$v.ConjointLocal.prenom.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!hasErrors('conjoint.prenom')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s).
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Nom de naissance -->
          <b-col md="3">
            <b-form-group label="Nom de naissance *" label-for="assure_nom_naissance">
                <b-form-input v-model="ConjointLocal.nom_naissance" id="assure_nom_naissance" placeholder="Nom de naissance" type="text" />
                <b-form-invalid-feedback :state="!$v.ConjointLocal.nom_naissance.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Date de naissance*"
              label-for="date_naissance_conjoint"
            >
              <!-- <b-form-input
                class="form-control"
                readonly
                disabled
                v-model="ConjointLocal.date_naissance"
                id="date_naissance_conjoint"
                :config="config"
                placeholder="Date naissance"
              /> -->
              <div class="form-control" readonly disabled >{{ ConjointLocal.date_naissance }}</div>
            </b-form-group>
          </b-col>
              <!-- Code postal de naissance -->
              <b-col md="3">
                <b-form-group label="Code postal de naissance *" label-for="code-postal-naissance">
                    <b-form-input id="code-postal-naissance" v-model="ConjointLocal.code_postal_naissance" type="text" :raw="false" placeholder="Code postal de naissance" />
                    <b-form-invalid-feedback :state="!$v.ConjointLocal.code_postal_naissance.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Commune de naissance -->
              <b-col md="3">
                <b-form-group label="Commune de naissance *" label-for="commune-naissance">
                    <b-form-input v-model="ConjointLocal.commune_naissance" class="text-capitalize" id="commune-naissance" placeholder="Commune de naissance" type="text" />
                    <b-form-invalid-feedback :state="!$v.ConjointLocal.commune_naissance.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Pays de naissance -->
              <b-col md="3">
                  <b-form-group label="Pays de naissance *" label-for="pays-naissance">
                    <v-select v-model="ConjointLocal.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays"
                                      class="flex-grow-1"
                                      input-id="pays" label="pays" placeholder="Pays de naissance"
                            >                      
                      <template #option="{ pays }">
                        <span class="ml-50"> {{ pays }}</span>
                      </template>

                      <template #selected-option="{ pays }">
                        <span class="ml-50"> {{ pays }}</span>
                      </template>
                      <div slot="no-options">Aucune pays disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="!$v.ConjointLocal.pays_naissance.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                  </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Nationalité*" label-for="nationalite">
                  <v-select v-model="ConjointLocal.nationalite" :close-on-select="true"
                    :reduce="nationalite => nationalite.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="nationalites" class="flex-grow-1" input-id="value" label="value"
                    placeholder="Nationalité">
                    <template #option="{ value }">
                      <span class="ml-50"> {{ value }}</span>
                    </template>

                    <template #selected-option="{ value }">
                      <span class="ml-50"> {{ value }}</span>
                    </template>
                    <div slot="no-options">Aucune nationalité disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="!$v.ConjointLocal.nationalite.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
        </b-row>
        
        <b-row>
          <b-col md="12">
            <b-form-group label="Le conjoint possède-t-il un numéro de sécurité social ?">
                <b-form-radio-group v-model="conjointPossedeNumSS" :options="BooleanValue" label="Conjoint à assurer" :disabled="hasPermissionAssureur()" />
                <b-form-invalid-feedback :state="!$v.conjointPossedeNumSS.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="conjointPossedeNumSS">
            <b-row>
              <b-col md="3">
                <b-form-group label="Régime de sécurité sociale" label-for="regime_sociale_conjoint">
                  <div class="form-control" readonly disabled>{{ conjoint_regime_obligatoire_mapped }}</div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Numéro de sécurite sociale*" label-for="num_secu_conjoint">
                  <cleave id="num_secu_conjoint" v-model="ConjointLocal.num_secu" class="form-control" :raw="false" :options="options.blockSecu" placeholder="Numéro de sécurite sociale" />
                  
                    <b-form-invalid-feedback :state="!$v.ConjointLocal.num_secu.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="!hasErrors('conjoint.num_secu')">
                    <span v-if="hasErrors('conjoint.num_secu') == 'Veuillez renseigner le(s) champ(s) obligatoire(s)'">Veuillezrenseigner le(s) champ(s) obligatoire(s)</span>
                    <span v-else>Le numéro de sécurite sociale est erroné. </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Code organisme*" label-for="numeroOrganismeConjoint">
                  <cleave id="numeroOrganismeConjoint" v-model="ConjointLocal.numeroOrganisme" class="form-control" :raw="false"
                    :options="options.block" placeholder="Code organisme" type="number" />

                  <b-form-invalid-feedback :state="!$v.ConjointLocal.numeroOrganisme.$error && $v.ConjointLocal.numeroOrganisme.minLength">
                      <span v-if="ConjointLocal.numeroOrganisme == null || ConjointLocal.numeroOrganisme == ''">
                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                      </span>
                      <span v-else>
                        Le champ doit être de 9 caractères              
                      </span>
                    </b-form-invalid-feedback>
       
                <b-form-invalid-feedback :state="!hasErrors('conjoint.numeroOrganisme')">
                  Le champ doit être de 9 caractères
                </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- rang laser conjoint -->
              <b-col md="3">
                <b-form-group
                  label="Rang de naissance (laser)"
                  label-for="rang_laser"
                >
                  <b-form-input
                    id="complement_adresse"
                    v-model="ConjointLocal.rang_laser"
                    autocomplete="nope"
                    placeholder="Rang de naissance (laser)"
                    type="number"
                    min="0"
                  />
                </b-form-group>
              </b-col>
              <!-- rang laser conjoint -->
            </b-row>
          </b-col>
          <b-col md="4" v-else>
            <b-form-group label="Rattaché à" label-for="rattache_a">
              <b-form-select v-model="ConjointLocal.ayantdroit" id="rattache_a" disabled>
                <b-form-select-option v-for="(val, value) in ayantdroitList" :key="value" :value="val.value"> {{val.text}} </b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!hasErrors('conjoint.ayantdroit')"> Veuillez renseigner le(s) champ(s) obligatoire(s). </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
    </b-card-text>

    <b-card-text v-if="objets.objet_sante_seniors.enfantCharge>0 && formuleSelectedHasEnfnat">
      <h4 class="title-custom-wizard mb-2"><u>ENFANTS</u></h4>
      <b-row v-for="(enfant,index) in enfantsData" :key="index" class="mb-2">
        <b-col md="12">
            <h6 class="">Enfant {{index+1}}</h6>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Civilité*"
            :label-for="`civilite ${index}`"
          >
            <b-form-select
              v-model="enfant.civilite"
              :id="`civilite ${index}`"
            >
              <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
              <b-form-select-option value="MR">M.</b-form-select-option>
              <b-form-select-option value="MME">Mme</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!$v.enfantsData.$each[index].civilite.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :state="!hasErrors('enfant.' + index + '.civilite')">
              Veuillez renseigner le(s) champ(s) obligatoire(s).
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom*"
            :label-for="`nom ${index}`"

          >
          <div class="form-control" readonly disabled v-if="enfant.statut_gda==='VERT' || enfant.statut_gda==='ROUGE' || enfant.statut_gda==='ORANGE' ">{{ enfant.nom }}</div>

            <b-form-input v-else
              :id="`nom ${index}`"
              v-model="enfant.nom"
              trim
              placeholder="Nom"
            />
           <b-form-invalid-feedback :state="!$v.enfantsData.$each[index].nom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :state="!hasErrors('enfant.' + index + '.nom')">
              Veuillez renseigner le(s) champ(s) obligatoire(s).
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Prénom*"
            :label-for="`prenom ${index}`"

          >
          <div class="form-control" readonly disabled v-if="enfant.statut_gda==='VERT' || enfant.statut_gda==='ROUGE' || enfant.statut_gda==='ORANGE' ">{{ enfant.prenom }}</div>

            <b-form-input v-else
              :id="`prenom ${index}`"
              v-model="enfant.prenom"
              trim
              placeholder="Prénom"
            />
            <b-form-invalid-feedback :state="!$v.enfantsData.$each[index].prenom.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="!hasErrors('enfant.' + index + '.prenom')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Date de naissance*"
            :label-for="`date_naissance ${index}`"

          >
          <div class="form-control" readonly disabled>{{ enfant.date_naissance }}</div>
              <b-form-invalid-feedback :state="!$v.enfantsData.$each[index].date_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="!hasErrors('enfant.' + index + '.date_naissance')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Régime de sécurité sociale*" label-for="regime_sociale">
            <div class="form-control" readonly disbaled>{{ enfnat_regime_obligatoire_mapped(enfant.regime_obligatoire_enf) }}</div>
          </b-form-group>
        </b-col>
        <b-col md="12" v-if="!hasPermissionAssureur()">
          <b-form-group> 
            <label class="float-left pr-2">Je déclare que l’enfant poursuit des études et qu’il est
              couvert par le régime du souscripteur ou par un régime étudiant</label>
            <b-form-radio-group :id="`poursuit_etude_option_${index}`" :options="BooleanValue" v-model="enfant.poursuit_etude">
            </b-form-radio-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.poursuit_etude')">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="12" class="mb-2">
          <label>L'enfant possède-t-il un numéro de sécurité sociale ?</label>
          <b-form-radio-group
            label="Option enfant"
            :disabled="disableEnfantNumSec"
            v-model="enfant.hasnumsecu"
            :options="BooleanValueBenif"
            @change="initAyantDroit($event, index)"
          ></b-form-radio-group>
        </b-col>
        <b-col md="4" v-if="enfantsData[index].hasnumsecu">
          <b-form-group
            label="Numéro de sécurite sociale*"
            :label-for="`num_secu ${index}`"
            >
            <cleave
              :id="`num_secu ${index}`"
              v-model="enfant.num_secu"
              :options="options.blockSecu"
              class="form-control"
              :raw="false"
              placeholder="Numéro de sécurite sociale"
            />
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.num_secu')">
                <span v-if="hasErrors('enfant.'+index+'.num_secu') == 'Veuillez renseigner le(s) champ(s) obligatoire(s)'">Veuillez renseigner le(s) champ(s) obligatoire(s)</span>
                <span v-else>Le numéro de sécurite sociale est erroné. </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="4" v-if="enfantsData[index].hasnumsecu">
          <b-form-group
            label="Code organisme*"
            :label-for="`numeroOrganismeE ${index}`"
          >
            <cleave
              :id="`numeroOrganismeE ${index}`"
              v-model="enfant.numeroOrganisme"
              class="form-control"
              :raw="false"
              type="number"
              placeholder="Code organisme"
              :options="options.block"
            />
           
       
                <b-form-invalid-feedback :state="!hasErrors('enfant.' + index + '.numeroOrganisme') && $v.enfantsData.$each[index].numeroOrganisme.minLength">
                  <span v-if="enfantsData[index].numeroOrganisme == null || enfantsData[index].numeroOrganisme == ''">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </span>
                  <span v-else>
                    Le champ doit être de 9 caractères              
                  </span>
                </b-form-invalid-feedback>
          </b-form-group>
          <!-- <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.num_secu')">
            Veuillez renseigner le(s) champ(s) obligatoire(s
          </b-form-invalid-feedback> -->
        </b-col>
        <b-col md="4" v-if="!enfantsData[index].hasnumsecu">
          <b-form-group
            label="Rattaché à"
            :label-for="`ayantdroit ${index}`"
          >
            <b-form-select
              v-model="enfant.ayantdroit"
              :id="`ayantdroit ${index}`"
              :disabled="isAssureurProduitChoisie(enfant)"
            >
              <b-form-select-option value="sans" disabled>Rattaché à</b-form-select-option>
              <b-form-select-option value="signataire">Signataire</b-form-select-option>
              <b-form-select-option value="conjoint" v-if="objets.objet_sante_seniors.conjoint">Conjoint</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.ayantdroit')">
              Veuillez renseigner le(s) champ(s) obligatoire(s).
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Rang de naissance (laser)" :label-for="`rang_laser_enfant_${index}`">
            <b-form-input :id="`rang_laser_enfant_${index}`" v-model="enfant.rang_laser" autocomplete="nope"
              placeholder="Rang de naissance (laser)" type="number" min="0" />
              <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.rang_laser')">
                Le rang de naissance est erroné.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>


    <b-card-text v-if="objets.objet_sante_seniors.ria">
        <h4 class="title-custom-wizard"><u>INFORMATIONS RIA</u></h4>
        <!-- <b-alert
          variant="warning"
          show
          class="mb-2"
        >
          <div class="alert-body">
            <span>
              L'assureur se chargera de la résilitation du contrat existant . Merci de renseigner les informations nécessaires ci-dessous .
            </span>
          </div>
        </b-alert> -->
        <b-row>
          <!-- <b-col md="6">
            <b-form-group
              label="Siret"
              label-for="siret_ria"
            >
              <cleave
                id="siret_ria"
                v-model="riaLocal.siret"
                autocomplete="nope"
                placeholder="Siret"
                class="form-control"
                type="number"
                :raw="false"
                :options="options.blockSiret"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('ria.siret')">
              <span v-if="hasErrors('ria.siret')=='Veuillez renseigner le(s) champ(s) obligatoire(s)'">{{ hasErrors('ria.siret') }}</span>
              <span v-else>Le numéro de SIRET est erroné.</span>
            </b-form-invalid-feedback>
          </b-col> -->
          <b-col md="6">
            <b-form-group
              label="Nom de l'assureur*"
              label-for="raison_socialeE"
            >
              <b-form-input
                id="raison_socialeE"
                v-model="riaLocal.raisonSociale"
                trim
                placeholder="Nom de l'assureur"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.raisonSociale')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Service de résiliation*"
              label-for="serviceResiliationE"
            >
              <b-form-input
                id="serviceResiliationE"
                v-model="riaLocal.serviceResiliation"
                trim
                placeholder="Service de résiliation"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.serviceResiliation')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Adresse*"
              label-for="adresseE"
            >
              <b-form-input
                id="adresseE"
                v-model="riaLocal.adresse"
                trim
                placeholder="Adresse"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.adresse')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Code postal*"
              label-for="codePostalE"
            >
              <cleave
                id="codePostalE"
                v-model="riaLocal.codePostal"
                autocomplete="nope"
                type="number"
                class="form-control"
                :raw="false"
                :options="options.cp"
                placeholder="Code postal"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.codePostal')">
                <span v-if="hasErrors('ria.codePostal')=='Veuillez renseigner le(s) champ(s) obligatoire(s)'">Veuillez renseigner le(s) champ(s) obligatoire(s).</span>
                <span v-else>Le code postal sociale est erroné. </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Ville*"
                label-for="villeE"
              >
                <b-form-select
                v-model="riaLocal.ville_id"
                id="villeE"
                >
                  <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                  <b-form-select-option v-for="(val, value) in riaLocal.villesData" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="!hasErrors('ria.ville_id')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s).
                </b-form-invalid-feedback>
              </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Nom du contrat*"
              label-for="contratNomE"
            >
              <b-form-input
                id="contratNomE"
                v-model="riaLocal.contratNom"
                trim
                placeholder="Nom du contrat"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.contratNom')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Numéro de contrat*"
              label-for="contratNumE"
            >
              <b-form-input
                id="contratNumE"
                v-model="riaLocal.contratNum"
                trim
                placeholder="Numéro de contrat"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.contratNum')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Date d'echeance*"
              label-for="echeance"
            >
              <flat-pickr
                class="form-control"
                v-model="riaLocal.echeance"
                id="echeance"
                :config="config"
                placeholder="Date d'echeance"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.echeance')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

        </b-row>
    </b-card-text>


    <b-alert variant="danger" show v-show="objets.objet_sante_seniors.ria && assureurProduitChoisie" class="mt-1">
          <div class="alert-body">
            <p>Nous vous rappelons qu’un mandat de résiliation sera proposé à la signature en fin de parcours et devra être adressé par vos soins à l’ancien assureur.</p>
            
          </div>
    </b-alert>


    <b-card-text>
        <!-- <h4 class="title-custom-wizard"><u>DONNÉES CONTRACTUELLES</u></h4> -->
        <b-row>
          <!-- <b-col md="6">
            <label>Loi Madelin</label>
            <b-form-radio-group
              label="Le proposant est-il éligible au cadre du RIA?"
              name="madelin"
              v-model="devis.loi_madelin"
              :options="BooleanValue"
            ></b-form-radio-group>
          </b-col> -->

          <b-col lg="12" class="mt-4">
            <b-button
              variant="primary"
              class="float-left"
              size="lg"
              @click="backStep"
            >
              Précédent
            </b-button>
            <b-button
              variant="primary"
              class="float-right"
              size="lg"
              @click="submit"
            >
              Suivant
            </b-button>
          </b-col>
        </b-row>
    </b-card-text>

   </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {required,minLength,maxLength,numeric,email, requiredIf} from "vuelidate/lib/validators";
import Data from "@/shared/constants/risquebesoin";
import {is422} from '@/shared/utils/response';
import moment from 'moment';
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  // BCardCode,
  BFormSelectOption,
  BAlert

} from "bootstrap-vue";
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormSelectOption,
    Cleave,
    BAlert
  },
  props:["ria","pays","showLoading"],
  computed: {
    ...mapGetters(["Tiers"]),
    ...mapGetters(["Conjoint"]),
    ...mapGetters(["Enfant"]),
    ...mapGetters(["getItemsRisque"]),
    prospect_regime_obligatoire_mapped:function(){
        let findRegime = this.regimeSocial.find(obj => {
           return obj.value === this.prospect.regime_obligatoire;
        }); 
        return findRegime ? findRegime.name : ''
    },
    conjoint_regime_obligatoire_mapped:function(){
        let findRegime = this.regimeSocial.find(obj => {
           return obj.value === this.ConjointLocal.regime_obligatoire;
        }); 
        return findRegime ? findRegime.name : ''
    },
    formuleSelectedHasEnfnat :function(){
      let result=false
      let pr= _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
      if(pr && (pr.produit.id == 19 || pr.produit.id  == 27 || pr.produit.id  == 23 || pr.produit.id  == 20 || pr.produit.id  == 26) ){
          result = true
      }
      return result
    },
    sortedSecteurs() {
      return this.secteurActivite.sort((a, b) => a.name.localeCompare(b.name));
    },
    assureurProduitChoisie :function(){
      console.log('11111111')
      let result = false
      let pr = _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
      if(pr && pr.produit.assureur_id == 905){
        return result = true
      }
      return result
    }
  },
  watch: {
    conjointPossedeNumSS: {
      handler(val) {
        if (this.objets.objet_sante_seniors.conjoint) {
          if (val) {
            this.ConjointLocal.ayantdroit = 'sans'
          } else {            
            this.ConjointLocal.ayantdroit = 'signataire'
          }
        }
      }
    },
    Tiers: {
        handler: function (val, oldVal) {
          if(val.id != undefined) {
            this.mapProspectLocal(val)
          }
        },
        immediate: true,
        deep:true
    },
    Conjoint: {
        handler: function (val, oldVal) {
          this.ConjointLocal = val;
        },
        immediate: true,
        deep:true
    },
    ConjointLocal: {
      handler (val) {
        if (val.ayantdroit == 'signataire') {
            this.conjointPossedeNumSS = 0
          } else {            
            this.conjointPossedeNumSS = 1
          }
      },
      deep: true,
      immediate: true
    },
    Enfant: {
        handler: function (val, oldVal) {
          this.EnfantsGlobalLocal = val;
        },
        immediate: true,
    },
    getItemsRisque: {
        handler: function (val, oldVal) {
          this.rl=val.ItemsRisque[0]
          this.objets=val.ItemsRisque[0].objets
          this.devis=val.ItemsRisque[0].devis
          this.getEnfantsData()
        },
        immediate:true
    },
    "prospect.moyen_contact.code_postal": {
      immediate:true,
      handler(old,nld){
          this.cpKeyup()
      }
    },
    "riaLocal.codePostal": {
      immediate:true,
      handler(old,nld){
          this.cpKeyupRia()
      }
    },
    // "ConjointLocal.numeroOrganisme": {
    //   handler(val) {
    //     if (this.ConjointLocal.numeroOrganisme.length != 9) { this.messageErreurConjoint = 'Le champ doit être de 9 caractères' }
    //     else { this.messageErreurConjoint = '' }
    //   }
    // },
    // "enfantsData[0].numeroOrganisme": {
    //   handler(val) {
    //     console.log('1111')
    //   }
    // },
    ria: {
      immediate:true,
      handler(val,nld){
          this.riaLocal=val
      }
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque;
    },
   
  },
  data() {
    return {
      messageErreurConjoint: '',
      conjointPossedeNumSS: 0,
      ConjointLocal:null,
      devis:{
        loi_madelin:null,
      },
      objets:{
        objet_sante_seniors:{
          conjoint:null,
          enfantCharge:null,
        }
      },
      indicatif:[],
      BooleanValue: [
        {
          value: 0,
          text: 'Oui'
        },
        {
          value: 1,
          text: 'Non'
        }
      ],
      ayantdroitList: [
        {
          value: 'sans',
          text: '-- Choisissez --'
        },
        {
          value: 'signataire',
          text: 'Signataire'
        }
      ],
      prospect:{
        id:null,
        civilite:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        type:null,
        numeroOrganisme:null,
        num_secu:null,
        ayantdroit:null,
        situation_familiale:null,
        statut_gda:null,
        nom_naissance:null,
        code_postal_naissance:null,
        commune_naissance:null,
        pays_naissance:null,
        regime_obligatoire:null,
        rang_laser: 1,
        statut: null,
        original_statut: null,
        nationalite: null,
        secteur_activite: null,
        isPPE:null,
        moyen_contact:{
          libelle:null,
          lieu_dit_ou_bp:null,
          complement_adresse:null,
          indicatif_tel: null,
          tel:null,
          code_postal:null,
          ville_id:null,
          email:null
        }
      },
      BooleanValue: [
        { value: 1, text: "Oui" },
        { value: 0, text: "Non" },
      ],
      BooleanValueBenif: [
        { value: true, text: "Oui" },
        { value: false, text: "Non" },
      ],
      villesLocal:[],
      situationFamiliale : Data["SITUATIONFAMILIALE"],
      nationalites: Data["NATIONALITES"],
      statutJuridique : Data["ACTIVITEPROFESSIONNELLE"],
      regimeSocial : Data["REGIMESOCIAL"],
      secteurActivite : Data["SECTEUR_ACTIVITES"],
      errors:[],
      rl:null,
      EnfantsGlobalLocal:[],
      enfantsData:[],
      disableEnfantNumSec: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          numericOnly: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        block: {
          blocks: [9],
          uppercase: true,
        },
        blockSecu: {
          blocks: [15],
          uppercase: true,
        },
        blockSiret: {
          blocks: [14],
          uppercase: true,
        },
        delimiter: {
          delimiter: ' ',
          blocks: [2,2,2,2,2],
          uppercase: true,
          numericOnly:true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true,
        },
        cp: {
          blocks: [5],
        },
      },
      config: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },     
      },
      riaLocal:{
        id:null,
        siret:null,
        raisonSociale:null,
        contratNum:null,
        contratNom:null,
        serviceResiliation:null,
        echeance:null,
        adresse:null,
        codePostal:null,
        ville_id:null,
        villesData:[]
      },
    };
  },
  validations: {
    conjointPossedeNumSS:{ required },
    ConjointLocal: {
      civilite: { required },
      nom: { required },
      prenom: { required },
      date_naissance: { required },
      num_secu: { required },
      nationalite: { required },
      nom_naissance: { required },
      code_postal_naissance: { required },
      commune_naissance: { required },
      pays_naissance: { required },
      numeroOrganisme: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
    },
    enfantsData: {
      $each: {
        civilite: { required },
        nom: { required },
        prenom: { required },
        date_naissance: { required },
        numeroOrganisme: {
          minLength: minLength(9),
          maxLength: maxLength(9),
        }
      },
    },
    prospect: {
      moyen_contact: {
        tel: { required, minLength: minLength(14) },
        indicatif_tel: { required },
        // tel: { required},
        email: { required, email },
        libelle: { required },
        code_postal: { required },
        ville_id: { required },
      },
      civilite: { required },
      nom: { required },
      prenom: { required },
      numeroOrganisme: {
        minLength: minLength(9),
        maxLength: maxLength(9),
        required: requiredIf(function () {
          return this.conjointPossedeNumSS;
        })
      },
      situation_familiale: { required },
      secteur_activite: { required },
      statut: { required },
      num_secu: {
        required: requiredIf(function () {
          return this.conjointPossedeNumSS;
        })
      },
      ayantdroit: {
        required: requiredIf(function () {
          return !this.conjointPossedeNumSS;
        })
      },
      nom_naissance: { required },
      nationalite: { required },
      code_postal_naissance: { required },
      commune_naissance: { required },
      pays_naissance: { required },
    },
  },
  created(){
    this.getPaysList()
  },
  methods: {
    enfnat_regime_obligatoire_mapped(regime){
        let findRegime = this.regimeSocial.find(obj => {
           return obj.value === regime;
        }); 
        return findRegime ? findRegime.name : ''
    },
    hasPermissionAssureur() {
      let result=false
      if(this.rl !== undefined) {
        let pr= _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
        let prPermission = [19, 27,20, 23,26]
        if(pr && prPermission.find(prper => prper == pr.produit.id)){
          this.conjointPossedeNumSS = 1
          if(this.enfantsData.length > 0) {
            this.enfantsData.forEach((enf, index) => {
              if(pr.produit.id == 19 || pr.produit.id == 23) {
                this.disableEnfantNumSec = false
                // if(this.enfantsData[index].regime_obligatoire_enf == 'REGIME_GENERAL' || this.prospect.regime_obligatoire_enf == this.enfantsData[index].regime_obligatoire_enf) {
                //   this.enfantsData[index].hasnumsecu = true
                // } else this.enfantsData[index].hasnumsecu = false
              } else {
                this.disableEnfantNumSec = false
              } 
            })
          }        
          result = true
        }
      }
      return result
    },
    initAyantDroit(val, index) {
      console.log(val, index)
      if(val) {
        this.enfantsData[index].ayantdroit = "sans"
      }
    },
    getPaysList() {
      this.$http.get(`tiers/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
        if (!this.prospect.moyen_contact.indicatif_tel) {
          this.prospect.moyen_contact.indicatif_tel = this.indicatif[0].indicatif
        }
      })
      .catch((e)=>{
        console.log(e);
      })
    },
    mapProspectLocal(tiers){
      this.prospect.id=tiers.id
      this.prospect.civilite=tiers.civilite
      this.prospect.nom=tiers.nom
      this.prospect.prenom=tiers.prenom
      this.prospect.date_naissance=tiers.date_naissance
      this.prospect.numeroOrganisme=tiers.numeroOrganisme
      this.prospect.type=tiers.type
      this.prospect.situation_familiale=tiers.situation_familiale
      this.prospect.statut_gda=tiers.statut_gda 
      this.prospect.num_secu=tiers.num_secu
      this.prospect.ayantdroit=tiers.ayantdroit 
      this.prospect.nom_naissance=tiers.nom_naissance
      this.prospect.code_postal_naissance=tiers.code_postal_naissance
      this.prospect.commune_naissance=tiers.commune_naissance
      this.prospect.pays_naissance=tiers.pays_naissance
      this.prospect.regime_obligatoire=tiers.regime_obligatoire
      this.prospect.rang_laser=tiers.rang_laser
      this.prospect.isPPE=tiers.isPPE
      this.prospect.statut=tiers.statut
      this.prospect.original_statut=tiers.statut
      this.prospect.nationalite=tiers.nationalite
      this.prospect.secteur_activite=tiers.secteur_activite
      this.prospect.moyen_contact.libelle=tiers.moyen_contact.libelle
      this.prospect.moyen_contact.lieu_dit_ou_bp=tiers.moyen_contact.lieu_dit_ou_bp
      this.prospect.moyen_contact.complement_adresse=tiers.moyen_contact.complement_adresse
      this.prospect.moyen_contact.tel=tiers.moyen_contact.tel
      this.prospect.moyen_contact.code_postal=tiers.moyen_contact.code_postal
      this.prospect.moyen_contact.ville_id=tiers.moyen_contact.ville_id
      this.prospect.moyen_contact.email=tiers.moyen_contact.email
      this.prospect.moyen_contact.indicatif_tel = tiers.moyen_contact.indicatif_tel
    },
    updateElementInRow(row, columnIndex, newValue) {
      var rowArray = row.split('');
      rowArray[columnIndex] = newValue;
      return rowArray.join('');
    },
    submit(checkNumSecu = true) {
      this.errors=[]
      this.$v.$reset();
      this.$v.prospect.$touch();
      if (this.objets.objet_sante_seniors.conjoint) {
        this.$v.ConjointLocal.$touch();
      }
      if (this.objets.objet_sante_seniors.enfantCharge > 0 && this.formuleSelectedHasEnfnat) {
        this.$v.enfantsData.$touch();
      }
    
      if (this.$v.prospect.$error || this.$v.ConjointLocal.$error || this.$v.enfantsData.$error) {
          return 0;
      }

 
      this.$emit('updateLoading',true)

      if(this.prospect.original_statut != null && this.prospect.statut !== this.prospect.original_statut) {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Attention, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
          <br><br>
          Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
          <br><br>
          Souhaitez-vous confirmer cette modification ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            html: 'text-left'
          },
          buttonsStyling: false
        })
          .then(confirmed => {
            if (confirmed.value) {
              this.updateDataSouscripteur(checkNumSecu)
              this.prospect.original_statut = this.prospect.statut
            } else {
              this.$emit('updateLoading',false)
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }
          })
          .catch(() => {
            this.$emit('updateLoading',false)
          }).finally(() => {
          })
      } else {
        this.updateDataSouscripteur(checkNumSecu)
      }
    },
    updateDataSouscripteur(checkNumSecu) {
      let data={
        prospect:this.prospect,
        enfant:this.formuleSelectedHasEnfnat ? this.enfantsData : [],
        conjoint:this.ConjointLocal,
        objets:this.objets,
        devis:this.rl.devis.id,
        ria:this.riaLocal,
        risque: { label: this.rl.risque.label },
        checkNumSecu: checkNumSecu
      }
      this.$http.post(`portefeuilles/updateDataSouscripteurStrategy`,data).then((r) => {
          let dataMadelin={
              id:this.devis.id,
              loi_madelin:this.devis.loi_madelin
          }
           this.$http.post(`devis/updateDevisLoiMadelin`,dataMadelin).then((r) => {
              this.updateDevisSouscriptionAvancement()
              let prosp = JSON.parse(JSON.stringify(this.prospect))
              this.$store.commit('setProspect', {
                Tiers: prosp
              })
              // this.$store.commit("setObjetEnfant", {
              //   Enfant: this.enfantsData,
              // });
              // this.$store.commit("setObjetEntreprise", {
              //   Entreprise: this.EntrepriseLocal,
              // });
              this.$store.commit("setStepTarif", {
                  step: {
                    indexStep:2,
                    active:'paiement'
                  },
              });
              this.$emit('updateLoading',false)
          }).catch((err)=>{
              this.$emit('updateLoading',false)
              console.log(err)
          })
      }).catch((err)=>{
          if(is422(err)){
            this.$emit('updateLoading',false)
            this.errors = err.response.data.errors
            console.log(this.errors['prospect.num_secu'])

            if (this.errors['prospect.num_secu']) {
              let message = '<div><h3 style="color:black;">Vérification du numéro sécurité sociale</h3> <p> Le numéro de sécurité sociale semble incorrecte, confirmez-vous celui-ci ?</p></div>'            
              this.showPopupNumSecu(message)
            }
          }
      })
    },
    showPopupNumSecu(message) {
      this.$swal({
        icon: 'warning',
        html: message,
        showCancelButton: true,
        confirmButtonText: 'OUI',
        cancelButtonText: 'NON',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.submit(false)

        }
      });

    },
    backStep(){
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:1,
            active:'choixclient'
          },
      });
    },
    async cpKeyup(){
      let cp=this.prospect.moyen_contact.code_postal
      if(cp.length==5){
          try{
              this.villesLocal = (await this.$http.get(`helpers/${cp}/villes`)).data
              if(this.prospect.moyen_contact.ville_id==null){
                  this.prospect.moyen_contact.ville_id=this.villesLocal[0].id
              }
          }catch(e){
              console.log(e)
          }
      }else{
              this.villesLocal=[]
              this.prospect.moyen_contact.ville_id=null
      }
    },
    async cpKeyupRia() {
      let cpLocal=this.riaLocal.codePostal;
      if(cpLocal && cpLocal.length==5){
          try {
            this.riaLocal.villesData=  (await this.$http.get(`helpers/${cpLocal}/villes`)).data;
          } catch (e) {
            this.riaLocal.ville_id=null
            this.riaLocal.villesData = []
          }
      }else{
            this.riaLocal.ville_id=null
            this.riaLocal.villesData = []
      }
    },
    async updateDevisSouscriptionAvancement(){
      let data={
          id:this.rl.devis.id,
          devis_etape_avancement:'paiement'
      }
      await this.$http.post(`devis/updateDevisSouscriptionState`,data).then((r) => {
          return  true
      }).catch((err)=>{
          console.log(err)
      })
    },
    initpaysProspect(pays){

    },
    async getEnfantsData(){
      await this.$http
        .get(`devis/getProspectDevisEnfants/${this.rl.devis.id}`)
        .then(r => {
          this.mapEnfantForStore(r.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
     mapEnfantForStore(enfants){
      Object.entries(this.EnfantsGlobalLocal.Enfant).forEach(([key, enf]) => {
        if(enf.lienable !== undefined) {
        let enfant={
          id:enf.lienable.id,
          civilite:enf.lienable.civilite,
          nom:enf.lienable.nom,
          prenom:enf.lienable.prenom,
          type:enf.lienable.type,
          date_naissance:enf.lienable.date_naissance,
          num_secu:enf.lienable.num_secu,
          numeroOrganisme:enf.lienable.numeroOrganisme,
          ayantdroit:enf.lienable.ayantdroit,
          regime_obligatoire_enf: enf.lienable.regime_obligatoire,
          hasnumsecu:false,
          statut_gda:enf.lienable.statut_gda,
          poursuit_etude: 0,
          state: true,
          rang_laser: enf.lienable.rang_laser,
          erreur:''
        }

        if(enfant.ayantdroit=='sans' && enfant.num_secu && enfant.numeroOrganisme ){
          enfant.hasnumsecu=true
        }
        
        let checkExist=_.find(enfants, function(e) { return e.personne_physique_id == enfant.id; });
        let isPoursuitEtude =_.find(enfants, function(e) { return e.personne_physique_id == enf.lienable.id && e.poursuit_etude });

        if(checkExist){
          enfant.date_naissance=moment(enfant.date_naissance,'YYYY-MM-DD').format('DD/MM/YYYY'),
            this.enfantsData.push(enfant)
            
        }
        if(isPoursuitEtude) {
          enfant.poursuit_etude = 1
        }
      }
      })
      this.$store.commit("setObjetEnfant", {
        Enfant: this.enfantsData,
      });
    },
    hasErrors(val){
      if(this.errors){
        const found = Object.entries(this.errors).find(([key, value]) => key === val);
        return found ? found[1][0] : false
      }
    },
    isAssureurProduitChoisie(enfant){
        let pr = _.find(this.rl.produitsFiltred, function(p) {
          return p.tarif.choix_client;
        });
        if (pr && pr.produit.assureur_id === 905) {
          // Set value to "signataire" if condition is true
          enfant.ayantdroit = "signataire";
          return true;
        }
        return false;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle
  .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .checked
  ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.title-custom-wizard{
    color: #4d25bc ;
}
</style>


<style>

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle:focus {
    border-radius: 0.357rem 0 0 0.357rem;
}
</style>