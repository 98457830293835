<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 4 : ajout des pièces justificatives</h2>
        <h6>Ajoutez l'ensemble des pièces justificatives listées</h6>
      </b-col>
    </b-row>

    <b-alert
        v-if="apiNotAvailable"
        variant="danger"
        show
        class="mb-0"
      >
        <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            Service de contrôle du gel des avoirs temporairement indisponible.
        </div>
      </b-alert>

    <b-alert v-if="documentErrors.length > 0" variant="danger" show class="mb-2">
      <div class="alert-body">
        <div v-for="(error, key) in documentErrors" :key="key">
          <feather-icon icon="InfoIcon" class="mr-50" /> {{ error }}
        </div>
      </div>
    </b-alert>

    <b-row>
      <!-- +++++ Permis de conduire (Copie recto verso) +++++ -->
      <b-col sm="12" md="6">
        <span class="cursor-pointer" v-b-tooltip.hover.top="'Permis de conduire définitif en cours de validité du conducteur désigné'" v-b-tooltip.hover.v-primary>Permis de conduire (Copie recto verso)</span>
      </b-col>
      <b-col sm="12" md="6">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <span block variant="outline-primary" class="text-capitalize">
              <span v-if="!documentsToSaving.permisConduire.isExisteDocument"> <feather-icon icon="PlusIcon" size="12" />Ajouter un fichier </span>
              <span v-else> <feather-icon icon="EyeIcon" size="12" />visualiser / <feather-icon icon="XIcon" size="12" />détacher </span>
            </span>
          </template>
          <b-dropdown-item v-if="!documentsToSaving.permisConduire.isExisteDocument" @click="openToSelectFile('filePermisConduire')"> <feather-icon icon="UploadIcon" class="mr-50" /> Parcourir cet ordinateur</b-dropdown-item>
          <b-dropdown-item v-if="!documentsToSaving.permisConduire.isExisteDocument" @click="openGEDModal('filePermisConduire')"> <feather-icon icon="LinkIcon" class="mr-50" />Parcourir la GED</b-dropdown-item>
          <b-dropdown-item v-if="documentsToSaving.permisConduire.isExisteDocument" @click="DetacherFile('filePermisConduire')"> <feather-icon icon="XIcon" class="mr-50" />Détacher </b-dropdown-item>
          <b-dropdown-item v-if="documentsToSaving.permisConduire.isExisteDocument" @click="ShowFile(documentsToSaving.permisConduire.id, documentsToSaving.permisConduire.nom_document, 'filePermisConduire')"> 
            <feather-icon icon="EyeIcon" class="mr-50" />Visualiser
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <div class="w-100"></div>
      <!-- +++++ Copie de la carte grise +++++ -->
      <b-col sm="12" md="6">
        <span class="cursor-pointer" v-b-tooltip.hover.top="'Carte grise définitive et, en cas de véhicule neuf, carte grise provisoire'" v-b-tooltip.hover.v-primary>Carte grise (Copie recto verso)</span>
      </b-col>
      <b-col sm="12" md="6">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <span block variant="outline-primary" class="text-capitalize">
              <span v-if="!documentsToSaving.carteGrise.isExisteDocument"> <feather-icon icon="PlusIcon" size="12" />Ajouter un fichier </span>
              <span v-else> <feather-icon icon="EyeIcon" size="12" />visualiser / <feather-icon icon="XIcon" size="12" />détacher </span>
            </span>
          </template>
          <b-dropdown-item v-if="!documentsToSaving.carteGrise.isExisteDocument" @click="openToSelectFile('fileCarteGrise')"> <feather-icon icon="UploadIcon" class="mr-50" /> Parcourir cet ordinateur</b-dropdown-item>
          <b-dropdown-item v-if="!documentsToSaving.carteGrise.isExisteDocument" @click="openGEDModal('fileCarteGrise')"> <feather-icon icon="LinkIcon" class="mr-50" />Parcourir la GED</b-dropdown-item>
          <b-dropdown-item v-if="documentsToSaving.carteGrise.isExisteDocument" @click="DetacherFile('fileCarteGrise')"> <feather-icon icon="XIcon" class="mr-50" />Détacher </b-dropdown-item>
          <b-dropdown-item v-if="documentsToSaving.carteGrise.isExisteDocument" @click="ShowFile(documentsToSaving.carteGrise.id, documentsToSaving.carteGrise.nom_document, 'fileCarteGrise')"> 
            <feather-icon icon="EyeIcon" class="mr-50" />Visualiser
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <!-- +++++ modals +++++ -->
    <!-- +++++ modal display document or download it +++++ -->
    <b-modal id="modal-display-document" ref="modal-display-document" :size="documentFile.extensionDocument === 'PDF' ? 'lg' : 'sm'" :title="documentFile.name" cancel-variant="outline-secondary" cancel-title="Fermer" ok-title="Télécharger" @ok="downloadDocument(documentFile.base64, documentFile.nameToDownload)" @hidden="resetModalDisplayDocument">
      <form ref="form" :style="{ height: documentFile.extensionDocument === 'PDF' ? '80vh' : 'auto', overflow: 'hidden' }">
        <b-img v-if="documentFile.extensionDocument === 'IMAGE'" :alt="documentFile.name" :src="documentFile.base64" fluid />
        <iframe v-else-if="documentFile.extensionDocument === 'PDF'" :src="documentFile.base64" height="100%" width="100%" :style="hideToolbar()" />
      </form>
    </b-modal>
    
    <!-- +++++ modal affect document from GED +++++ -->
    <b-modal id="modal-ged-document" body-class="p-0" ref="modal-ged-document" hide-footer centered size="lg" title="Documents" scrollable>
      <b-card-body style="height: 50vh;">
        <b-row class="mb-2">
          <b-col cols="10"><h6>Nom De Document</h6></b-col>
          <b-col cols="2" class="text-right"><h6>Actions</h6></b-col>
        </b-row>
        <b-row class="mb-2" v-for="(doc, index) in documentsLocalFiltered" :key="index">
          <b-col cols="10" class="text-truncate"><span>{{ doc.document.nom_custom }}</span></b-col>
          <b-col cols="2" class="text-right">
            <feather-icon @click="AttachDocument(doc.document)" icon="CheckCircleIcon" size="24" class="mr-50 custom-icon-box cursor-pointer" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-modal>
    
    <!-- +++++ form file hidden +++++ -->
    <b-form-file v-if="!documentsToSaving.carteGrise.id" type="file" id="fileCarteGrise" class="d-none" v-model="documentsToSaving.carteGrise.file" @change="handleFile('fileCarteGrise', $event)" />
    <b-form-file v-if="!documentsToSaving.permisConduire.id" type="file" id="filePermisConduire" class="d-none" v-model="documentsToSaving.permisConduire.file" @change="handleFile('filePermisConduire', $event)" />
    
    <!-- +++++ footer buttons +++++ -->
    <b-row>
      <b-col lg="12" class="mt-4">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
        <b-button variant="primary" class="float-right" size="lg" @click="submit"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import documentService from '@/shared/services/documentService'
import { BOverlay, BFormFile, BImg, BSidebar, BAvatar, VBTooltip, VBToggle, BCardText, BCard, BCardHeader, BCardTitle, BCardBody, BFormCheckbox, BForm, BInputGroup, BFormInvalidFeedback, BInputGroupPrepend, BPagination, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BRow, BCol, BButton, BDropdown, BDropdownItem, BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    // VBT
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BFormFile,
    BImg,
    BCardBody,
    BAlert
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    paiement: { 
      type: Object,
      default: [],
      required: true
    },
    dataPayeur: {
      type: Object,
      default: [],
      required: true
    }
  },
  data() {
    return {
      payeur : {
        id: null,
        type: null,
        s_is_payeur: 1,
        typePayeur: null,
        civilite: null,
        nom: null,
        prenom: null,
        libelle: null,
        lieu_dit_ou_bp:null,
        complement_adresse: null,
        code_postal: null,
        ville_id: null,
        pays: null
      },
      apiNotAvailable : false,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      devis: null,
      rl: null,
      documentsLocal: [],
      documentErrors: [],
      documentsDevis: [],
      documentsLocalFiltered: [],
      showLoading: false,
      documentFile: {
        base64: '',
        name: '',
        nameToDownload: '',
        extensionDocument: ''
      },
      documentsToSaving: {
        carteGrise: {
          id: '',
          date_creation: '',
          note: '',
          file: null,
          nom_document: '',
          type: '',
          typeNom: '',
          nature: '',
          isExisteDocument: false,
          idClient: '',
          typeClient: ''
        },
        permisConduire: {
          id: '',
          date_creation: '',
          file: null,
          note: '',
          nom_document: '',
          type: '',
          typeNom: '',
          nature: '',
          isExisteDocument: false,
          idClient: '',
          typeClient: ''
        },
      },
    }
  },
  watch: {
    getItemsRisque: {
      immediate: true,
      handler(val) {
        this.rl = val.ItemsRisque[0]
        this.devis = val.ItemsRisque[0]?.devis
        let firstObjetMoto = val.ItemsRisque[0]?.objets?.objet_moto[0]

        // init permis conduire client
        this.documentsToSaving.permisConduire.idClient = firstObjetMoto.conducteur.lienable_id
        if (firstObjetMoto.conducteur.lienable_type.includes('PersonnePhysique')) {
          this.documentsToSaving.permisConduire.typeClient = 'PERSONNE_PHYSIQUE'
        } else if (firstObjetMoto.conducteur.lienable_type.includes('PersonneMorale')) {
          this.documentsToSaving.permisConduire.typeClient = 'PERSONNE_MORALE'
        }

        // init carte grise client
        this.documentsToSaving.carteGrise.idClient = firstObjetMoto.objetmotoable_id
        if (firstObjetMoto.objetmotoable_type.includes('PersonnePhysique')) {
          this.documentsToSaving.carteGrise.typeClient = 'PERSONNE_PHYSIQUE'
        } else if (firstObjetMoto.objetmotoable_type.includes('PersonneMorale')) {
          this.documentsToSaving.carteGrise.typeClient = 'PERSONNE_MORALE'
        }

        // Get document
        this.getDocumentByProspect(
          this.documentsToSaving.carteGrise.idClient,
          this.documentsToSaving.carteGrise.typeClient,
          this.documentsToSaving.permisConduire.idClient,
          this.documentsToSaving.permisConduire.typeClient
        )
      }
    },
    dataPayeur: {
     immediate: true,
      handler(val, nld) {
        this.payeur = val
      }
    },
  },
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['Tiers']),
    ...mapGetters(['Enfant']),
    ...mapGetters(['Conjoint']),
    ...mapGetters(['Entreprise']),
    ...mapGetters(['getEtude'])
  },
  mounted() {
    this.fetchListNatureDocument()
  },
  methods: {
    // Global functions
    backStep() {
      this.documentErrors = []
      this.$store.commit('setStepTarif', { step: { indexStep: 4, active: 'paiement' } })
    },
    // open file from PC
    openToSelectFile(FileName) {
        document.getElementById(FileName).click()
    },
    // change data when select file from pc
    handleFile(FileName, event) {
      if(FileName === 'fileCarteGrise') {
        this.documentsToSaving.carteGrise.id = ''
        this.documentsToSaving.carteGrise.isExisteDocument = event.target.files.length > 0
        this.documentsToSaving.carteGrise.nom_document = this.formatText(event.target.files[0].name, 'initial')
      } else if (FileName === 'filePermisConduire') {
        this.documentsToSaving.permisConduire.id = ''
        this.documentsToSaving.permisConduire.isExisteDocument = event.target.files.length > 0
        this.documentsToSaving.permisConduire.nom_document = this.formatText(event.target.files[0].name, 'initial')
      }
    },
    // 
    resetModalDisplayDocument() {
      this.clearDocumentDisplay()
    },

    // Document functions
    // ---- downloadDocument (parameters : base64 and name of document)
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    
    // ---- download file uploaded from pc (parameters : file)
    downloadFile(file) {
      const url = URL.createObjectURL(file)
      const a = document.createElement('a')
      a.href = url
      a.download = file.name
      document.body.appendChild(a)
      a.click()
      a.remove()
      URL.revokeObjectURL(url)
    },
    // ---- get base64 of document (parameters : id and name document)
    getBase64OfDocument(id, name) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            // check if extension document id IMAGE/PDF and is download directly or not
            if (!res.data.data.isDownload && (res.data.data.extensionDocument === 'IMAGE' || res.data.data.extensionDocument === 'PDF')) {
              this.documentFile.base64 = res.data.data.base64
              this.documentFile.name = name
              this.documentFile.nameToDownload = name
              this.documentFile.extensionDocument = res.data.data.extensionDocument
              // open modal
              this.$refs['modal-display-document'].show()
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'danger', 'BellIcon', 3000)
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'danger', 'BellIcon', 3000)
          console.error(err)
        })
    },
    // get the file extension (parameter: file)
    getFileExtension(file) {
      return file.name.split('.').pop().toLowerCase();
    },
    // ---- checks the file's extension and return type
    checkExtensionFile (file)  {
      if (this.getFileExtension(file) === 'pdf'){
        return 'PDF'
      } else if (this.getFileExtension(file) === 'jpg' || this.getFileExtension(file) === 'jpeg' || this.getFileExtension(file) === 'png' || this.getFileExtension(file) === 'gif') {
        return 'IMAGE'
      } else {
        return 'UNKNOWN'
      }
    },
    // ---- show file (params: id document, name document, typeFileName (filePermisConduire/fileCarteGrise))
    ShowFile(id, name, typeFileName) {
      this.clearDocumentDisplay()
      if(id){
        this.getBase64OfDocument(id, name)
      } else {
        if (typeFileName == 'filePermisConduire') {
          var typeDocumentExtension = this.checkExtensionFile(this.documentsToSaving.permisConduire.file)
          if (typeDocumentExtension == 'UNKNOWN') {
            this.downloadFile(this.documentsToSaving.permisConduire.file)
          } else {
            this.documentFile.base64 = URL.createObjectURL(this.documentsToSaving.permisConduire.file)
            this.documentFile.name = this.documentsToSaving.permisConduire.file.name
            this.documentFile.nameToDownload = this.documentsToSaving.permisConduire.file.name
            this.documentFile.extensionDocument = this.checkExtensionFile(this.documentsToSaving.permisConduire.file)
            // open modal
            this.$refs['modal-display-document'].show()
          }
        } else if(typeFileName == 'fileCarteGrise') {
          var typeDocumentExtension = this.checkExtensionFile(this.documentsToSaving.carteGrise.file)
          if (typeDocumentExtension == 'UNKNOWN') {
            this.downloadFile(this.documentsToSaving.carteGrise.file)
          } else {
            this.documentFile.base64 = URL.createObjectURL(this.documentsToSaving.carteGrise.file)
            this.documentFile.name = this.documentsToSaving.carteGrise.file.name
            this.documentFile.nameToDownload = this.documentsToSaving.carteGrise.file.name
            this.documentFile.extensionDocument = this.checkExtensionFile(this.documentsToSaving.carteGrise.file)
            // open modal
            this.$refs['modal-display-document'].show()
          }
        }
      }
    },
    // ---- init documentFile
    clearDocumentDisplay() {
      this.documentFile.base64 = ''
      this.documentFile.name = ''
      this.documentFile.nameToDownload = ''
      this.documentFile.extensionDocument = ''
    },
    // ---- fetch list natures document with types
    fetchListNatureDocument() {
      const fetchDocumentNatureWithTypes = documentService()

      fetchDocumentNatureWithTypes.then(res => {
          // find client nature with types
          let findClientNature = res.data.find(nature => nature.id == 3)
          let findCarteGriseType = findClientNature.documentype.find(type => type.id == 80)
          let findPermisConduireType = findClientNature.documentype.find(type => type.id == 4)

          // init permis de conduire et cate grise
          this.documentsToSaving.permisConduire.nature = 3
          this.documentsToSaving.permisConduire.type = findPermisConduireType?.id
          this.documentsToSaving.permisConduire.typeNom = findPermisConduireType?.type

          this.documentsToSaving.carteGrise.nature = 3
          this.documentsToSaving.carteGrise.type = findCarteGriseType?.id
          this.documentsToSaving.carteGrise.typeNom = findCarteGriseType?.type
        }).catch(errors => {
          console.error(errors)
        })
    },
    // ---- get document by id client and type client and type document [4 and 80] => [PermisConduire, CarteGrise]
    getDocumentByProspect(id, type, idClientConducteur, typeClientConducteur) {
      this.showLoading = true
      let dataToSent = {
        idClient: id,
        typeClient: type,
        idClientConducteur: idClientConducteur,
        typeClientConducteur: typeClientConducteur,
        typeDocumentId: [4, 80]
      }
      this.$http
        .post('document/getDocumentByProspectAndTypeId/', dataToSent)
        .then(r => {
          this.documentsLocal = r.data
          this.showLoading = false
        })
        .catch(e => {
          console.log(e)
          this.showLoading = false
        })
    },
    // ---- detach file from list typeFileName (filePermisConduire/fileCarteGrise)
    DetacherFile (typeFileName) {
      if (typeFileName === 'filePermisConduire') {
        this.documentsToSaving.permisConduire.id = ''
        this.documentsToSaving.permisConduire.file = null
        this.documentsToSaving.permisConduire.date_creation = ''
        this.documentsToSaving.permisConduire.nom_document = ''
        this.documentsToSaving.permisConduire.isExisteDocument = false
      } else if (typeFileName === 'fileCarteGrise') {
        this.documentsToSaving.carteGrise.id = ''
        this.documentsToSaving.carteGrise.file = null
        this.documentsToSaving.carteGrise.date_creation = ''
        this.documentsToSaving.carteGrise.nom_document = ''
        this.documentsToSaving.carteGrise.isExisteDocument = false
      }
    },
    // ---- attach file from GED (parameter document)
    AttachDocument (doc) {
      if(doc.type_id == 80) {
          this.documentsToSaving.carteGrise.id = doc.id
          this.documentsToSaving.carteGrise.file = ''
          this.documentsToSaving.carteGrise.nom_document = doc.nom_custom
          this.documentsToSaving.carteGrise.type = doc.type_id
          this.documentsToSaving.carteGrise.typeNom = doc.document_type.type
          this.documentsToSaving.carteGrise.nature = doc.document_type.document_nature_id
          this.documentsToSaving.carteGrise.isExisteDocument = true
          this.$refs['modal-ged-document'].hide()
        } else if(doc.type_id == 4) {
          this.documentsToSaving.permisConduire.id = doc.id
          this.documentsToSaving.permisConduire.file = ''
          this.documentsToSaving.permisConduire.nom_document = doc.nom_custom
          this.documentsToSaving.permisConduire.type = doc.type_id
          this.documentsToSaving.permisConduire.typeNom = doc.document_type.type
          this.documentsToSaving.permisConduire.nature = doc.document_type.document_nature_id
          this.documentsToSaving.permisConduire.isExisteDocument = true
          this.$refs['modal-ged-document'].hide()
        }
    },
    // ---- open modal ged and init table
    openGEDModal(typeFileName){
      this.documentsLocalFiltered = []
      let firstObjetMoto = this.rl.objets?.objet_moto[0]
      // 4	Permis de conduire
      let idClientPermisConduire = this.documentsToSaving.permisConduire.idClient
      let typeClientPermisConduire = this.documentsToSaving.permisConduire.typeClient

      // 80	Carte Grise
      let idClientcarteGrise = this.documentsToSaving.carteGrise.idClient
      let typeClientcarteGrise = this.documentsToSaving.carteGrise.typeClient

      if (typeFileName === 'filePermisConduire') {
        this.documentsLocalFiltered = this.documentsLocal.filter(item =>
          item.document.type_id == 4 &&
          item.clientable_id == idClientPermisConduire &&
          item.clientable_type.toLowerCase().replace('_', '').includes(typeClientPermisConduire.toLowerCase().replace('_', ''))
        )
      }
      else if (typeFileName === 'fileCarteGrise') {
        this.documentsLocalFiltered = this.documentsLocal.filter(item =>
          item.document.type_id == 80 &&
          item.clientable_id == idClientcarteGrise &&
          item.clientable_type.toLowerCase().replace('_', '').includes(typeClientcarteGrise.toLowerCase().replace('_', ''))
        )
      }

      if (this.documentsLocalFiltered.length > 0) { this.$refs['modal-ged-document'].show() }
      else { this.messageToast("Vous n'avez aucun document de ce client sur la GED", 'Avertissement', 'warning', 'BellIcon', 3000) }

    },
    showAlertGDA(listclient) {
      let str = "Attention, le contrôle du gel des avoirs sera effectué juste après validation de cet écran. Assurez-vous que toutes les informations (nom, prénom et date de naissance) de votre client/prospect et des personnes rattachées soient correctes.</br></br>"
      str = str + "Si un ou plusieurs sont suspectés, vous ne pourrez pas finaliser la souscription sans avoir effectué une vérification préalable de leur identité.</br></br>"
      str = str + "Que souhaitez-vous faire ?"

      this.$swal({
        html: '<div style="text-align:justify;">' + str + '</div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Je souhaite procéder aux contrôles de mon client/prospect et des personnes rattachées',
        cancelButtonText: '<div style="color:red;">Je souhaite vérifier à nouveau les informations de mon client/prospect et des personnes rattachées. </div>',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mt-1',
        },
        cancelButtonColor: '#ffffff'

      }).then((result) => {
        if (result.value) {
          this.$http.post('/gda/checkClientGda', { prospect: listclient })
            .then(res => {
              if (res.data) {
                let result = res.data
                let id = result.id
                if (!result.pass)
                  this.$router.push({
                    name: 'gda-list',
                    params: { redirected: true }
                  })
                else {
                  this.submitDocument()
                  this.submitSouscripteur()
                }
              }
            }).catch(err => {
              this.apiNotAvailable = true
              this.functionShowLoading(false)
            })
        }
      })     
    },
    // Submit functions
    async submit() {
      let listProspect = []

      if (this.Tiers.statut_gda == "GRIS" || this.Tiers.statut_gda == "BLANC")
        listProspect.push(this.Tiers)
      if (this.getItemsRisque.ItemsRisque[0].objets.objet_moto[1].lien != null) {
        if (this.getItemsRisque.ItemsRisque[0].objets.objet_moto[1].lien == "ENFANT") {
          this.Enfant.Enfant.forEach((value) => {
            if (value.statut_gda == "GRIS" || value.statut_gda == "BLANC")
              listProspect.push(value)
          })
        }
        else if (this.getItemsRisque.ItemsRisque[0].objets.objet_moto[1].lien == "CONJOINT" && (this.Conjoint.statut_gda == "GRIS" || this.Conjoint.statut_gda == "BLANC"))
          listProspect.push(this.Conjoint)
      }

      console.log(listProspect)

      this.documentErrors = []
      this.functionShowLoading(true)
      if(this.checkGda())
        this.detectedAlertMessage()
      else {
        if(listProspect.length > 0 && (this.documentsToSaving.carteGrise.isExisteDocument || this.documentsToSaving.permisConduire.isExisteDocument))
        {
          this.showAlertGDA(listProspect)
          this.functionShowLoading(false)
        }
        else {
          if (this.documentsToSaving.carteGrise.isExisteDocument || this.documentsToSaving.permisConduire.isExisteDocument) {
            await this.submitDocument()
          }
          await this.submitSouscripteur()
          await this.functionShowLoading(false)
          // if (isValidateDocument) {
          // else //hna sift signature dyalk
          // this.$store.commit('setStepTarif', { step: { indexStep: 5, active: 'signature' }})
          // }
        }
      }
      await this.functionShowLoading(false)
    },
    submitDocument() {

        // if (!this.documentsToSaving.carteGrise.isExisteDocument) { this.documentErrors.push("Le copie de la carte grise est obligatoire, veuillez l'ajouter!") }
        // if (!this.documentsToSaving.permisConduire.isExisteDocument) { this.documentErrors.push("Le permis de conduire est obligatoire, veuillez l'ajouter!") }

        if (this.documentErrors.length <= 0) {
          this.showLoading = true
            let config = { headers: { 'Content-Type': 'multipart/form-data' } }
            let formData = new FormData()

            // append global data 
            formData.append('typeClient', this.documentsToSaving.carteGrise.typeClient)
            formData.append('idClient', this.documentsToSaving.carteGrise.idClient)

            formData.append('devisId', this.devis.id)
            formData.append('etudeId', this.getEtude.etudeID)

            formData.append('userId', this.currentUser.id)
            formData.append('courtierId', this.currentUser.courtier_user[0].courtier_id)

            // append data for carteGrise document
            if (this.documentsToSaving.carteGrise.isExisteDocument){
              formData.append('files[0][id]', this.documentsToSaving.carteGrise.id)
              formData.append('files[0][file]', this.documentsToSaving.carteGrise.file)
              formData.append('files[0][type]', this.documentsToSaving.carteGrise.type)
              formData.append('files[0][nature]', this.documentsToSaving.carteGrise.nature)
              formData.append('files[0][idClient]', this.documentsToSaving.carteGrise.idClient)
              formData.append('files[0][typeClient]', this.documentsToSaving.carteGrise.typeClient)
              formData.append('files[0][nom_document]', this.documentsToSaving.carteGrise.nom_document)
              formData.append('files[0][date_creation]', this.documentsToSaving.carteGrise.date_creation)
            }

            // append data for permisConduire document
            if (this.documentsToSaving.permisConduire.isExisteDocument){
              formData.append('files[1][id]', this.documentsToSaving.permisConduire.id)
              formData.append('files[1][file]', this.documentsToSaving.permisConduire.file)
              formData.append('files[1][type]', this.documentsToSaving.permisConduire.type)
              formData.append('files[1][nature]', this.documentsToSaving.permisConduire.nature)
              formData.append('files[1][idClient]', this.documentsToSaving.permisConduire.idClient)
              formData.append('files[1][typeClient]', this.documentsToSaving.permisConduire.typeClient)
              formData.append('files[1][nom_document]', this.documentsToSaving.permisConduire.nom_document)
              formData.append('files[1][date_creation]', this.documentsToSaving.permisConduire.date_creation)

            }

            if (this.documentsToSaving.permisConduire.isExisteDocument || this.documentsToSaving.carteGrise.isExisteDocument) {
            this.$http
                .post(`document/createOrAttachDocument`, formData, config)
                .then(async r => {
                  if (r.data.success) {
                    this.documentsDevis = r.data.data
                    return true
                  } else {
                    await this.functionShowLoading(false)
                    return false
                  }
                })
                .catch(err => {
                  // this.messageToast(err.response.data.errors[0], 'Erreur', 'danger', 'BellIcon', 3000)
                  // this.functionShowLoading(false)
                  return false
                })
              }

        }
    },


    functionShowLoading(isShow) {
      this.showLoading = isShow
    },
    async submitSouscripteur(){
      this.getItemsRisque.ItemsRisque[0].errors=[]
      let produitPreconise = this.getItemsRisque.ItemsRisque[0].produitsFiltred.find( pf => pf.tarif.choix_client);
      let data= {
        etude:this.getEtude.etudeID,
        prospect:this.Tiers,
        enfant:this.Enfant,
        conjoint:this.Conjoint,
        devis:this.getItemsRisque.ItemsRisque[0].devis,
        objets:this.getItemsRisque.ItemsRisque[0].objets,
        produit: produitPreconise,
        risque:this.getItemsRisque.ItemsRisque[0].risque,
        paiement:this.paiement,
        payeur: this.payeur,
        pj:this.documentsDevis,
      }
      return this.validTosouscrire(data);
    },
    async validTosouscrire(data) {
      this.errorsWsSouscription = []
      await this.$http.post('portefeuilles/creerDevisFMA', data)
        .then(async res => {
          if (res.data.success) {
            if (res.data.data.contentDPSignees && res.data.data.contentMandatSepa && res.data.data.contentProposition) {
              this.getItemsRisque.ItemsRisque[0].devis.document = []
              await this.getItemsRisque.ItemsRisque[0].devis.document.push(res.data.data.contentDPSignees)
              await this.getItemsRisque.ItemsRisque[0].devis.document.push(res.data.data.contentMandatSepa)
              if (res.data.data.contentMandatHamon)
              {
                await this.getItemsRisque.ItemsRisque[0].devis.document.push(res.data.data.contentMandatHamon)
              }
              await this.getItemsRisque.ItemsRisque[0].devis.document.push(res.data.data.contentProposition)
              await this.updateDevisSouscriptionAvancement()

              await this.$store.commit("setStepTarif", { step: { indexStep: 5, active: 'signature' } })
              
              await this.functionShowLoading(false)
              return true
            }
          }

        }).catch(err => {
          this.documentErrors.push(err.response.data.errors[0])
          return false
        })
    },
    async updateDevisSouscriptionAvancement(){
      let data = { id: this.rl.devis.id, devis_etape_avancement: 'signature' }
      await this.$http.post(`devis/updateDevisSouscriptionState`, data).then(() => {
        return true
      }).catch(err => {
        console.error(err)
      })
    },
    checkGda(){
      if(this.Tiers.statut_gda=="ORANGE" ||this.Tiers.statut_gda=="ROUGE" )
        return true;
      if(this.getItemsRisque.ItemsRisque[0].objets.objet_moto[1].lien!=null ){
        if(this.getItemsRisque.ItemsRisque[0].objets.objet_moto[1].lien=="ENFANT"){
          this.Enfant.Enfant.forEach((value)=>{
            if(value.statut_gda=="ORANGE" || value.statut_gda=="ROUGE" )
              return true;
          })
        }
        else if(this.getItemsRisque.ItemsRisque[0].objets.objet_moto[1].lien=="CONJOINT" && (this.Conjoint.statut_gda=="ORANGE" || this.Conjoint.statut_gda=="ROUGE"))
          return true;
      }
      return false;
    },
    detectedAlertMessage(){
      let chardd="Attention, un ou plusieurs bénéficiaires ont été suspectés d'être présents sur le registre du gel des avoirs.</br></br>"
      chardd=chardd+"Pour pouvoir finaliser la souscription, vous devez confirmer les identités de chacune des personnes suspectées.</br></br>"
      this.$swal({
        html:'<div style="text-align:justify;">'+chardd+'</div>',
        icon: 'warning',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
          },
      }).then(()=>{  
          this.$router.push({
            name: 'gda-list',
            params: { redirected: true }
          })
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

