export function mrhRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        switch(pr.produit.produit_ws_nom){
            case 'MRH_GENERALI':
                produitsResult.push(mrhEcaProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function mrhEcaProduit(produit,garantie,exigences){

    let total=0
    let cpt=0
    let tmptauxGarantie=0

    let ESSENTIELLE={
        degatEaux:{
            value:1,
            choose:searchoption('Dégâts des eaux'),
        },
        attentatActes:{
            value:1,
            choose:searchoption('Attentats et actes de terrorisme')
        },
        catastropheNaturelle:{
            value:1,
            choose:searchoption('Catastrophes naturelles')
        },
        catastropheTech:{
            value:1,
            choose:searchoption('Catastrophes technologiques')
        },
        grNiveau:'Faible'
    }
    let CONFORT={
        degatEaux:{
            value:2,
            choose:searchoption('Dégâts des eaux'),
        },
        attentatActes:{
            value:2,
            choose:searchoption('Attentats et actes de terrorisme')
        },
        catastropheNaturelle:{
            value:2,
            choose:searchoption('Catastrophes naturelles')
        },
        catastropheTech:{
            value:2,
            choose:searchoption('Catastrophes technologiques')
        },
        grNiveau:'Moyen'
    }
    let COMPLETE={
        degatEaux:{
            value:2,
            choose:searchoption('Dégâts des eaux'),
        },
        attentatActes:{
            value:3,
            choose:searchoption('Attentats et actes de terrorisme')
        },
        catastropheNaturelle:{
            value:2,
            choose:searchoption('Catastrophes naturelles')
        },
        catastropheTech:{
            value:3,
            choose:searchoption('Catastrophes technologiques')
        },
        grNiveau:'Moyen'
    }
    let OPTIMUM={
        degatEaux:{
            value:3,
            choose:searchoption('Dégâts des eaux'),
        },
        attentatActes:{
            value:3,
            choose:searchoption('Attentats et actes de terrorisme')
        },
        catastropheNaturelle:{
            value:3,
            choose:searchoption('Catastrophes naturelles')
        },
        catastropheTech:{
            value:3,
            choose:searchoption('Catastrophes technologiques')
        },
        grNiveau:'Fort'
    }

    function mapAndGetResult(exigence){
        let tmpEx= exigence.exigenceniveaux.find( egn => egn.id === exigence.exigenceNiveauID[0]);
        if(tmpEx && tmpEx.exigence_niveau_libel){
            switch(tmpEx.exigence_niveau_libel){
                case 'NON':
                    return -1;
                case 'Min':
                    return 1;
                case 'Moyen':
                    return 2
                case 'Fort':
                    return 3;
                case 'Max':
                    return 4;
                default:
                    return 1;
            }
        }else{
            return 1
        }
    }
    function searchoption(option){
        let tmpOption= exigences.find( pg => pg.label === option);
        return tmpOption ? mapAndGetResult(tmpOption) : 1
    }
    function calcultauxtarif(pgniveau){
        Object.entries(pgniveau).forEach(([key, o]) => {
            if(key!=='grNiveau'){
                total+=o.value/o.choose;
                cpt++
            }
        });
        tmptauxGarantie=pgniveau.grNiveau==garantie ? 1 : 0
        let tmpTotal=(total/cpt+tmptauxGarantie)/2
        if(tmpTotal>1){
            produit.tarif.taux=(1-(tmpTotal-1))
            produit.tarif.tauxtype=1
        }
        else{
            produit.tarif.taux=tmpTotal
            produit.tarif.tauxtype=-1
        }
    }
    
    switch(produit.tarif.formule){
        case 'ESSENTIELLE':
            calcultauxtarif(ESSENTIELLE)
        break;
        case 'CONFORT':
            calcultauxtarif(CONFORT)
        break;
        case 'COMPLETE':
            calcultauxtarif(COMPLETE)
        break;
        case 'OPTIMUM':
            calcultauxtarif(OPTIMUM)
        break;
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}