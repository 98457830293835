<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 3 : désignation des modes de paiement</h2>
        <h6>Choisissez le mode de paiement des cotisations et des prestations</h6>
      </b-col>
    </b-row>
    <validation-observer ref="PaiementComposeRules">
      <h4 class="title-custom-wizard"><u>Informations de paiements</u></h4>

      <b-row>
        <b-col md="6">
          <b-form-group label="Mode de paiement" label-for="mode_paiement">
            <validation-provider #default="{ errors }" name="mode de paiement" rules="required">
              <b-form-select id="mode_paiement" v-model="devis.mode_paiement" :state="errors.length > 0 ? false : null" readonly>
                <b-form-select-option :value="null" disabled>Mode de paiement</b-form-select-option>
                <b-form-select-option value="CHEQUE" v-if="devis.fractionnement != 'MOIS'">Chèque </b-form-select-option>
                <b-form-select-option value="PRELEVEMENT">Prélèvement</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Fractionnement" label-for="fractionnement">
            <validation-provider #default="{ errors }" name="fractionnement" rules="required">
              <b-form-select v-model="devis.fractionnement" :state="errors.length > 0 ? false : null" readonly disabled>
                <b-form-select-option :value="null" disabled>Périodicité</b-form-select-option>
                <b-form-select-option value="MOIS">Mensuel</b-form-select-option>
                <b-form-select-option value="ANNEE">Annuel</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Titulaire du compte" label-for="paiementTitulaireNom">
            <validation-provider #default="{ errors }" name="titulaire du compte" rules="required">
              <b-form-input id="paiementTitulaireNom" autocomplete="nope" v-model="paiementLocal.paiementTitulaireNom" trim placeholder="Titulaire du compte"  :state="errors.length > 0 ? false : null"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group label="Jour du prélèvement" label-for="jour_prelev">
            <b-form-select v-model="devis.jour_de_prelevement">
              <b-form-select-option :value="null" disabled>Jour du prélèvement</b-form-select-option>
              <b-form-select-option value="5">5</b-form-select-option>
              <b-form-select-option value="10">10</b-form-select-option>
              <b-form-select-option value="15">15</b-form-select-option>
              <b-form-select-option value="20">20</b-form-select-option>
              <b-form-select-option value="DERNIERJOUR">Le dernier jour du mois</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!hasErrors('devis.jour_de_prelevement')">
              {{ hasErrors('devis.jour_de_prelevement') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col> -->
      </b-row>

      <b-row v-if="devis.mode_paiement && devis.mode_paiement != 'CHEQUE'">
        <b-col md="6">
          <b-form-group label="IBAN" label-for="iban">
            <validation-provider #default="{ errors }" name="IBAN" rules="required|ibanRules">
              <b-form-input id="iban" v-model="paiementLocal.iban" autocomplete="nope" trim placeholder="Iban" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>          
        </b-col>
        <b-col md="6">
          <b-form-group label="BIC" label-for="bic">
            <validation-provider #default="{ errors }" name="BIC" rules="required|bicRules">
              <b-form-input id="bic" v-model="paiementLocal.bic" autocomplete="nope" trim placeholder="Bic" :state="errors.length > 0 ? false : null"/>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="pb-2 pt-1">
          <b-form-group class="mb-0">
            <b-form-checkbox id="is_payeur_option" v-model="payeur.s_is_payeur"
              value="1">
              Le souscripteur est le payeur
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!payeur.s_is_payeur">
        <b-col md="12">
          <h4 class="title-custom-wizard mb-2"><u>Information du mandat SEPA</u></h4>
        </b-col>
        <b-col md="12" v-if="conducteur.id">
          <b-form-group>
            <label class="mr-1 float-left mt-1">Qui est le payeur ?</label>
            <validation-provider #default="{ errors }" name="payeur" rules="required"
              vid="payeur" class="d-inline-block min-w-150">
              <b-form-select v-model="payeur.typePayeur"  :options="TypePayeurOptions"
                :state="errors.length > 0 ? false : null" @change="initPayeurLocal" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12" v-if="payeur.typePayeur || !conducteur">
          <b-row>
            <b-col md="3">
              <b-form-group>
                <label for="civilité">Civilité *</label>
                <validation-provider #default="{ errors }" name="civilité payeur" rules="required">
                  <b-form-select :options="civilite" v-model="payeur.civilite" id="payeur_civilite" :state="errors.length > 0 ? false : null" :disabled="payeur.typePayeur == 'CONDUCTEUR'" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="nom">Nom *</label>
                <validation-provider #default="{ errors }" name="nom payeur" rules="required">
                <b-form-input
                  id="nom"
                  :state="errors.length > 0 ? false : null"
                  v-model="payeur.nom"
                  autocomplete="nope"
                  type="text"
                  placeholder="Nom"
                  class="text-capitalize"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="prenom">Prénom *</label>
                <validation-provider #default="{ errors }" name="prénom payeur" rules="required">
                <b-form-input
                  id="prenom"
                  :state="errors.length > 0 ? false : null"
                  v-model="payeur.prenom"
                  autocomplete="nope"
                  type="text"
                  placeholder="Prénom"
                  class="text-capitalize"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="libelle">N° et libellé de la voie *</label>
              <validation-provider #default="{ errors }" name="N° et libellé de la voie payeur" rules="required">
              <b-form-input id="libelle" autocomplete="nope" trim v-model="payeur.libelle"
                placeholder="N° et libellé de la voie" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="lieu_dit_ou_bp">Lieu-dit</label>
              <b-form-input
                id="lieu_dit_ou_bp"
                autocomplete="nope"
                v-model="payeur.lieu_dit_ou_bp"
                trim
                placeholder="Lieu-dit"
              />
            </b-form-group>
          </b-col>
           <b-col md="3">
            <b-form-group>
              <label>Complément d'adresse</label>
              <b-form-input
                id="complement_adresse"
                v-model="payeur.complement_adresse"
                autocomplete="nope"
                trim
                placeholder="Complément d'adresse"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Code Postal *</label>
              <validation-provider #default="{ errors }" name="code postal payeur" rules="required">
              <cleave id="code_postale_payeur" autocomplete="nope" v-model="payeur.code_postal"
                type="number" class="form-control" :class="errors.length > 0 ? 'is-invalid' : ''" :raw="false" :options="options.cp"
                placeholder="Code Postal" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Ville *</label>
              <validation-provider #default="{ errors }" name="ville payeur" rules="required">
                <b-form-select id="ville" v-model="payeur.ville_id" :class="errors.length > 0 ? 'is-invalid' : ''">
                  <b-form-select-option disabled :value="null">Choisissez</b-form-select-option>
                  <b-form-select-option v-for="(val, value) in villesLocal" :key="value" :value="val.id">
                    {{val.ville}}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <validation-provider #default="{ errors }" name="pays" rules="required">
              <b-form-group label="Pays *" label-for="pays" :class="errors.length > 0 ? 'is-invalid' : ''">
                <v-select v-model="payeur.pays" :close-on-select="true" :reduce="pays => pays.pays" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays" class="flex-grow-1" :state="errors.length > 0 ? false : null" input-id="pays" label="pays" placeholder="Pays">
                  <template #option="{ pays }">
                    <span class="ml-50"> {{ pays }}</span>
                  </template>

                  <template #selected-option="{ pays }">
                    <span class="ml-50"> {{ pays }}</span>
                  </template>
                  <div slot="no-options">Aucune pays disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- <div v-if="false">
        <h4 class="title-custom-wizard"><u>Informations de remboursements</u></h4>
      <b-row>
        <b-col md="12 mb-2">
          <label style="margin-bottom: 20px">Le compte de remboursement des prestations est-il différent du compte de paiement des cotisations ?</label>
          <b-form-radio-group label="remb_diff" id="remb_diff" v-model="paiementLocal.remboursementTitulaireCompte" :options="BooleanValue"></b-form-radio-group>
        </b-col>
        <b-col md="12" v-if="paiementLocal.remboursementTitulaireCompte">
          <b-row>
            <b-col md="6">
              <b-form-group label="IBAN de remboursement" label-for="remboursementTitulaireIban">
                  <validation-provider #default="{ errors }" name="IBAN remboursement" rules="required|ibanRules">
                    <b-form-input id="remboursementTitulaireIban" v-model="paiementLocal.remboursementTitulaireIban" autocomplete="nope" trim placeholder="IBAN de remboursement" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="BIC de remboursement" label-for="remboursementTitulaireBic">
                <validation-provider #default="{ errors }" name="BIC remboursement" rules="required|bicRules">
                  <b-form-input id="remboursementTitulaireBic" v-model="paiementLocal.remboursementTitulaireBic" autocomplete="nope" trim placeholder="BIC de remboursement"  :state="errors.length > 0 ? false : null"/>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Titulaire du compte remboursement" label-for="remboursementTitulairePaiementNom">
                  <validation-provider #default="{ errors }" name="titulaire du compte remboursement" rules="required">
                    <b-form-input id="remboursementTitulairePaiementNom" v-model="paiementLocal.remboursementTitulairePaiementNom" autocomplete="nope" trim placeholder="Titulaire du compte remboursement" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      </div> -->
    </validation-observer>

    <b-row>
      <b-col lg="12" class="mt-4">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
        <b-button variant="primary" class="float-right" size="lg" @click="submit"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { is422 } from '@/shared/utils/response'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Cleave from 'vue-cleave-component'
import BCardCode from '@core/components/b-card-code'
import { rules } from '@core/libs/validations'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  // BCardCode,
  BFormSelectOption,
  BFormCheckbox,
  BAlert
} from 'bootstrap-vue'
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BCardCode,
    BFormSelectOption,
    Cleave,
    BFormCheckbox,
    BAlert
  },
  props: ['paiement', 'showLoading', 'dataPayeur'],
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['Tiers']),
    ...mapGetters(["Enfant"]),
    ...mapGetters(["Parent"]),
    ...mapGetters(["Conjoint"])
  },
  watch: {
    getItemsRisque: {
      immediate: true,
      handler(val, nld) {
        this.rl = val.ItemsRisque[0]
        this.devis = val.ItemsRisque[0].devis
        this.objets=val.ItemsRisque[0].objets
        this.devis.jour_de_prelevement = 8
        if(this.objets.objet_moto[0].lien)
          this.initConducteur(this.objets.objet_moto[0].lien)
        else {
          this.payeur.typePayeur = 'AUTRE'
        }
      }
    },
    paiement: {
      immediate: true,
      handler(val, nld) {
        this.paiementLocal = val
      }
    },
    dataPayeur: {
     immediate: true,
      handler(val, nld) {
        this.payeur = val
      }
    },
    "payeur.code_postal": {
      immediate:true,
      handler(old,nld){
        this.cpKeyup()
      }
    },
    // "payeur.typePayeur": {
    //   handler(val) {
    //     if(val == 'CONDUCTEUR') {
    //       this.initPayeur(this.conducteur)
    //     } 
    //     else {
    //       this.initPayeur(this.payeur)
    //     }
    //   }
    // }
  },
  data() {
    return {
      pays: [],
      objets: {},
      conducteur: {
        id: null,
        type:null,
        civilite:null,
        nom:null,
        prenom:null,
        libelle:null,
        lieu_dit_ou_bp:null,
        complement_adresse:null,
        code_postal: null,
        ville_id:null
      },
      devis: {
        mode_paiement: null,
        fractionnement: null
      },
      paiementLocal: {
        id: null,
        iban: null,
        bic: null,
        modePaiement: null,
        periodicite: null,
        paiementTitulaireCompte: false,
        paiementTitulaireNom: null,
        paiementTitulairePrenom: null,
        remboursementPrestationsSante: false,
        remboursementTitulaireCompte: false,
        remboursementTitulaireIban: null,
        remboursementTitulaireBic: null,
        remboursementTitulairePaiementTitulaire: false,
        remboursementTitulairePaiementNom: null,
        remboursementTitulairePaiementPrenom: null,
      },
      payeur : {
        id: null,
        type: null,
        s_is_payeur: 1,
        typePayeur: null,
        civilite: null,
        nom: null,
        prenom: null,
        libelle: null,
        lieu_dit_ou_bp:null,
        complement_adresse: null,
        code_postal: null,
        ville_id: null,
        pays: null
      },
      config: {
        dateFormat: 'd/m/Y'
      },
      BooleanValue: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' }
      ],
      TypePayeurOptions: [{
          value: null,
            text: 'Choisissez',

          },
          {
            value: 'CONDUCTEUR',
            text: 'Conducteur'
          },
          {
            value: 'AUTRE',
            text: 'Autre'
          },
      ],
      villesLocal:[],
      civilite: [{
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'MR',
          text: 'M.'
        },
        {
          value: 'MME',
          text: 'Mme'
        }
      ],
      errors: [],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        },
        cp: {
          blocks: [5],
        },
      }
    }
  },
  created() {
    this.getPays();
  },
  methods: {
    getPays() {
      this.$http
      .get(`tiers/getPays`)
      .then(r => {
        this.pays = r.data
      })
      .catch(e => {
        console.log(e)
      })
    },
    initConducteur(lien) {
      if(lien) {
        this.conducteur = {}
        if(lien == 'ENFANT') {
          let enfantSelected = this.Enfant.Enfant.find((enf) => enf.lienable.id == this.objets.objet_moto[0].conducteur.lienable_id)
          if(enfantSelected != undefined)
            this.conducteur = enfantSelected.lienable
        } else if(lien == 'PARENT') {
          let parentSelected = this.Parent.Parent.find((par) => par.lienable.id == this.objets.objet_moto[0].conducteur.lienable_id)
          if(parentSelected != undefined)
            this.conducteur = parentSelected.lienable
        } else if (lien == 'CONJOINT') {
            this.conducteur = this.Conjoint
        }
      }
    },
    initPayeur(payeur = null) {
      this.payeur.id                  = payeur ? payeur.id : null
      this.payeur.type                = payeur ? payeur.type : null
      this.payeur.typePayeur          = payeur ? 'CONDUCTEUR' : 'AUTRE'
      this.payeur.civilite            = payeur ? payeur.civilite : null
      this.payeur.nom                 = payeur ? payeur.nom : null
      this.payeur.prenom              = payeur ? payeur.prenom : null
      this.payeur.libelle             = payeur ? payeur.moyen_contact.libelle : null
      this.payeur.lieu_dit_ou_bp      = payeur ? payeur.moyen_contact.lieu_dit_ou_bp : null
      this.payeur.complement_adresse  = payeur ? payeur.moyen_contact.complement_adresse : null
      this.payeur.code_postal         = payeur ? payeur.moyen_contact.code_postal : null
      this.payeur.ville_id            = payeur ? payeur.moyen_contact.ville_id : null
      this.payeur.pays                = payeur ? payeur.pays_naissance : null
      this.$refs.PaiementComposeRules.reset()
    },
    initPayeurLocal(val) {
      (val == 'CONDUCTEUR') ? this.initPayeur(this.conducteur) : this.initPayeur()
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 1,
          active: 'beneficiaire'
        }
      })
    },
    submit() {
      this.$refs.PaiementComposeRules.validate().then(success => {
        if (success) {
          this.$emit('updateLoading', true)
          this.errors = []
          this.paiementLocal.remboursementTitulaireCompte            = false
          this.paiementLocal.remboursementTitulairePaiementTitulaire = false
          let data = {
            paiement: this.paiementLocal,
            payeur: this.payeur,
            devis: this.devis,
            risque: this.rl.risque,
            prospect: this.Tiers
          }
          this.$http
            .post(`portefeuilles/saveorupdatePaiementStrategy`, data)
            .then(r => {
              this.updateDevisSouscriptionAvancement()
              //this.updateDevisRemuneration()
              this.$store.commit('setStepTarif', {
                step: {
                  indexStep: 4,
                  active: 'document'
                }
              })
              this.$emit('updateLoading', false)
            })
            .catch(err => {
              console.log(err)
              if (is422(err)) {
                this.$emit('updateLoading', false)
                this.errors = err.response.data.errors
              }
            })
        }
      })
    },
    async updateDevisSouscriptionAvancement() {
      let data = {
        id: this.rl.devis.id,
        devis_etape_avancement: 'document'
      }
      await this.$http
        .post(`devis/updateDevisSouscriptionState`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    hasErrors(val) {
      if (this.errors) {
        const found = Object.entries(this.errors).find(([key, value]) => key === val)
        return found ? found[1][0] : false
      }
    },
    async updateDevisRemuneration() {
      let data = {
        id: this.rl.devis.id,
        honoraires_remuneration: this.rl.devis.honoraires_remuneration,
        commission_remuneration: this.rl.devis.commission_remuneration,
        autre_remuneration: this.rl.devis.autre_remuneration,
        nbr_honoraire: this.rl.devis.nbr_honoraire,
        methode_calcul: this.rl.devis.methode_calcul
      }
      await this.$http
        .post(`devis/updateDevisRemuneration`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    async cpKeyup() {
      let cpLocal=this.payeur.code_postal;
      if(cpLocal && cpLocal.length==5){
          try {
            this.villesLocal =  (await this.$http.get(`helpers/${cpLocal}/villes`)).data;
          } catch (e) {
            this.payeur.ville_id=null
            this.villesLocal = []
          }
      }else{
            this.payeur.ville_id=null
            this.villesLocal = []
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body {
  padding: 0 !important;
}
.resume > .card > .card-header {
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.title-custom-wizard {
  color: #4d25bc;
}
.opacited_input {
  opacity: 0.6;
}
</style>
