<template>
   <div>
      <b-card-code title="Vertically Stacked Pills">
        <b-tabs
          pills
          vertical
        >
          <b-tab
            title="RIA"
            active
          >
          <b-card-text>
            <h4 class="title-custom-wizard"><u>RIA</u></h4>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Siret"
                  label-for="siret"
                >
                  <b-form-input
                    id="siret"
                    v-model="ria.siret"
                    trim
                    placeholder="Siret"
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="!$v.entreprise.enseigne.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s
                </b-form-invalid-feedback> -->
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Raison sociale"
                  label-for="raison_sociale"
                >
                  <b-form-input
                    id="raison_sociale"
                    v-model="ria.raisonSociale"
                    trim
                    placeholder="Raison sociale"
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s
                </b-form-invalid-feedback> -->
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Numero Contrat"
                  label-for="contratNum"
                >
                  <b-form-input
                    id="contratNum"
                    v-model="ria.contratNum"
                    trim
                    placeholder="Numero Contrat"
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s
                </b-form-invalid-feedback> -->
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Service De Resiliation"
                  label-for="serviceResiliation"
                >
                  <b-form-input
                    id="serviceResiliation"
                    v-model="ria.serviceResiliation"
                    trim
                    placeholder="Service De Resiliation"
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s
                </b-form-invalid-feedback> -->
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Date d'echeance"
                  label-for="echeance"
                >
                  <flat-pickr
                    class="form-control"
                    v-model="ria.echeance"
                    id="echeance"
                    :config="config"
                    placeholder="Date d'echeance"
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s
                </b-form-invalid-feedback> -->
              </b-col>
            </b-row>
          </b-card-text>

          </b-tab>
          <b-tab title="COORDONNÉES">
            <b-card-text>
              <h4 class="title-custom-wizard"><u>ADRESSE</u></h4>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Adresse"
                      label-for="adresse"
                    >
                      <b-form-input
                        id="adresse"
                        v-model="ria.adresse"
                        trim
                        placeholder="Adresse"
                      />
                    </b-form-group>
                    <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s
                    </b-form-invalid-feedback> -->
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Code Postal"
                      label-for="codePostal"
                    >
                      <b-form-input
                        id="codePostal"
                        v-model="ria.codePostal"
                        trim
                        placeholder="Code Postal"
                      />
                    </b-form-group>
                    <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s
                    </b-form-invalid-feedback> -->
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                        label="Ville"
                        label-for="ville"
                      >
                        <b-form-select
                        v-model="ria.ville_id"
                        id="ville"
                        >
                          <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                          <b-form-select-option v-for="(val, value) in ria.villesData" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s
                    </b-form-invalid-feedback> -->
                  </b-col>
  
                </b-row>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card-code>
      <b-row class="float-right mb-4">
        <b-col lg="4" >
          <b-button
            variant="primary"
            size="lg"
            @click="submit"
          >
            Suivant
          </b-button>
        </b-col>
      </b-row>
   </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {required} from "vuelidate/lib/validators";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  // BCardCode,
  BFormSelectOption

} from "bootstrap-vue";
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    // BCardCode,
    BFormSelectOption
  },
  computed: {
    ...mapGetters(["getItemsRisque"]),
    ...mapGetters(["getSelectedRisque"]),
    ...mapGetters(["getEtude"]),
  },
  watch: {
    getItemsRisque: {
        handler: function (val, oldVal) {
          this.resumeLocal = this.getItemsRisque.ItemsRisque;
          this.hasProduct=false
          if(this.resumeLocal.length>0){
            this.resumeLocal.map((risque)=>{
                if(risque.produitsFiltred.length>0 && !this.hasProduct){
                  risque.produitsFiltred.map((rpf)=>{
                    if(rpf.tarif.presente)
                    this.hasProduct=true
                  })
                }
            })
          }
        },
        immediate: true,
        deep:true
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque;
    },
  },
  data() {
    return {
      ria:{
        id:null,
        siret:null,
        raisonSociale:null,
        contratNum:null,
        serviceResiliation:null,
        echeance:null,
        adresse:null,
        codePostal:null,
        ville_id:null,
        villesData:[]
      },
      config: {
        dateFormat: 'd/m/Y',      
      },
    };
  },
  validations: {
    // entreprise: { 
    //   denomination_commercial:  { required },
    //   raison_sociale: { required },
    //   siret: { required },
    //   forme_juridique: { required },
    // },
  },
  created() {
    
  },
  methods: {
    submit(){
      this.$store.commit("setStepTarif", {
        step: {
          indexStep:6,
          active:'signature'
        },
      });
    },
    getMandatRia($devis){
      let devisID=this.getItemsRisque.ItemsRisque[this.indexRisque].devis.id
      this.$http.get(`ecaglobal/getMandatRiaByDevis/${devisID}`)
        .then((r) => {
          console.log(r.data);
          this.initRiaData(r.data)
        })
        .catch((e)=>{
          console.log(e);
        })
    },
    initRiaData(data){
      this.ria.id=data.id,
      this.ria.siret=data.assureur_resiliation.siret,
      this.ria.raisonSociale=data.assureur_resiliation.raison_sociale,
      this.ria.serviceResiliation=data.assureur_resiliation.service_resiliation,
      this.ria.contratNum=data.contratNum,
      this.ria.echeance=moment(data.date_echeance, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      this.ria.adresse=data.assureur_resiliation.adresse,
      this.ria.codePostal=data.assureur_resiliation.code_postal
      this.ria.ville_id=data.assureur_resiliation.ville_id
      this.cpKeyup(this.ria.codePostal)
    },
    async cpKeyup(cpLocal) {
      if(cpLocal.length==5){
          try {
            this.ria.villesData=  (await axios.get(`${url}helpers/${cpLocal}/villes`)).data;
          } catch (e) {
            this.ria.villesData = []
          }
      }else{
            this.ria.villesData = []
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle
  .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .checked
  ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.title-custom-wizard{
    color: #4d25bc ;
}
</style>

