<template>
  <div>
    <b-overlay
      :show="showLoading"
      no-wrap
    />
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 1 : confirmation du choix du client
        </h2>
        <h6>
          Sélectionnez la formule choisie par le client (commentaire obligatoire)
        </h6>
      </b-col>
    </b-row>
        <b-row class="pricing-card" v-for="(rl, index) in resumeLocal" :key="index + 'content'">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row>
          <b-col md="12" lg="12" xl="6" class="resume" v-for="(pr, val) in filtreProduit(rl.produitsFiltred)" :key="val + 'produits'">
            <b-card align="center" :header="`${pr.produit.produit_nom} - ${pr.produit.assureur.nom_com_assureur}`" class="text-center">
              <div class="d-flex flex-column bd-highlight">
                <div class="pt-1 bd-highlight">
                  <h5>{{ pr.tarif.formule_commercial }}</h5>
                </div>
                <div class="bd-highlight">
                  <b-row style="font-size: 12px;" class="text-left px-1">
                    <b-col sm="12" v-for="(DTBP, indexDTBP) in $_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc')" :key="indexDTBP + ' - GARANTIES ET FRANCHISES'">
                      <b-row>
                      <template v-if="DTBP.pret == 'Principal'">
                        <b-col sm="12" class="text-center pt-3">
                            <div style="margin-bottom:10px;" class="bd-highlight">
                              <div class="annual-plan mb-0">
                                <div class="plan-price">
                                  <span class="pricing-basic-value font-weight-bolder text-primary pr-1" style="font-size: 1.5rem">{{ `${DTBP.assure}` }}</span>
                                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.5rem"> {{ Number(pr.tarif.totalBase[DTBP.type_assure == 'Assuré' ? 'Assure' : 'Co-assure'].price).toFixed(2) }} </span>
                                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/an</sub>
                                </div>
                              </div>
                            </div>
                            <h6> {{ pr.tarif.formule =='CI' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : pr.tarif.formule =='CI' && pr.produit.assureur_id == '2632' ?  "cotisation fixe annuelle sur l'ensemble du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '2632'? "Cotisation annuelle moyenne calculée sur le montant total du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : "" }} </h6>

                        </b-col>
                      </template>
                        <b-col md="12" class="pb-1 pt-2 px-0">
                          <strong>{{ `Prêt ${DTBP.pret.toLowerCase()}` }}</strong>
                        </b-col>
                        <b-col sm="12" class="border-bottom-primary border-top-primary border-2 py-1">
                          <b-row class="text-primary">
                            <b-col sm="5" class="p-0"><strong>Garanties, franchise</strong></b-col>
                            <b-col sm="3" class="p-0"><strong>Quotités - délais</strong></b-col>
                            <b-col sm="2" class="p-0"><strong>Statut</strong></b-col>
                            <b-col sm="2" class="p-0"><strong>Coût total</strong></b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row >
                        <b-col sm="12" v-for="(garantieEtFranchise, indexgarantie) in DTBP.garanties_et_franchises" :key="indexDTBP + ' - ' + indexgarantie + ' - GARANTIES ET FRANCHISES' ">
                          <b-row :class="indexgarantie == 0 ? 'mt-1' : ''">
                            <template v-if="!garantieEtFranchise.isParent && garantieEtFranchise.isShowing">
                              <b-col sm="5" class="p-0">{{ garantieEtFranchise.key }}</b-col>
                              <b-col sm="3" class="p-0" :style="garantieEtFranchise.style">{{ garantieEtFranchise.value }}</b-col>
                              <b-col sm="2" class="p-0"> 
                                <feather-icon :icon="garantieEtFranchise.icon.feather" size="16" :color="garantieEtFranchise.icon.color"/>
                              </b-col>
                              <b-col sm="2" class="p-0"> 
                                {{ garantieEtFranchise.price }}
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                        <b-col sm="12" v-for="(garantieEtFranchise, indexgarantie) in DTBP.options" :key="indexDTBP + ' - ' + indexgarantie + ' - OPTIONS' ">
                          <b-row :class="indexgarantie == 1 ? 'pt-1' : ''">
                            <template v-if="!garantieEtFranchise.isParent">
                              <b-col sm="5" class="p-0">{{ garantieEtFranchise.key }}</b-col>
                              <b-col sm="3" class="p-0">-</b-col>
                              <b-col sm="2" class="p-0"> 
                                <feather-icon :icon="garantieEtFranchise.value" size="16" :color="garantieEtFranchise.style"/>
                              </b-col>
                              <b-col sm="2" class="p-0"> 
                                {{ garantieEtFranchise.price != '' ? garantieEtFranchise.price : '-' }}
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                        <b-col sm="12">
                          <b-row>
                            <b-col sm="12" class="p-0">
                              <hr>
                            </b-col>
                            <b-col sm="5" class="p-0">TAEA</b-col>
                            <b-col sm="2" class="p-0" offset="5">{{ DTBP.taea != "-" ? Number(DTBP.taea).toFixed(2)+"%" : DTBP.taea }}</b-col>
                            <b-col sm="5" class="p-0">Budget par prêt</b-col>
                            <b-col sm="2" class="p-0" offset="5">{{ DTBP.budget }}€</b-col>
                            <b-col sm="5" class="p-0">Budget sur 8 ans par prêt</b-col>
                            <b-col sm="2" class="p-0" offset="5">{{ DTBP.budget_couverture_de_pret_sur_8_ans }}€</b-col>
                          </b-row>
                          <b-row class="text-primary" v-if="DTBP.pret == 'Secondaire' || (DTBP.pret == 'Principal' && countPrets.secondaire <= 0)">
                            <b-col sm="12" class="p-0">
                              <hr class="border-top-primary">
                            </b-col>
                            <b-col sm="5" class="p-0"><strong>Budget total / assuré</strong></b-col>
                            <b-col sm="2" class="p-0" offset="5"><strong>{{ initBudget(pr.tarif.detailTarifByPret, DTBP.is_assure, 'budget') }}€</strong></b-col>
                            <!-- <b-col sm="5" class="p-0" ><strong>Budget sur 8 ans</strong></b-col> -->
                            <!-- <b-col sm="2" class="p-0" offset="5"><strong>{{ initBudget(pr.tarif.detailTarifByPret, DTBP.is_assure, 'budget_couverture_de_pret_sur_8_ans') }}€</strong></b-col> -->
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <template #footer>
                <b-form-textarea
                  placeholder="exemple : Le rapport qualité/prix de cette formule est le plus adapté compte tenu du besoin que vous avez exprimé."
                  v-model="pr.tarif.commentaire_client" rows="3" maxlength="255"> </b-form-textarea>
                <!-- buttons -->
                <b-button block class="mt-2" @click="choisirProduit(pr.tarif.tarification_id)"
                  :variant="pr.tarif.choix_client ? 'primary' : 'outline-primary'"> Choix Client </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col  lg="12">
        <app-collapse id="collapse-besoin" type="margin" :is-visible="true">
          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>Lexique<u></h4>">
          <ul>
            <li> Les montants affichés au niveau des garanties, options et budgets (hors budget sur 8 ans) portent sur la durée total du prêt.</li>
            <li><feather-icon icon="MinusIcon" size="24" /> : Garantie non envisagée</li>
            <li><feather-icon icon="CheckIcon" size="24" color="green"/> : Soit la garantie est envisagée et proposée soit la garantie est incluse</li>
            <li><feather-icon icon="XIcon" size="24" color="red"/> : La garantie envisagée n’est pas proposée (ou ne l’est pas pour cet assuré/ce projet)</li>
          </ul>
          <p>Pour Harmonie mutuelle, le budget sur 8 ans est calculé à partir des cotisations annuelles fractionnées.</p>
          <p>Chez Harmonie mutuelle, les options (EXO, DOS/PSY) sont sélectionnées par assuré (pas de distinction par prêt). Si une option est demandée sur un prêt, elle sera demandée sur les deux prêts.</p>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

    <b-row style="margin-top:50px;">
      <b-col lg="12">
        <b-button
          v-if="canNext"
          class="float-right"
          variant="primary"
          size="lg"
          @click="submitProduit(0)"
        >
          Suivant
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  VBTooltip,
  VBPopover,
  BAlert,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BAlert,
    BBadge,
  },
  props: ['showLoading'],
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['getEtude']),
    canNext() {
      return _.find(this.resumeLocal[0].produitsFiltred, p => p.tarif.choix_client)
    },
  },
  watch: {
    getSelectedRisque(val, old) {
      this.indexRisque = this.getSelectedRisque
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  data() {
    return {
      countPrets : {
        principal: 0,
        secondaire: 0
      },
      disablePrestation: false,
      checkerExplicationOptions: { isOption1: false, isOption2: false },
      resumeLocal: [],
      indexLocal: 0,
      indexRisque: 0,
      prospect: null,
      hasProduct: false,
      hasCommentedProduit: false,
      hasSelectedProduit: false,
      BooleanValue: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' },
      ],
      BooleanValue1: [
        { value: 1, text: 'Oui' },
        { value: 0, text: 'Non' },
      ],
    }
  },
  created() {
    this.getEtudeData(this.$route.params.id).then(res => {
      this.arrayProduit()
      this.hasProduct = false
      if (this.resumeLocal.length > 0) {
        this.resumeLocal.map(risque => {
          if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
            risque.produitsFiltred.map(rpf => {
              if (rpf.tarif.presente) this.hasProduct = true
            })
          }
        })
        this.$emit('updateLoading', false)
      }
    }).catch(() => {
      this.$emit('updateLoading', false)
    })
  },
  mounted() {

  },
  methods: {
    initBudget(tarifByPret, isAssure, key) {
      var total = tarifByPret.filter(item => item.is_assure == isAssure)
      .reduce((accu, objet) => {
        return accu + (!isNaN(Number(objet[key])) ? Number(objet[key]) : 0)
      }, 0)
      return Number(total).toFixed(2)
    },
    checkPret(tarifByPret) {
        this.countPrets.principal  = tarifByPret.filter(item => item.pret == 'Principal').length
        this.countPrets.secondaire  = tarifByPret.filter(item => item.pret == 'Secondaire').length
    },
    filtreProduit(data) {
      return data ? data.filter(pr => pr.tarif.presente) : []
    },
    async getEtudeData(etude) {
      await this.$http
        .get(`portefeuilles/getEtude/${etude}`)
        .then(r => {
          this.updateTarifModule(r.data.data)
          this.prospect = this.Tiers
          this.$store.commit('setChangedRisque', {})
        })
        .catch(e => {
          console.log(e)
        })
    },
    updateTarifModule(data) {
      const tmpProspect = data.Tiers
      tmpProspect.date_naissance = moment(tmpProspect.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.$store.state.tarifModule.etude = {
        etudeID: data.etudeID,
        etudeNom: data.etudeNom,
        ipid: data.ipid,
        cg: data.cg,
        tg: data.tg,
        Tiers: tmpProspect,
        checkedRisque: data.checkedRisque,
        itemsRisque: data.itemsRisque,
        Conjoint: data.Conjoint,
        Enfant: data.Enfant,
        Ami: data.Ami,
        Parent: data.Parent,
        Entreprise: data.Entreprise,
        changedRisque: false,
        step: data.itemsRisque.ItemsRisque[0].devis.devis_etape_avancement,
        etudeAvancement: null,
        conseilpath: null,
        selectedRisque: 0,
        activeStep: [
          { indexStep: 0, active: 'choix' },
          { indexStep: 1, active: 'souscripteur' },
          { indexStep: 2, active: 'benificaire' },
          { indexStep: 3, active: 'paiement' },
          { indexStep: 4, active: 'document' },
          { indexStep: 5, active: 'signature' },
        ],
      }
      this.resumeLocal = this.$store.state.tarifModule.etude.itemsRisque.ItemsRisque
    },
    async choisirProduit(tarification) {
      await this.updateProduitSelected(tarification, 0)
    },
    updateProduitSelected(tarification, index) {
      this.resumeLocal[index].produitsFiltred.map((pr, val) => {
        if (pr.tarif.tarification_id == tarification) {
          pr.tarif.choix_client = true
        } else {
          pr.tarif.choix_client = false
        }
      })
    },
    arrayProduit() {
      let produitFiltre = []
      if (this.resumeLocal.length > 0) {
        this.resumeLocal.map(risque => {
          produitFiltre = []
          risque.produitsFiltred = []
          risque.produits.map(produit => {
            if (produit.tarif) {
              produit.tarif.map(tf => {
                const { tarif, ...newProduit } = produit
                let garantiesLocal = tf.garanties
                let produitsgarantiesLocal = tf.produitsgaranties
                const { garanties, ...tarifLocal } = tf
                produitFiltre.push({
                  produit: newProduit,
                  garanties: garantiesLocal,
                  produitsgaranties: produitsgarantiesLocal,
                  tarif: tarifLocal
                })
              })
            }
          })
          if (produitFiltre.length > 0) {
            let data = {
              produits: produitFiltre,
              exigences: risque.exigences,
              garanties: risque.garanties,
              risque: risque.risque.label,
              objets: risque.objets
            }
            risque.produitsFiltred = this.sortByTauxDec(data.produits)
          }
        })
        this.checkPret(this.resumeLocal[0].produitsFiltred[0].tarif.detailTarifByPret)
        this.filtreDownUp(0)
      }
    },
    filtreDownUp(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.resumeLocal[risque].produitsFiltred.sort((a, b) => a.tarif.totalBase.Assure.price - b.tarif.totalBase.Assure.price)
        this.showCompareTable = true
      }, 10)
    },
    sortByTauxDec(data) {
      function UpDowncompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return 1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return -1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return 1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return -1
          }
        }
        return 0
      }
      data.sort(UpDowncompare)
      return data
    },
    submitProduit(index) {
      let validProduit = null
      validProduit = this.resumeLocal[index].produitsFiltred.find(pr => pr.tarif.choix_client)
      if (validProduit) {
        if (validProduit.tarif.commentaire_client !== '') {
          if (this.resumeLocal[index].devis.reprise_concurrence == null && this.hasPrvilegeToRepriseConcurrence) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Merci de préciser s\'il s\'agit d\'une reprise concurrence',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$emit('updateLoading', true)
            this.hasCommentedProduit = false
            const produisChoisi = []
            this.resumeLocal[index].produitsFiltred.map(rlp => {
              produisChoisi.push({
                choix_client: rlp.tarif.choix_client,
                commentaire_client: rlp.tarif.commentaire_client,
                tarification_id: rlp.tarif.tarification_id,
                assureur_id: rlp.produit.assureur_id,
              })
            })
            const data = {
              produisChoisi,
              devis: this.resumeLocal[index].devis.id,
            }
            this.$http.post('portefeuilles/updateTarifChoixClient', data).then(r => {
              this.updateDevisSouscriptionAvancement(index)
              this.hasSelectedProduit = false
              this.$emit('updateLoading', false)
              this.$store.commit('setStepTarif', {
                step: {
                  indexStep: 1,
                  active: 'souscripteur',
                },
              })
            }).catch(err => {
              this.$emit('updateLoading', false)
              console.log(err)
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Merci de saisir un commentaire',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          this.hasCommentedProduit = true
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Merci de Choisir un produit',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.hasSelectedProduit = true
      }
    },
    async updateDevisSouscriptionAvancement(index) {
      const data = {
        id: this.resumeLocal[index].devis.id,
        devis_etape_avancement: 'souscripteur',
      }
      await this.$http.post('devis/updateDevisSouscriptionState', data).then(r => true).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-icon-custom{
  color: #4d25bc;
  margin-top: -5px;
}

.popover {
  max-width: 600px;
  width: auto;
}
</style>
